.ruletree-wrapper *,
.ruletree-wrapper *:before,
.ruletree-wrapper *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ruletree-wrapper {
    position: relative;
    font-family: sans-serif, Verdana, Arial;
    font-size: 0.9em;
}

.ruletree-branch {
    position: relative;
    margin-left: 260px;	/* 노드와 노드 사이의 가로 거리. 원래 값 180px */
}

.ruletree-branch:first-child {
    margin-left: 0;
}

.ruleset_rule.empty .ruleset_dragging_guide {
    width: 100%;
    margin-top: 295px;
    font-family: Tahoma;
    font-size: 12px;
    color: #656565;
    letter-spacing: 0;
    text-align: center;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
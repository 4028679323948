.sw_publish_group_wrap {
    height:25px;
    width:100%;
}

.sw_publish_group_wrap > span:first-child {
    float:left;
    padding-top:3px;
}

.sw_publish_group_wrap > span:last-child {
    float:right
}

.sw_publish_group_wrap .delete_button {
    height: 19px;
    width: 19px;
    background: url(../../images/img/tab_close_select.png) no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}

.sw_publish_group_wrap .delete_button:hover {
    height: 19px;
    width: 19px;
    background: url(../../images/img/tab_close_hover.png) no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}    
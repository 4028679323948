.ruleset_condition_quest_img_noti {
    display: inline-block;
    position: relative;
}

.ruleset_condition_quest_img_noti a {
    width: 20px;
    height:20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-left: 13px;
    background: url('../../../../images/img/qu_notice.png') no-repeat;
}

.ipadress_tooltip_message{
    background: rgba(224,224,224,0.85);
    padding: 10px;
    z-index: 2;
    border: 1px solid #fff;
    position: absolute;
    top:15px;
    left:25px;    
    color: #000;
    line-height: 22px;
    width: 300px;
    height: 40px;
}

.device_setting_popper_wrap {
    position: absolute;
    z-index: 101; 
    width: 310px;
    height: 230px;
    top: 40px;
    right: 290px; 
    display: block;
    background: #fff;
    border: 1px solid #dde0e5;
    /* padding: 0px 35px; */
}

.device_setting_popper_wrap .popper_arrow {
    position: absolute;
    top: -13px;
    right: 30px;
}

.device_setting_popper_wrap .popper_header {
    font-size: 13px;
    line-height: 47px;
    border-bottom: 1px solid #ebebeb;
    padding-left: 20px;
    text-align: left;
    margin: 0px;
}

.device_setting_popper_wrap .popper_body {
    /* width: 100%; */
    border-bottom: 1px solid #ebebeb;
    margin: 15px 25px;
    padding-bottom: 15px;
}

.device_setting_popper_wrap .popper_body tr {
    line-height: 30px;
}

.device_setting_popper_wrap .popper_body th {
    padding: 5px 0 5px 0px;
    color: #8d8d8d;
    vertical-align: middle;
}

.device_setting_popper_wrap .popper_footer {
    width: 100%;
    text-align: center;
    padding: 6px 0;
    height: 28px;
}
.menu_select_box {
    display: inline-block;
    position: relative;
}
.menu_select_box .base.un_radius.select {
    border-radius: 5px;
}

.menu_select_box .base.select.on {
    color: #848484;
    border-color: #dde0e5;
    background: #ffffff;
}

.menu_select_box .base.select {
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
}

.menu_select_box .option_list {
    position: absolute;
    top: 37px;
    left: 0;
    background: #fff;
    border: 1px solid
    #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
}

.menu_select_box .option_list > div {
    position: relative;
}

.menu_select_box .option_list > div > span {
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background: url('../../images/bg/option_list_bg.png') no-repeat 10px 0;
}

.menu_select_box .option_list > div > ul > li:first-child {
    padding-top: 11px;
}

.menu_select_box .option_list > div > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}
.menu_select_box .option_list > div > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}

.menu_select_box .option_list > div > ul > li > a {
    display: inline-block;
    color: #707070;
    text-decoration: none;
    width: 100%;
    font-size: 12px;
    vertical-align: middle;
}

.addRule.menu_select_box .option_list > div > ul > li > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu_select_box .option_list > div > ul > li > a:hover {
    color: #609be8 !important;
    cursor: pointer;
}

.menu_select_box .extend_sub {
    background: url('../../images/button/btn_next.png') no-repeat;
    background-position: top 4px right 4px;
}

.menu_select_box .sub_option_list {
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
    border: 1px solid #dde0e5;
    position: absolute;
    top: 0;
    left: 150px;
    background: #ffffff;
    width: 150px;
}

.menu_select_box .sub_option_list > div {
    position: relative;
}

.menu_select_box .sub_option_list > div > ul > li:first-child {
    padding-top: 11px;
}

.menu_select_box .sub_option_list > div > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}
.menu_select_box .sub_option_list > div > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}

.menu_select_box .sub_option_list > div > ul > li > a {
    display: inline-block;
    color: #707070;
    text-decoration: none;
    width: 100%;
    font-size: 12px;
    vertical-align: middle;
}

.menu_select_box .sub_option_list > div > ul > li > a:hover {
    color: #609be8 !important;
    cursor: pointer;
}

.menu_select_box .base:disabled {
    border-color: #e5e8ec !important;
    color: #b2b3b3 !important;
    background: #f8f9f9 !important;
}

.menu_select_box .content.base:disabled {
    border-color: #e5e8ec !important;
    color: #b2b3b3 !important;
    background: #232832 !important;
}

.menu_select_box > button > span > span {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
}

.menu_select_box > button > span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url(../../images/button/select_down.png) no-repeat 0 10px;
    margin-left: 10px;
}

.menu_select_box > button > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url(../../images/button/select_down.png) no-repeat 0 10px;
    margin-left: 10px;
}

.menu_select_box .white {
    background-color: #333c4e !important;
    color: #848484 !important;
    border-color: #dde0e5 !important;
    border: 1px solid #dde0e5;
    font-size: 12px;
    line-height: 24px !important;
    border-radius: 15px !important;
}
.menu_select_box .white span {
    color: #dedede !important;
}
body {
    overflow-y: hidden;
    overflow-x: hidden;
}

.insightWrap {
    display: inline-flex;
    flex-flow: column;
    position: relative;
    height: calc(100vh - 75px);
    width: 100%;
}
.save_as_template_popup > table {
    border-collapse: collapse;
    border-spacing: 0;
}

.save_as_template_popup th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
    text-align: left;
    font-weight: normal;
    white-space: nowrap;
}

.save_as_template_popup td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #59616b !important;
    height: 28px;
    display:inline-flex;
}

.save_as_template_popup input {
    padding: 0 10px;
    border: 1px solid #dde0e5;
    border-radius: 5px;
    line-height: 26px;
    font-size: 12px;
    color: #686b72;
    height: 25px;
    cursor: text !important;
    margin-right: 8px !important;
}

.save_as_template_popup .circle_btn {
    padding: 0;
    height: 28px;
    width: 28px;
    border: 1px solid #dde0e5;
    color: #848484;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    float:left !important;
}

.save_as_template_popup .circle_btn > span {
    background: url('../../../images/icon/search_circle_normal.png') no-repeat 8px 7px;
    display: inline-block;
    height: 28px;
    width: 28px;
}
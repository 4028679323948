.role_content {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-flow: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.role_content_name {
    width: 100%;
    height: 27px;
    display: inline-flex;
    flex-flow: row;
    line-height: 27px;
}
.role_content_title {
    width: 210px;
    font-size: 13px;
    color: #8d8d8d;
}
.add_role_content_list {
    width: 729px;
    height: 396px;
    display: inline-flex;
    flex-flow: column;
}
.edit_role_content_list {
    width: 729px;
    height: 448px;
    display: inline-flex;
    flex-flow: column;
}
.role_content_list_table {
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    font-size: 13px;
}
.role_content_list_table table {
    width: 100%;
}
.role_content_list_table_title_column {
    background: #fafafa;
    line-height: 30px;
    padding-left: 12px;
}
.role_content_list_table_value_raw {
    border-top: 1px solid #f0f0f0;
}
.role_content_list_table_value_column {
    border-bottom: 0;
    line-height: 15px;
    vertical-align: middle;
    color: #797979 !important;
    padding: 12px;
}
.role_content_list_table table tr:hover td {
    background: #f3f9ff;
}

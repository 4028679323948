.graph_wrap {
    background: #fbfbfc;
    padding: 29px 0 30px 30px;
    position: relative;
}

.clearfix:before,
.clearfix:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after {
  content: " ";
  display: table;
}
.clearfix:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after {
  clear: both;
}

.statistics_export_button {
}

.statistics_export_button > button{
    border: 1px solid #5e5e5e !important;
    color: #363636 !important;
    background: #fff !important;
    min-width: 76px;
}

.statistics_export_button > button > span{
    width: 100%;
}
.statistics_export_button > button > span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url(../../images/button/select_down.png) no-repeat 0 10px;
    margin-left: 10px;
}

.statistics_export_button > button:hover {
    cursor: pointer !important;
    border: solid 1px #e7eaf0 !important;
    background: #e7eaf0 !important;
    color: #000000 !important;
}

.statistics_export_button > button:disabled {
    cursor: inherit !important;
    border-color: #c2c2c2 !important;
    color: #d0d0d0 !important;
    background: #fff !important;
}

.statistics_export_button ul {
    width: 76px !important;
}
.statistics_contentType_list {overflow-y:auto;overflow-x:hidden;border-bottom:0;margin-right: 0;height: 200px;}
.statistics_contentType_list table tr td {border-bottom:1px solid #f2f2f2;vertical-align:middle;text-align:left;padding:7px 10px 7px 17px;line-height:20px;position:relative;}

.statistics_list {overflow-x:hidden;overflow-y:auto; height:calc(100% - 83px); border-top: 1px solid #f2f2f2;}
.statistics_list > .statistics_status_wrap {width:100%;padding:15px 21px 0 21px;}
.statistics_list > .statistics_status_wrap > div {float:left;}
.statistics_list > .statistics_status_wrap > div h4 {font-size:16px;font-weight:normal;color:#686b72;margin:0 0 18px 0}
.statistics_list > .statistics_status_wrap > div > div {position:relative;border:1px solid #e7e7e7;border-radius:3px}
.statistics_list .bul.c_1aa05a {display:inline-block; width:8px; height:8px; margin-top:7px; margin-right:4px; border-radius:25px; vertical-align: top;background:#1aa05a}

table {	border-collapse:collapse;border-spacing:0;}
/* LIST STYLE */
.fixed_wrap {padding-right:360px}
.list_wrap {/*position:relative; */ padding-bottom:45px}
.list_wrap table {width:100%; position:relative;}
/*.list_wrap table {width:100%; position:relative;}*/
.list_wrap table thead {border:0;border-top:1px solid #d2d2d3;border-bottom:1px solid #d2d2d3;background:#eeeeef;position:relative;}
.list_wrap > div > div table > thead > tr > th {vertical-align:middle;padding:7px 10px 7px 10px;background:url('../../images/bg/table_divider.png') no-repeat right;position:relative;}
.list_wrap > div > div table > thead > tr > th:first-child {background:none;padding:7px 0 7px 17px;}
.list_wrap > div > div table > thead > tr > th.bar {background:url('../../images/bg/table_divider.png') no-repeat right}
.list_wrap > div > div table > thead > tr > th.bgnone {background:none;}
.list_wrap > div > div table > thead > tr > th:last-child {border-right:0;background:none}
.list_wrap > div > div table > thead > tr > th.icon:hover {background:#f3f3f3;border-top:1px solid #e0e0e0;border-bottom:1px solid #e0e0e0;}
.list_wrap > div > div table > thead > tr > th.icon:active {background:#f0f0f0;}
.list_wrap > div > div table > thead > tr > th.icon > a {display:none}
.list_wrap > div > div table > thead > tr > th.icon:hover > a {display:block;width:15px;height:15px;float:right;margin-right:0;padding:0;background:url('../../images/img/list_arrow.png') no-repeat 5px 7px;}
.list_wrap > div > div table > thead > tr > th:last-child.icon:hover  > a {margin-right:10px;}

.list_wrap > table > thead > tr > th {vertical-align:middle; padding:7px 10px 7px 10px; background:url('../../images/bg/table_divider.png') no-repeat right; position:relative;}
.list_wrap > table > thead > tr > th:first-child {background:none; padding:7px 0 7px 17px;}
.list_wrap > table > thead > tr > th.bar {background:url('../../images/bg/table_divider.png') no-repeat right}
.list_wrap > table > thead > tr > th.bgnone {background:none;}
.list_wrap > table > thead > tr > th:last-child {border-right:0; background:none}
.list_wrap > table > thead > tr > th.icon:hover {background:#f3f3f3; border-top:1px solid #e0e0e0; border-bottom:1px solid #e0e0e0;}
.list_wrap > table > thead > tr > th.icon:active {background:#f0f0f0;}
.list_wrap > table > thead > tr > th.icon > a {display:none}
.list_wrap > table > thead > tr > th.icon:hover > a {display:block; width:15px; height:15px; float:right; margin-right:0; padding:0; background:url('../../images/img/list_arrow.png') no-repeat 5px 7px;}
.list_wrap > table > thead > tr > th:last-child.icon:hover  > a {margin-right:10px;}


.list_wrap table tr th label {text-indent: -9999px;}
.list_wrap table tr th.checkall label {text-indent:0;}
.list_wrap table tr th.checkinfo label {text-indent:0;}
.list_wrap table tr td label {text-indent: -9999px}

.data_name {cursor:pointer;white-space: nowrap;overflow: hidden;display: block;}
.data_name:hover {text-decoration: underline}

.list_wrap_empty {width:100%;text-align:center}
.list_wrap_empty.noline {border-top:0;}
.list_wrap_empty .empty_title {font-size:26px;color:#d9e0e7;display:block;margin-bottom:17px;}
.list_wrap_empty > div > img {margin-bottom:41px;margin-top:290px;}
.list_wrap_empty > div > span {font-size:15px;color:#cbcbcb;line-height:20px}

.list_wrap_empty2 {width:auto;margin-bottom:0;}
.list_wrap_empty2 > img {margin-bottom:41px;}
.list_wrap_empty2 > span {display:block;font-size:15px; color:#cbcbcb; line-height:20px}

.dataTables_scrollBody table thead {background:none;border:0}



.bgc_2ecc71 {background:#a6bddb !important}
.bgc_369860 {background:#2b8cbe !important}
.bgc_fde0dd {background:#fde0dd !important}
.bgc_f64747 {background:#fa9fb5 !important}
.bgc_be5252 {background:#c51b8a !important}
.bgc_913d88 {background:#addd8e !important}
.bgc_5a3756 {background:#31a354 !important}
.bgc_f3cf29 {background:#f3cf29 !important}
.bgc_f7e076 {background:#f7e076 !important}

.bgc_a6bddb {background:#a6bddb !important}
.bgc_2b8cbe {background:#2b8cbe !important}
.bgc_fa9fb5 {background:#fa9fb5 !important}
.bgc_c51b8a {background:#c51b8a !important}
.bgc_c2e699 {background:#c2e699 !important}
.bgc_78c679 {background:#78c679 !important}
.bgc_bcbddc {background:#bcbddc !important}
.bgc_756bb1 {background:#756bb1 !important}

.statistics_period {
    width:94px;
    padding-left:11px;
    line-height:28px;
}

.donut-inner {
    text-align: center;
    margin-top: -53%;
    margin-bottom: 53%;
}

.donut-inner h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 26px;
}

.mt3 {margin-top:3px !important}
.mt6 {margin-top:6px !important}
.mt8 {margin-top:8px !important}
.mt15 {margin-top:15px !important}
.mt20 {margin-top:20px !important}
.mt35 {margin-top:35px !important}

.mlm20 {margin-left:-20px !important}
.ml0 {margin-left:0 !important}
.ml10 {margin-left:10px !important}

.mr8 {margin-right:8px !important}

.pb0 {padding-bottom:0;}

.w30 {width: 30% !important}
.w40 {width: 40% !important}
.w50 {width: 50% !important}
.w60 {width: 60% !important}
.w70 {width: 70% !important}
.w72 {width:72px;}
.w75 {width:75px;}



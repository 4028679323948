.playlist_copy_popup textarea {
    width: 200px;
    height: 80px;
}

.playlist_copy_title_nowrap {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

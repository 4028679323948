.input_number_wrap {
    position: relative !important;
}
.input_number_wrap input {
    width: 100%;
    padding-right: 31px;
    padding-left: 14px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.input_number_wrap input.error {
    border-color: rgb(247, 147, 148);
}

.input_number_wrap input::-webkit-outer-spin-button,
.input_number_wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_number_wrap input[type=number] {
  -moz-appearance:textfield;
}

.input_number_wrap button {
    position: absolute;
    width: 17px;
    height: 13px;
    border: 0;
    background: none;
}
.input_number_wrap .number_up {
    top: 0px;
    right: 8px;
    background: url('../../images/button/input_number_up.png') no-repeat 5px 5px;
    cursor: pointer;
}
.input_number_wrap .number_down {
    top: 13px;
    right: 8px;
    background: url('../../images/button/input_number_down.png') no-repeat 5px 4px;
    cursor: pointer;
}

.whiteButton {
    min-width: 72px;
    height: 28px;
    border: 1px solid #5e5e5e;
    color: #363636;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    border-radius: 15px;
    text-align: center;
    outline: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.whiteButton:hover {
    cursor: pointer;
    border: solid 1px #c9ccd1;
    background: #e7eaf0;
    color: #000000;
}

.whiteButton.disabled {
    cursor: inherit;
    border-color: #c2c2c2;
    color: #d0d0d0;
    background: #fff;
}

.whiteButton.selected {
    cursor: pointer;
    border: solid 1px #dde0e5;
    background: #eff0f1;
    color: #848484;
}
.text_input_wrapper input {
    padding: 0 10px;
    border: 1px solid #dde0e5;
    border-radius: 5px;
    line-height: 26px;
    font-size: 12px;
    color: #686b72;
    height: 25px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.text_input_wrapper span.error {
    display: block;
    color: #f79394;
    line-height: 13px;
}

.text_input_wrapper span.success {
    display: block;
    color: #3196FF;
    line-height: 13px;
}

.text_input_wrapper input:disabled:hover {
    cursor: default;
}

.text_input_wrapper input.light_gray {
    background: #fbfcfd;
    color: #686b72;
}
.text_input_wrapper input.light_gray:hover {
    border-color: #b4b9c3;
}
.text_input_wrapper input.light_gray:active {
    border-color: #b4b9c3;
}
.text_input_wrapper input.light_gray:disabled {
    border-color: #e5e8ec;
    background: #f5f5f5;
    color: #aeb0b3;
}
.text_input_wrapper input.light_gray:disabled:hover {
    border-color: #e5e8ec;
    background: #f5f5f5;
    color: #aeb0b3;
}

.text_input_wrapper input.check_confrim {
    background: #fbfcfd url('../../images/img/signup_check_confrim.png') right no-repeat;
}
.text_input_wrapper input.error,
.text_input_wrapper input.error:hover,
.text_input_wrapper input.error:active {
    border-color: #f79394 !important;
}

.text_input_wrapper .error_txt3 {
    position: relative;
    left: 0px;
    width: 20px;
    color:#f79394;
}

.text_input_wrapper input.noline {
    border: 1px solid #fff;
    color: #686b72;
}
.text_input_wrapper input.noline:hover {
    border-color: #caced5;
}
.text_input_wrapper input.noline:active {
    border-color: #caced5;
}
.text_input_wrapper input.noline:disabled {
    border-color: #eaeaea;
    background: #eaeaea;
    color: #bababa;
}
.text_input_wrapper input.noline:disabled:hover {
    border-color: #eaeaea;
    background: #eaeaea;
    color: #bababa;
}

.text_input_wrapper .empty_button {
    position: absolute;
    right: 3px;
    background: no-repeat url('../../images/button/popup_btn_circle_close_normal.png');
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    vertical-align: middle;
    margin-top: 5px;
}
.text_input_wrapper .empty_button:hover {
    background: no-repeat url('../../images/button/popup_btn_circle_close_hover.png');
    background-size: 15px 15px;
}
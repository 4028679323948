
.ruleset_detail {
    width: 400px;
    float: left;    
}

table #rulesetDetailBox {
    background-color: #eeeeef;
    border-radius:2px;
    margin-right : 10px;
    width:317px
}

table #rulesetDetailBox th {
    background-color: #eeeeef;
    border-radius:2px;
    margin-right : 10px;
    padding:7px 10px 7px 10px;
}


.ruleset_detail_tag_wrap {
    margin-top: 20px;    
    position: relative;    
}
.ruleset_detail_tag_wrap .whiteButton {
    float: right;
    border: 1px solid #dde0e5;
}
.ruleset_detail_popup_tag {
    border-top: 0;
    border-bottom-left-radius:2px;
    font-size:12px;
    height: 330px;
    overflow-x:hidden;
    overflow-y:auto;
    margin-top:31px; 
}

.ruleset_detail_popup_tag Button {
    border-radius: 5px;
    text-align: left;
    padding: 0 5px 0 9px;
}

.ruleset_detail_popup_tag table {
    border-collapse: collapse;
    border-spacing: 0; 
    /* margin-top : 30px;  */
}

.ruleset_detail_popup_tag table thead {
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;
    background: #eeeeef;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-left: 1px solid #d2d2d3;
    border-right: 1px solid #d2d2d3;
}

.ruleset_detail_popup_tag table thead th {
    background: #eeeeef;
    vertical-align:middle;
    padding:7px 10px 7px 10px;
    border-top:1px solid #d2d2d3;
    border-bottom:1px solid #d2d2d3;
    color: #797979;
}

.ruleset_detail_popup_tag table tr {
    border-bottom: 1px solid #f2f2f2;
}

.ruleset_detail_popup_tag table tr th:first-child {
    padding-left: 17px;
    background: url(../../../images/bg/table_divider.png) no-repeat right;
}

.ruleset_detail_popup_tag table tr th:last-child {
    border-right:0;

}

.ruleset_detail_popup_tag table tr td {
    color:#818181 !important;
    vertical-align: middle;
    height: 28px;
    padding: 5px 10px 5px 10px;    
    white-space: pre-line;    
}

.ruleset_detail_popup_tag table tr td label {
    color:#818181;
    display: inline-block;
}

.ruleset_detail_popup_tag table tr td:first-child {
    padding-left:10px;  
    cursor:pointer;
}

.ruleset_detail_popup_tag table tr:last-child td {
    border-bottom: 0 solid #f2f2f2;
}

.ruleset_detail_popup_tag table tr:hover td {
    background:#f3f9ff;
}

.ruleset_detail_popup_tag table tr.on td,
.ruleset_detail_popup_tag table tr:active td {
    background:#e1eefb;
    color:#2a2a2a;
}

.ruleset_detail_popup_tag table tr td input[type="radio"] {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
}

.ruleset_detail_popup_tag table tr td label > span {
    display:inline-block;
    background:#eeba07;
    font-size:9px;
    color:#fff;
    border-radius:10px;
    padding:0 5px;
    margin-left:9px;
}

.ruleset_detail_popup_tag .tag_condition_btn {
    width: 130px !important;
}

.ruleset_detail_popup_tag .tag_condition_input {
    width: 110px !important;
}


.ruleset_detail_popup_tag .tag_condition_option_list {
    position: relative;
    top: 5px;
    right: 62px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 10;
    width: 190px;
    overflow-y: auto;
    max-height: 150px;
}

.ruleset_empty_button{
    position: absolute;
    right: 3px;
    background: no-repeat url('../../../images/button/popup_btn_circle_close_normal.png');
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    vertical-align: middle;
    margin-top: 5px;
}
.ruleset_empty_button:hover {
    background: no-repeat url('../../../images/button/popup_btn_circle_close_hover.png');
    background-size: 15px 15px;
}
.progress_bar {
    position: relative;
}

.filler {
    height: 100%;
    transition: width .2s ease-in;
}

.filler > span {
    font-size: 14px;
    display: inline-block;
    margin-right: 7px;
    color: #fff;
    line-height: 19px;
    text-align: right;
    position: absolute;
}

.filler > span > span {
    font-size: 12px;
}

.filler .contentScheduleProgress {
    display: flex;
    margin-top: 3px;
    margin-left: 13px;
    color: #8c8c8c;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
}

.filler .contentScheduleProgress .progressBarStatus {
    width: 260px;
}

.filler .contentScheduleProgress .progressBarStatus > .programName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 230px;
}

.filler .contentScheduleProgress .progressBarClose {
    position: absolute;
    left: 260px;
}

.filler .contentScheduleProgress .progressBarClose > .closeImg {
    width: 7px;
    height: 15px;
    background: url('../../images/img/minimized_close.png') no-repeat 0 0;
    margin-left: 22px;
    margin-top: 5px;
    cursor: pointer;
}

.filler .contentScheduleProgress .progresBarClose > .percent {
    width: 35px;
    height: 20px;
    text-align: right;
}

.used_content_delete_popup .content_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0px !important;
    /*max-height: 40px;*/
}

.used_content_delete_popup .detail_view > p{
    color: #999999;
    font-size: 11px;
}

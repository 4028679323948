.edit_device_wrap .device_edit_panel_wrap .devicepop .device_time_holiday > div {
    margin-right: 8px;
    margin-bottom: 8px;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap {
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    font-size: 13px;
}

.device_edit_panel_wrap.device_time_edit .device_timer_date {
    width: 130px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.device_edit_panel_wrap.device_time_edit .device_timer_holiday {
    width: 120px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.device_edit_panel_wrap.device_time_edit .warning {
    background: url('../../../../images/icon/bullet_1.png') no-repeat 0 3px;
    padding-left: 17px;
    color: #a9a9a9;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap > table tr th {
    background: #fafafa;
    border-right: 1px solid #f0f0f0;
    line-height: 30px;
    padding-left: 12px;
    font-size: 13px;
    color: #8d8d8d;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap > table tr td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    /* background: #e6f2ff; */
    color: #000;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr td img {
    vertical-align: middle;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr td:first-child {
    padding-left: 14px;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr td {
    border-bottom: 1px solid #f2f2f2;
    line-height: 37px;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr:last-child td {
    border-bottom: 0;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap tr.time_detail td {
    padding: 0 0 0 0 !important;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap tr.on td {
    background: #e6f2ff;
    color: #000;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap tr th label,
.device_edit_panel_wrap.device_time_edit .timer_wrap tr td label {
    text-indent: -9999px;
    display: inline;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr.on,
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr:active td {
    background: #e1eefb;
    color: #2a2a2a;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap > table tr:hover td {
    background: #f3f9ff;
}
.device_edit_panel_wrap.device_time_edit .timer_wrap table tr.timer_line_info:hover td {
    cursor: pointer;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap table .time_detail {
    background: #fafafc;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap table .time_detail > td {
    padding: 15px 20px !important;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap table .time_detail .time_detail_wrap .time_detail_table {
    table-layout: fixed;
    width: 100%;
    font-size: 13px;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap table .time_detail .time_detail_wrap .time_detail_table td {
    color: #797979;
    padding: 12px;
    border-bottom: 0px;
    vertical-align: middle;
}

.device_edit_panel_wrap.device_time_edit .timer_wrap table .time_detail .time_detail_wrap .time_detail_table .time_detail_day .checkboxWrap {
    margin-right: 10px;
}
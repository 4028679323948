.create_playlist_popup_wrap {
}

.create_playlist_popup_wrap .quest_img_noti a {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 13px;
    background: url('../../../images/img/qu_notice.png') no-repeat;
}

.create_playlist_popup_wrap .opacity_notice_wrap {
    background: rgba(224,224,224,0.85);
    border: 1px solid #fff;
    position: fixed;
    padding: 20px;
    color: #000;
    line-height: 22px;
}

.create_playlist_popup_wrap .opacity_notice_wrap > table > tbody > tr > td {
    color: #000000;
    padding: 0 !important;
}
.create_playlist_popup_wrap #contentApproverTable th, td {
    color: #59616b !important;
}

.create_playlist_popup_wrap .error_txt2 {
    width: 100%;
    color: #f79394;
    line-height: 13px;
}

.dialog_button_wrap {
    border-top: 1px solid #f5f5f5;
    margin: 0 25px;
    padding: 20px 0;
    text-align: center;
}
.dialog_button_wrap > div > button:first-child {
    margin-right: 15px;
}

.create_playlist .ui-dialog-content {
    overflow: visible!important;
}
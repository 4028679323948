.content_playlist_manager_wrap {
    width : 100%;
    height: 100%;
}

.playlist_manager_buttonsWrap {
    height: 38px;
    position: relative;
    display: inline-block;
    width: -webkit-fill-available;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
}

.playlist_manager_buttonsWrap button:first-child {
    margin-left: 0
}

.playlist_manager_buttonsWrap button {
    margin-left: 8px;
}

.half .playlist_thumbnail {
    width: 45%
}

.btn_area {
    padding-left: 30px;
    padding-right: 20px;
    margin-bottom: 4px;
    width: 100%;
}

.playlist_thumb_top {
    background: #eff0f1;
    display: flex;
}

.playlist_thumbnail {
    background: #eff0f1;
    position: relative;
    height: 100% !important;
    overflow-y: hidden;
    overflow-x: auto;
    border-bottom: 0;
    padding-right: 55%;
    margin-bottom: 30px;
}

.playlist_thumbnail .leftButton {
    display: inline-block;
    float:left;
}

.playlist_thumbnail .rightButton {
    display: inline-block;
    float:right;
}

.half .playlist_thum	{width:45%}
.half .playlist_preview	{
    width: 55%;
    top: 78px;
    position: absolute;
    background-color: rgb(249, 250, 251);
    right: 0;
    z-index:1;
}

.playlist_preview .playlist_top {
    padding: 10px 0 34px 0;
    line-height: 28px;
    border-bottom: 1px solid #e7e7e7;
    height: 28px;
}

.contentBrowserGroupBtn {
    width: 24px;
    height: 24px;
    border: 0px solid #dde0e5;
    color: #848484;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    background: url('../../images/button/btn_group.png') no-repeat 0 0;
}

.playlist_preview .playlist_top .float_l span {
    display: inline-block;
    margin-left: 10px;
    color: #b8b9b9;
    font-size: 13px;
    line-height: 28px;
}

.playlist_preview .playlist_top .float_l h3 {
    display: inline-block;
    margin: 0;
    font-size: 16px;
    line-height: 28px;
}

.playlist_preview .playlist_top .float_r {
    margin-right: 25px;
}

.playlist_preview .playlist_top .float_l {
    margin-left: 30px;
}

.playlist_preview .playlist_top .float_r > button {
    margin-right: 8px;
}
.playlist_preview .playlist_editor_wrap .animated li > div {
    height: 136px;
}
.playlist_editor_wrap > h3 {
    margin-top: 12px;
    height: 30px;
    font-size: 12px;
    font-weight: normal;
    color: #848484;
}

.playlist_editor_wrap .animated ul > div > li > div {
    float: left;
    position: relative;
    width: 86px;
}

.playlist_editor_wrap .animated ul > div > li > div > img {
    width: 78px;
    height: 78px;
    border: 4px solid #fff;
    box-shadow: 0px 1px 0px #e7e7e7;
}

.playlist_preview .playlist_editor_wrap.type01 .animated ul > div > li {
    padding: 0 5px 75px 7px;
}

.playlist_preview .playlist_editor_wrap.type01 .animated ul > div > li .name {
    margin: 0 0 -9px 0;
    font-size: 12px;
    /* line-height: 22px; */
}

.time_setting_wrap {
    position: absolute;
    width: 150px!important;
    top: 110px;
    display: inline-flex;
    height:30px;
}

.time_setting_wrap.tagplaylist {
    position: relative!important;
    top: 15px!important;
    left: 0px!important;
}

.space {
    line-height: 28px;
}

.time_setting_wrap span {
    display: inline-block;
    float: left;
    height:28px;
}

.time_setting_wrap input[type="text"] {
    display: inline-block;
    width: 20px;
    float: left;
}

.playlist_tag_info {
    z-index: 3;
    left: 4px;
    top: 2px;
    background: rgba(224,224,224,0.85);
    border: 1px solid #fff;
    position: absolute;
    padding: 5px;
    color: #000;
    line-height: 22px;
    display: none;
}

.playlist_editor_wrap .delete {
    border: 0;
    position: absolute;
    top: -11px;
    right: 0px;
    float: right;
    width: 19px;
    height: 19px;
    background: url('../../images/icon/dashboard_delete_normal2.png');
    margin-top: 9px;
    z-index: 1;
    display: block;
}

.playlist_editor_wrap .playlist_settings {
    position: absolute;
    top: 70px;
    right: 0px;
    width: 19px;
    height: 19px;
    background: url('../../images/icon/setting_normal.png.png') no-repeat 0 0;
    border: 0;
    z-index: 1;
    display: block;
}

.playlist_editor_wrap .check_on {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 78px;
    height: 78px;
    background: url('../../images/img/playlist_list_on.png') no-repeat 0 0;
    background-size: 78px 78px;
    display: block;
}

.playlist_editor_wrap .tag_check_on {
    position: absolute;
    top: 17px;
    left: 1px;
    width: 78px;
    height: 78px;
    background: url('../../images/img/playlist_list_on.png') no-repeat 0 0;
    background-size: 78px 78px;
    display: block;
}
.playlist_editor_wrap .none {
    display: none;
}

.playlist_editor_wrap .transition_wrap {
    position: relative;
    height: 110px;
}

.playlist_preview .playlist_editor_wrap .animated ul > div > li > div {
    height: 136px;
}
.playlist_editor_wrap .animated ul > div > li > div {
    float: left;
    position: relative;
    width: 86px;
}

.playlist_preview .bot_wrap {
    position: absolute;
    z-index: 100;
    bottom: 0px;
    left: -30px;
    right: 0;
}

.transition_option, .transition_delay_option {
    position: absolute;
    /* right: 94px; */
    top: 0;
    background: #e6e6e6;
    width: 96px;
    height: 100%;
    text-align: center;
    padding-left: 5px;
}

.playlist_preview .bot_wrap .transition {
    position: relative;
    padding: 0 0 0 15px;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
}

.transition_wrap .transition {
    background: #ececec;
    padding-left: 12px;
    height: 100%;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
}

.transition_wrap .transition > ul {
    padding-top: 25px;
    width: 45000px;
}
.transition_wrap .transition > ul > li:first-child {
    margin-left: 10px;
}
.transition_wrap .transition > ul > li {
    float: left;
    margin-right: 10px;
    position: relative;
}

.transition_wrap .transition > ul > li > img {
    border: 4px solid #fff;
    box-shadow: 0px 1px 0px #e7e7e7;
    width: 40px;
    height: 40px;
}

.transition_wrap .transition > ul  > li.on > img {border-color:#3196ff;}

.transition_wrap .transition > ul > li > span {
    width: 86px;
    text-align: center;
    color: #818181;
    display: block;
    font-size: 9px;
    line-height: 10px;
}
.transition_option, .transition_delay_option {position:absolute;/*right:94px;*/top:0;background:#e6e6e6;width:96px;height:100%;text-align:center;padding-left:5px;}
.transition_option:hover {background:#ececec}
.transition_option:active {background:#dfdfdf}
.transition_option > a {display:inline-block;width:100%;height:150px;color:#737373;}
.transition_option img {margin-top:48px;margin-bottom:7px;}
.transition_option.anima {right:94px;top:0px;background:#eeeeee;height:185px;z-index:100}
.transition_option.anima > a {display:inline-block;width:100%;height:181px;color:#737373;}
.transition_option.anima img {margin-top:64px;margin-bottom:8px;}
.transition_option.anima:hover {background:#f5f5f5}
.transition_option.anima:active {background:#e7e7e7}

.transition_wrap .transition_option .save_transition {background-image: url('../../images/icon/circle_confirm_select.png');background-size:70px 70px; margin-left:12px;margin-top:10px;width:70px;height:70px;float:left;}
.transition_wrap .transition_option .save_transition:hover {background-image: url('../../images/icon/circle_confirm_hover.png');background-size:70px 70px; margin-left:12px;margin-top:10px;width:70px;height:70px;float:left;}
.transition_wrap .transition_option .save_transition:active {background-image: url('../../images/icon/circle_confirm_hover.png');background-size:70px 70px; margin-left:12px;margin-top:10px;width:70px;height:70px;float:left;}

.transition_wrap .transition {background:#ececec;padding-left:12px;height:100%;border-bottom:1px solid #e9e9e9;position:relative;}
.transition_wrap .transition > ul {padding-top: 25px;width:45000px;}
.transition_wrap .transition > ul  > li {float:left;margin-right:10px;position:relative;}
.transition_wrap .transition > ul  > li:first-child {margin-left:10px;}
.transition_wrap .transition > ul  > li > img {border:4px solid #fff;box-shadow:0px 1px 0px #e7e7e7;width:40px;height:40px;}
.transition_wrap .transition > ul  > li.on > img {border-color:#3196ff;}
.transition_wrap .transition > ul  > li > span {width:86px;text-align:center;color:#818181;display:block;font-size:9px;line-height:10px}
.transition_wrap .transition > ul  > li .hover_cover {display:none;background:rgba(243,249,255,0.85);width:40px;height:40px;position:absolute;left:4px;top:4px;overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    word-break: break-word;}
.transition_wrap .transition > ul  > li:hover .hover_cover {display:-webkit-box;}


.addedContent {
    display: inline-flex;
    position:relative;
    height:86px;
    float: left;
    margin-bottom: 10px;
    width: 150px;
}

.addedContent.tagContent {
    display: inline-flex;
    position:relative;
    height:86px;
    float: left;
    margin-bottom: 10px;
    width: 120px;
}

.clicked{
    border-radius:2px;
    box-shadow: 0px 0px 0px 3px #3196ff;
}

.apply_to_all_IP{
    position: absolute;
    bottom: 10%;
}
.apply_to_all{
    position: absolute;
    top: 40%;
}

.playlist_tag_thumbnail  {
    margin-top: 10px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-position: center;
    background-size: 80px;
    background-image: url('../../images/img/folder-icon_thumbnail.png');
    background-repeat: no-repeat;
    display: flex;
}

.playlist_editor_wrap .animated > ul > div > li > button {
    float: left;
    background: none;
    border: 0;
    width: 50px;
    height: 22px;
    margin: 26px 3px 0px 0px;
    padding: 0;
}

.playlist_effect_info {
    z-index: 3;
    top: 120px;
    width: 250px !important;
    height: 50px !important;
    background: url('../../images/bg/thumb_layer_bg.png') repeat;
    border: 1px solid #fff;
    padding: 5px;
    color: #000;
    line-height: 22px;
    display: none;
    position: absolute!important;
}

.playlist_preview .playlist_editer_wrap.type01 .animated ul li .name {
    margin: 0 0 -9px 0;
    font-size: 12px;
    text-align: start!important;
}

.text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

/* for image view */
.poplist {
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 0;
    margin-right: 0;
    min-width: 500px;
    margin-top: 10px;
    word-break: break-all;
}

.pop_list ul li {
    color: #797979;
    vertical-align: middle;
    padding: 5px 0px 0px 5px;
}
.device_info span {
    color: #7f7f7f;
}

.thumbnailview_wrap ul {

}

.thumbnailview_wrap li {
    float: left;
    margin: 0px 20px 10px 0;
    height: 70px;
    color: #797979;
    vertical-align: middle;
    padding: 5px 0px 0px 5px;
}
.thumbnailview_wrap .thumbview_box {
    width: 100px;
    height: 100px;
    position: relative;
}
.thumbnailview_wrap .device_info, .device_tag_info {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../images/bg/thumb_layer_bg.png') repeat;
    color: #000;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    z-index: 100;
}
.thumbnailview_wrap .device_info.add {
    font-size: 8px;
}
.thumbnailview_wrap .device_info.add > div {
    height: 100%;
    text-align: left;
    vertical-align: middle;
    padding: 15px 0 0 15px;
}
.thumbnailview_wrap .device_info > div {
    display: table;
    width: 100%;
    height: 100%;
}
.thumbnailview_wrap .device_info:hover {display:block;}
.thumbnailview_wrap .device_info strong {display:block;padding:0 0 0 0;}
.thumbnailview_wrap .device_info span {color:#7f7f7f;}

.thumbnailview_wrap .device_info span {
    width: 100px;
    height: 65px;
    vertical-align: middle;
    display: table-cell;
}

.playlist_thumbnail .thumbnailview_wrap .device_info span {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 22px;
    word-wrap: break-word;
    -webkit-box-pack:center;
}

.thumbnailview_wrap .name {
    font-size: 10px;
    text-decoration: none;
    color: #818181;
    text-align: center;
}
.thumbview_box:hover .device_info {display:table;}
.thumbview_box.tiny:hover .device_info {display:none;}
.thumbview_box.information .device_info {display:table;}
.thumbview_box.information.tiny .device_info {display:none;}
.thumbview_box.tag_information .device_tag_info {display:block;}
.thumbview_box.tag_information.tiny .device_tag_info {display:none;}

.playlist_tooltip {
    padding-top:20px;
    position:absolute;
    background-color:#fff;
    padding:10px;
    border: 2px solid #DDDEDE;
    border-radius: 10px;
    width: fit-content;
    z-index: 10000;
}
.playlist_tooltip .contentName > span {
    margin:0; color:#000; font-size:12px; line-height:22px;
}
.playlist_tooltip .contentName {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 22px;
    max-height: 44px;
    word-wrap: break-word;
    width: 175px;
}
.playlist_tooltip .info p	{margin:0; color:#808080; font-size:12px; line-height:22px;text-align: left;}
.playlist_tooltip .info p span	{color:#000}
.playlist_tooltip .info p span.icon img {margin-right:3px;}

.tag:hover {
    background: url('../../images/icon/label_hover.png.png');
}

.tag {
    position: absolute;
    top: -3px;
    left: -5px;
    width: 19px;
    height: 19px;
    background: url('../../images/icon/label_normal.png.png') no-repeat 0 0;
    border: 0;
}

.zoomInBtn {
    position: absolute;
    top: 70px;
    left: -5px;
    width: 20px;
    height: 20px;
    background: url('../../images/icon/icon_zoom_nor.png') no-repeat 0 0;
    border: 0;
    z-index: 3;
}

.tag_input {
    position: relative;
    display: inline-block;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid #D6DEE8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 1px;
}

.ellipsis2 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
}

.tag_input > span > input[type="text"] {
    line-height: 28px;
    background: #eff2f6;
    width: 90%;
    padding: 0 !important;
    border: 0 !important;
}

.tag_input > span > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url('../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
}

.minusContentBtn span {background:url('../../images/button/minus_icon_nor.png') no-repeat;}
.minusContentBtn span:hover {background:url('../../images/button/minus_icon_over.png') no-repeat;}
.minusContentBtn span:active {background:url('../../images/button/minus_icon_press.png') no-repeat;}

.plusContentBtn span {background:url('../../images/button/plus_icon_nor.png') no-repeat;background-position-x:5px;}
.plusContentBtn span:hover {background:url('../../images/button/plus_icon_over.png') no-repeat;background-position-x:5px;}
.plusContentBtn span:active {background:url('../../images/button/plus_icon_press.png') no-repeat;background-position-x:5px;}

.expiredDate.disable {
    position: absolute;
    width: 100%;
    height: 87px;
    background-color: #fff;
    opacity: 0.9;
    filter: alpha(opacity=90);
    -ms-filter: "alpha(opacity=90)";
}
.playlist_editor_wrap .expired_content_settings {
    position:absolute;top:70px;left:0px;width:19px;height:19px;background:url('../../images/icon/widget_ic_devi_warning.png') no-repeat 0 0;border:0;z-index:1;display:block;
}

.pop_circle {
    width: 27px;
    /* height: 27px; */
    border: 0;
    padding: 0;
    border-radius: 15px;
    background: none;
}

.pop_circle > span {
    display: inline-block !important;
    width: 27px;
    height: 27px;
}

.playlist_thumbnail .content_title {
    padding: 0px;
    color: #686b72;
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
    height: 30px;
}

.detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.list_wrap_loading {width:100%; text-align:center; margin-top: 40%; margin-left: -30px;z-index: 100}
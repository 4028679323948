.device_number_input_wrap {
    position: relative !important;
}

.device_number_input_wrap input {
    width: 60px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    /* Chrome, Safari, Edge, Opera */
}

.device_number_input_wrap input::-webkit-outer-spin-button,
.device_number_input_wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.device_number_input_wrap input[type=number] {
  -moz-appearance:textfield;
}

.device_number_input_wrap .up_btn,.down_btn {
    width: 20px;
    height: 27px;
    vertical-align: middle;
    padding: 0;
    border: 0;
    background: url('../../images/button/btn_control_chn.png') no-repeat 0 0;
}

.device_number_input_wrap .down_btn {
    /* left: 7px; */
    margin-right: 10px;
}

.device_number_input_wrap .up_btn {
    background-position: 0 -27px;
    margin-left: 10px;
}

.toggleSwitchWrap input[type="checkbox"] {width:0;height:0;margin:0;display:none;}
.toggleSwitchWrap input[type="checkbox"] + label {padding-left:35px;color:#59616b;background:url('../../images/img/toggle_off.svg') no-repeat;background-position-y:1px;background-size:30px;padding-bottom:3px;cursor:pointer;height: 25px;}
.toggleSwitchWrap input[type="checkbox"] + label:hover {background:url('../../images/img/toggle_off.svg') no-repeat;background-position-y:1px;background-size:30px;}
.toggleSwitchWrap input[type="checkbox"]:disabled + label:hover {background:url('../../images/img/toggle_off.svg') no-repeat;background-position-y:1px;background-size:30px;cursor: default;}
.toggleSwitchWrap input[type="checkbox"]:disabled {background:url('../../images/img/toggle_off.svg') no-repeat;background-position-y:1px;background-size:30px;cursor: default;border:1px solid #000;}
.toggleSwitchWrap input[type="checkbox"]:checked + label {background:url('../../images/img/toggle_on.svg') no-repeat;background-position-y:1px;background-size:30px;}
.toggleSwitchWrap input[type="checkbox"]:checked:disabled + label {background:url('../../images/img/toggle_off.svg') no-repeat;background-position-y:1px;background-size:30px;}
.toggleSwitchWrap input[type="checkbox"]:checked:disabled + label:hover {background:url('../../images/img/toggle_on.svg') no-repeat;background-position-y:1px;background-size:30px;cursor: default;}
.toggleSwitchWrap input[type="checkbox"]:checked:disabled + label:hover {background:url('../../images/img/toggle_off.svg') no-repeat;background-position-y:1px;background-size:30px;cursor: default;}

.toggleSwitchWrap label.table {
    text-indent: -9999px;
}
.toggleSwitchWrap input[type="checkbox"] {
    width: 0;
    height: 0;
    margin: 0;
    display: none;
    position: absolute;
}
.warning {
    color: red !important;
}

.content_type_filter_wrap .filterMode {
    width:170px;
    position: absolute;
    top: 40px;
    right:90px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
}

.content_type_filter_wrap .filterMode > div {
    position: relative;
}

.content_type_filter_wrap .filterMode > div > span {
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background: url('../../images/bg/option_list_bg.png') no-repeat 10px 0;
}


.content_type_filter_wrap .filterMode > div > ul > li {
    padding: 4px 5px 4px 8px;
}
.content_type_filter_wrap .filterMode > div > ul > li:first-child {
    padding-top: 11px;
}
.content_type_filter_wrap .filterMode > div > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}
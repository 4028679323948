.alert_popup_wrap .ui-dialog-overlay {

}

.alert_popup_wrap .mis_dialog.modal .ui-dialog {

}

.alert_popup_message_wrap {
    width: 100%;
    height: 83px;
    text-align: center;
    display:table;
}

.alert_popup_message_wrap_text {
    display:table-cell;
    vertical-align:middle;
    font-size: 13px;
    color: #666666;
}
.event_condition_wrap table {
    border-collapse: collapse;
    border-spacing: 0;
}
.event_condition_wrap .contents {
    word-break: break-all;
}

.event_condition_wrap .contents_buttonwrap {
    height: 38px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-left: 20px;
}

.event_condition_wrap .contents_buttonwrap button{
    margin-left: 10px;
}

.event_condition_wrap .schedule_message .lnb_view {
    margin-right: 10px;
    display: none;
    float: left;

    width: 27px;
    height: 27px;
    background: url('../../../images/icon/lnb_view_normal.png') no-repeat;
    border: 0;
    border-radius: 14px;

}

.event_condition_wrap .schedule_message h2 {
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
}

.event_condition_wrap .schedule_message > div {
    padding: 0 0 0 24px;
    padding-bottom: 0px;
}

.event_condition_wrap .message_detail_setting > table > tbody > tr > th {
    vertical-align: middle;
    color: #646464;
}

.event_condition_wrap .message_detail_setting > table > tbody > tr > td {
    padding: 10px 0;
}

.event_condition_wrap .condition_wrap {
    border: 1px solid #e7e7e7;
    background: #fafafc;
}

.event_condition_wrap .condition_wrap > div {
    padding: 29px;
}

.event_condition_wrap .tag_input {
    position: relative;
    display: inline-block;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid
    #D6DEE8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 1px;
}

.event_condition_wrap .tag_input > span > input[type="text"] {
    line-height: 28px;
    background: #eff2f6;
    width: 90%;
    padding: 0 !important;
    border: 0 !important;
}

.event_condition_wrap .circle_btn.light_gray {
    border-color: #dde0e5;
    background: #fbfcfd;
}
.event_condition_wrap .circle_btn {
    padding: 0;
    height: 28px;
    width: 28px;
    border: 1px solid #dde0e5;
    color: #848484;
    background: #fff;
    border-radius: 15px;
    text-align: center;
}

.event_condition_wrap .circle_btn.light_gray {
    border-color:
            #dde0e5;
    background:
            #fbfcfd;
}

.event_condition_wrap .circle_btn.text > span {
    background: url('../../../images/icon/text_normal.png') no-repeat 7px 8px;
}
.event_condition_wrap .circle_btn > span {
    display: inline-block;
    height: 28px;
    width: 28px;
}

.event_condition_wrap .event_wrap .message_closebox {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    width: 19px;
    height: 19px;
    background: url('../../../images/img/tab_close_normal.png') no-repeat;
    border: 0;
    margin: 0;
}

.event_condition_wrap .condition_wrap h3 {
    margin: 0;
    background: #fff;
    line-height: 37px;
    font-size: 13px;
    padding-left: 19px;
    color: #969696;
    position: relative;
}

.event_condition_wrap .tag_input > span > button:hover {
    background: url('../../../images/icon/dashboard_delete_hover.png') no-repeat;
}
.event_condition_wrap .tag_input > span > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url('../../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
}
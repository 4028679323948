.secret_key_area {

}

.secret_key_area .separate_line {
    width: 1px;
    height: 80px;
    background-color: #DEDEDE;
    float: left;
}

.secret_key_area .qrcode {
    width: 90px;
    height: 90px;
    margin-left: 67px;
    margin-right: 27px;
    float: left;
}


.secret_key_area .key {
    width: 270px;
    height: 24px;
    color: #3196FF;
    margin-left: 24px;
    padding-top: 17px;
    float: left;
}

.error_txt_otp {
    width: 100%;
    color: #f79394;
    line-height: 13px;
    padding-left: 14px;
}

.info_txt_otp {
    height: 35px;
}



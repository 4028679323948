.polling_history_popup .dim_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: #aaa;
    opacity: 0.5;
}

.polling_history_popup .main_title {
    width: 462px;
    display: inline-block;
    padding-top: 13px;
}

.polling_history_popup .title_notice {
    width: 400px;
    color: red;
    font-size: 12px;
    display: inline-block;
    padding-left: 20px;
}

.polling_history_popup .mis_dialog .ui-dialog-container .ui-dialog .ui-dialog-titlebar{
    border-bottom: none;
}

.polling_history_popup .history_table_wrap{
    display: flex;
}

.polling_history_popup .history_table_left{
    float: left;
    min-height: 372px;
    max-height: 372px;
    min-width: 462px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 3px;
    border-right: 1px solid #e8e8e8;
}

.polling_history_popup .history_table_wrap .link_cell:hover{
    color: #609be8;
    text-decoration: underline;
    cursor: pointer;
}

.polling_history_popup .history_table_wrap .ReactTable .rt-table {
    overflow-x: hidden;
}

.polling_history_popup .history_table_wrap .ReactTable .rt-tbody{
    overflow-x: hidden;
}

.polling_history_popup .history_table_right{
    float: right;
    min-height: 372px;
    max-height: 372px;
    min-width: 462px;
    overflow-y: auto;
    padding-left: 3px;
    overflow-x: hidden;
}

.polling_history_popup .history_table_wrap .file_name_cell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}

.polling_history_popup .history_table_wrap .history_not_selected {
    float: right;
    min-height: 372px;
    min-width: 462px;
    padding-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
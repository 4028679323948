.minder_map{
    width: 100%;
    height: 100%;
}

/* important section */
.minder_map .mind-inner{position:relative;overflow-x: hidden;overflow-y: auto;width:100%;height:100%;}/*box-shadow:0 0 2px #000;*/
.minder_map .mind-inner{
    moz-user-select:-moz-none;
    -moz-user-select:none;
    -o-user-select:none;
    -khtml-user-select:none;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

/* z-index:1 */
.minder_map .mind-inner > canvas{position:absolute;z-index:1;}

/* z-index:2 */
jmnodes{position:absolute;z-index:2;background-color:rgba(0,0,0,0);}/*background color is necessary*/
jmnode{position:absolute;cursor:default;max-width:400px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
jmexpander{position:absolute;width:11px;height:11px;display:block;overflow:hidden;line-height:12px;font-size:12px;text-align:center;border-radius:6px;border-width:1px;border-style:solid;cursor:pointer;}

/* default theme */
jmnode{background-color:#fff;color:#333;font:16px/1.125 Tahoma,Arial,Dotum,sans-serif;}
jmnode.root{padding-left:16px;background-color:#fff;color:#333;border-radius:16px;border-style:solid;border-color:#b7b7b7;border-width:1px;font:Tahoma,Arial,Dotum,sans-serif;width:110px;height:32px;}
jmnode.selected{background-color:#fff;color:#333;border:1px solid #b7b7b7;border-radius:50px;}
jmexpander{border-color:gray; }
jmexpander:hover{border-color:#000;}

/* primary theme */
jmnodes.theme-primary jmnode{padding-left:16px;background-color:#fff;color:#333;border-radius:16px;border-style:solid;border-color:#b7b7b7;border-width:1px;font:16px/1.125 Tahoma,Arial,Dotum,sans-serif;line-height:2.0;text-align:left;width:110px;height:32px;}
jmnodes.theme-primary jmnode.selected{color:#333;background-color:#fff;}
jmnodes.theme-primary jmnode.root{color:#333;line-height:1.9;text-align:left;background-color:#fff !important;}
jmnodes.theme-primary jmexpander{}
jmnodes.theme-primary jmexpander:hover{}


jmnodes.theme-primary jmnode.depth2{padding: initial; background-color: #fff; color: #333; line-height: 1; width: auto; height: auto; border: initial;border-radius: 0px;}

.tag_content {
    width: 100%;
    height: 100%;
}
.tag_content:hover .tag_content_info {
    display: block;
}
.tag_content_info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../../images/bg/thumb_layer_bg.png) repeat;
    color: #000;
    font-size: 12px;
    text-align: center;
    z-index: 100;
    display: none;
}
.tag_content_info span {
    color: #7f7f7f;
}
.tag_content_info > div {
    display: table;
    width: 100%;
    height: 100%;
}
.tag_content_info.add {
    font-size: 8px;
}
.tag_content_info.add > div {
    height: 100%;
    text-align: left;
    vertical-align: middle;
}
.tag_content_info.add span {
    display: inline-block;
    padding-top: 2px;
}
.tag_content_info.add span:first-child {
    display: inline-block;
    padding-top: 0px;
}
.tag_content_info.add > div i {
    display: inline-block;
    font-style: normal;
    color: #fff;
    background: #9aacbf;
    border: 1px solid #9aacbf;
    border-radius: 2px;
    margin-right: 8px;
    width: 26px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
}
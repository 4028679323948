.detail_btn {
    border: 0;
    background: none;
    color: #8d8d8d;
    line-height: 28px;
    padding: 0;
    text-align: left
}

.detail_btn > span {
    width: 7px;
    height: 5px;
    display: inline-block;
    background: url('../../../../images/button/select_down.png') no-repeat;
    vertical-align: middle;
    margin-right: 9px;
}

.detail_btn.on > span {
    background: url('../../../../images/button/select_up.png') no-repeat;
}
.device_info_poper_wrap {
    position: absolute; 
    z-index: 101; 
    top: 40px;
    right: 450px; 
    width: 180px;
    display: block;
    background: #fff;
    border: 1px solid #dde0e5;
    padding: 0px 35px;
}

.device_info_poper_wrap table {
    margin: 15px 0px;
}

.device_info_poper_wrap .underline {
    border-bottom: 1px solid #ebebeb;
}


.ruleset_preview_selected_item_box{
    display: inherit;
}
.ruleset_preview_selected_item_box table th,
.ruleset_preview_selected_item_box table td {
    padding: 2px 0 !important;
}
.ruleset_preview_selected_item_box table th > span,
.ruleset_preview_selected_item_box table td > span {
    padding-left: 4px;
}

.ruleset_preview_pop_list {
    border-left: 1px solid #dad4d4;
    border-right: 1px solid #dad4d4;
    border-bottom: 1px solid #dad4d4;
    border-top: 1px solid #dad4d4;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: hidden;
    overflow-y: auto;
}

.ruleset_preview_pop_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    text-align: center;
    background: url(./images/table_divider.png) no-repeat right #eeeeef;
}

.ruleset_preview_pop_list table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: center;
    padding: 9px 10px 9px 10px;
    line-height: 20px;
    position: relative;
    color: #797979;
    word-break: break-all;
}

.ruleset_preview_pop_list table tr td span {
    display: inline-block;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

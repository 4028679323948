.edit_flip_device_dialog {
    height: 610px;
    overflow-y: auto;
}
.edit_flip_device_wrap {
    height: 600px;
    overflow-y: auto;
    padding: 0px 10px;
}

.edit_flip_device_wrap .changed {
    /* font-size: 13px; */
    color: #e55b48 !important;
}

.edit_flip_device_wrap .response_warning {
    background:url('../../../../images/icon/bullet_1.png') no-repeat 0 14px !important;
    padding-left:16px;
    color:#a9a9a9;
    background-position-y: center !important;
}

.edit_flip_device_wrap .device_edit_panel_wrap .devicepop {
    margin-right: 10px;
    border-bottom: 1px solid #f4f4f4;
}

.edit_flip_device_wrap .device_edit_panel_wrap .devicepop > h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    color: #676767;
}

.edit_flip_device_wrap .device_edit_panel_wrap .devicepop .system_restart_interval > span {
    margin-right: 10px;
}


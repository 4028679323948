.playlist_list_view_wrap .playlist_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0 !important;
}


.playlist_list_view_wrap .playlist_name_cell:hover {
    color: blue;
    cursor: pointer;
}

.float_l {
    float: left !important;
}

.playlist_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 71px;
    padding: 7px 0px 7px 0px !important;
}

.playlist_tr_group .rt-td {
    max-height: 70px;
    min-height: 70px;
    height: 70px;
}

.playlist_view_thumbnail  {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    border: red;
}

.playlist_thumb_render {
    height: 100%;
    overflow: hidden;
    display: flex;
}

.playlist_view_thumbnail.tagImg  {
    text-align: center;
    line-height: 56px;
}


.playlist_thumb_nested_wrap > span {
    display: block;
    width: 100%;
}

.playlist_thumb_wrap  {
    width : 90px;
    height : 70px;
}

.playlist_thumb_wrap_nestedPlaylist {
    width:100px;
    height:56px;
    line-height:56px;
    text-align: center;
    float: left !important;
}

.playlist_thumb_wrap_nestedIcon {
    line-height : 56px;
    height: 100%;
    text-align: right;
    width: 25px;
    float: right  !important;
}


.playlist_thumb_wrap > span {
    display: block;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 56px;
}

.playlist_thumb_wrap > span > span{
    display: block;
    height: 100%;
}


/*
.playlist_table_wrap .ReactTable .rt-tbody {
    overflow-y: auto;
    overflow-x: auto;
}
*/


.playlist_table_wrap .playlist_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0px !important;
}


.playlist_folder_thumbnail  {
    margin-left: 20px;
    width: 58px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    background-position: center;
    background-size: 56px;
    background-image: url('../../images/img/folder-icon_thumbnail.png');
    background-repeat: no-repeat;
    display: flex;
    float : left !important;
}

.playlist_export_button {
    margin-left: 10px;
}

.playlist_export_button > button{
    border: 1px solid #5e5e5e !important;
    color: #363636 !important;
    background: #fff !important;
    min-width: 76px;
}

.playlist_export_button > button > span{
    width: 100%;
}
.playlist_export_button > button > span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url(../../images/button/select_down.png) no-repeat 0 10px;
    margin-left: 10px;
}

.playlist_export_button > button:hover {
    cursor: pointer !important;
    border: solid 1px #e7eaf0 !important;
    background: #e7eaf0 !important;
    color: #000000 !important;
}

.playlist_export_button > button:disabled {
    cursor: inherit !important;
    border-color: #c2c2c2 !important;
    color: #d0d0d0 !important;
    background: #fff !important;
}

.playlist_export_button ul {
    width: 76px !important;
}
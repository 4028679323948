.device_health_list_view_wrap .device_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 70px;
}

.device_health_list_view_wrap .device_tr_group .rt-td {
    max-height: 70px;
}

.device_health_list_view_wrap .device_name_cell:hover,
.device_health_list_view_wrap .mac_address_cell:hover,
.device_health_list_view_wrap .link_cell:hover {
    color: blue;
    cursor: pointer;
}

.device_health_list_view_wrap .rt-th:nth-child(5) {
    background: none;
}

.device_health_list_view_wrap .rt-th:nth-child(5) .rt-resizer {
    cursor: default;
}

.device_health_list_view_wrap .rt-tr-group .rt-td:nth-child(5) {
    border-right: none;
}
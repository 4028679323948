.loading_overlay_wrap.wrapper {
    posiiton: relative;
}

.loading_overlay_wrap .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    textAlign: center;
    fontSize: 1.2em;
    color: #FFF;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99999;
}

.loading_overlay_wrap .content {
    margin: auto;
}

.loading_overlay_wrap .content:before {
    content: "";
    display: block;
    paddingTop: 100%;
}

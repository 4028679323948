.content_schedule_sub_table {
    position: relative;
    z-index: 1;
    background: #f5f9fc;
    color: #818181 !important;
    padding: 0;
}

.content_schedule_sub_table table {
    min-width: 100% !important;
    width: 100%;
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
}

.content_schedule_sub_table table tr {
    background: #f5f9fc !important;
}

.content_schedule_sub_table table tr th:first-child {
    border-left: 0;
}
.content_schedule_sub_table table tr th {
    position: relative;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #dde4e8;
    border-left: 1px solid #dde4e8;
    background: #ebf2f7;
    vertical-align: middle;
    color: #818181 !important;
}
.content_schedule_sub_table table thead {
    background: none;
    border: 0px;
}

.content_schedule_sub_table table tr:hover td {
    background: #eceef3;
    border-bottom: 1px solid #d2d2d3;
}
.content_schedule_sub_table table tr td:first-child {
    border-right: 0;
    padding: 10px 0 10px 17px;
    max-width:600px;
}
.content_schedule_sub_table table tr td {
    background:
            #f5f9fc !important;
    border-bottom: 1px solid
    #e9ecef !important;
    color:
            #818181 !important;
}
.content_schedule_sub_table table tr td {
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 7px 10px 7px 17px;
    line-height: 20px;
    position: relative;
}

.content_schedule_sub_table table tr td .updated {
    color: blue;
}
.external_power_wrapper > ul > li {
    width: 210px;
    display: inline-block;
    border-top: 1px solid #e8e8e8;
    padding: 7px 15px;
}
.external_power_wrapper > ul > li:last-child, .external_power_wrapper > ul > li:nth-last-child(2) {
    border-bottom: 1px solid #e8e8e8;
}
.external_power_wrapper > ul > li:nth-child(odd) {
    border-right: 1px solid #e8e8e8;
}
.external_power_wrapper > ul > li.empty {
    width: 440px;
    text-align: center;
    border-right: 0;
}
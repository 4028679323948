.statistics_summary {
    padding: 0 20px 0 19px;
    overflow-y: auto;
    height: 100%;
}

.statistics_summary > div {
    background: #fbfbfc;
    border-radius: 3px;
    padding: 28px 30px 30px 30px;
    width: 800px;
    position: relative;
}

.statistics_summary > div > ul > li {
    float: left;
    margin-right: 56px;
    margin-left: 106px;
}

.statistics_summary > div > ul > li:last-child {
    margin-right: 0;
}

.statistics_summary h6 {
    color: #494949;
    font-size: 12px;
    height: 44px;
    line-height: 13px;
    position: relative;
    margin-top: 0;
}

.statistics_summary h6 .numbering {
    display: block;
    position: absolute;
    top: -1px;
    left: 240px;
    border-radius: 7px;
    color: #fff;
    font-size: 11px;
    line-height: 17px;
    background: #a4a8ae;
    padding: 0 10px;
    text-align: center;
    font-weight: normal
}

.statistics_summary > div > ul > li > div > span {
    display: block;
    color: #626262;
    font-size: 11px;
}

.statistics_summary > div > ul > li > div > span > span {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    margin-right: 6px;
}

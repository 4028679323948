.custom_area .area_title {
    font-weight: bold;
    font-size: 13px;
    color: #363636;
    letter-spacing: 0;
    margin-left: 10px;
}

.custom_area .header {
    position: relative;
    width: 100%;
    height: 42px;
    display: flex;
    background: #F3F4F5;
    border-radius: 8px 8px 0 0;
}

.custom_area .header .title {
    color: #363636;
    font-size: 13px;
    line-height: 44px;
    padding-left: 16px;
    width: 100%;
    float: left;
}

.custom_area .header .close {
    background: url('../../../images/button/popup_btn_circle_close_normal.png') no-repeat;
    cursor: pointer;
    display: inline-block;
    width: 30px;
    margin-left: 10px;
    margin-top:5px;
    margin-right: 10px;
}

.custom_area .custom_widget {
    margin: 10px 5px 10px 5px;
    width: 100%;
    height: 265px;
    background: #FFFFFF;
    border: 1px solid #BDC2C8;
    border-radius: 8px;
}

.custom_area .custom_widget_body {
    position: relative;
    margin-top: 20px;
}

.custom_area .server_storage_container {
    display: inline-block;
}

.custom_area .server_detail {
    float: left;
    width: 45%;
    padding-left: 20px;
}

.custom_area .server_storage_container .chart {
    float: right;
    width: 43%;
    padding-right: 20px;
    text-align: right;
}

.storage-summary .li {
    margin: 3px 3px 3px 0;
    padding: 1px;
    float: left;
    height: auto;
    width: 100%;
}

.custom_widget .external_servers .server_row{
    display: flex;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    paddingRight: 10px;
}

.custom_widget .external_servers .server_row .server_name {
    width: 50%;
    line-height: 30px;
    font-size: 13px;
    color: #454545;
    letter-spacing: 0;
}

.custom_widget .external_servers .server_row .server_information {
    width: 50%;
    display: flex;
    height: 30px;
    line-height: 30px;
}

.server_information .chart {
    background-color: #E5E5E5;
    flex: 1;
    flex-grow :9;
    height: 20px;
}
.server_information .number {
    position: absolute;
    margin-top: -25px;
    color: #000;
    line-height: 35px;
    padding-left: 5px;
}

.server_information .total_area .total {
    padding-left: 10px;
    line-height: 35px;
    color: #000;
}

.custom_widget.dashboard-widget-drag-mode {
    background-color: #FFFFFF;
    border: 1px solid #BDC2C8;
    border-radius: 8px;
}

.custom_widget.dashboard-widget-drag-mode .header {
    position: relative;
    width: 100%;
    height: 42px;
    background-color: #f3f4f5;
    display: flex;
    border-radius: 8px 8px 0 0;
}

.custom_widget.dashboard-widget-drag-mode .header .title {
    color: #363636;
    font-weight: bold;
    font-size: 13px;
    line-height: 44px;
    padding-left: 16px;
    width: 100%;
    float: left;
}

.custom_widget .underline {
    width: 100%;
    height: 28px;
    position: relative;
    overflow: hidden;
    background: url('../../../images/bg/datetab_bg2.png') repeat-x bottom;
    margin-top:-10px;
}

.underline .external_servers_title {
    opacity: 0.6;
    font-family: Tahoma;
    font-size: 11px;
    color: #363636;
    letter-spacing: 0;
}

.custom_container {
    display: flex;
}

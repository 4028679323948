.circle_button {
    padding: 0;
    height: 28px;
    width: 28px;
    color: #848484;
    background: #fff;
    border-radius: 15px;
    text-align: center;
}

.circle_button:hover {
    border-color:#b5bac4;
    background:#eff0f1;
}

.circle_button_border {
    border:1px solid #dde0e5;
}

.circle_button.disabled {
    cursor: inherit;
    border-color: #e0dfdf;
    color: #d0d0d0;
    background: #eff0f1;
}
/* .circle_button_border:hover {
    border:1px solid #b4b9c3;
} */
.play_frequency {
    height: 100%;
}
.play_frequency_body {
    height: calc(100% - 78px);
    overflow-x: hidden;
    overflow-y: auto;
}
.play_frequency_export {
    height: 58px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;
    border-bottom: 1px solid #f2f2f2;
}
.play_frequency_period {
    height: 38px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;
}
.play_frequency_period_title {
    width: 93px;
    line-height: 28px;
    float: left;
    display: inline-block;
}
.play_frequency_selection {
    display: inline-flex;
    padding: 0 20px;
    width: 100%;
    margin-top: 20px;
}
.play_frequency_selection > div {
    width: 35%;
    border-radius: 3px;
    position: relative;
}
.play_frequency_selection > div > ul {
    clear: both;
    height: 182px;
    overflow-y: scroll;
    border: 1px solid #e5e5e5;
    margin-top: 12px;
    border-right: 0;
}
.play_frequency_selection > div > ul > li {
    border-bottom: 1px solid #e5e5e5;
    line-height: 30px;
    padding-left: 15px;
}
.play_frequency_selection > div > ul > li:last-child {
    border-bottom: 0px solid #e5e5e5;
}
.play_frequency_table_title {
    height: 38px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-left: 20px;
    font-weight: bold;
    color: #818181;
    font-size: 12px;
    line-height: 16px;
}
.play_frequency_table {
    height: fit-content;
    min-height: 520px;
    width: 100%;
    position: relative;
}
.play_frequency_table_chart {
    width: 100%;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    padding: 20px;
}
.pop-file-history_chart_count {
    width: 50%;
    min-width: 500px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
}
.pop-file-history_chart_count_title {
    height: 16px;
    font-weight: bold;
    color: #59616b;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 18px;
}
.pop-file-history_chart_count_body {
    height: 350px;
    width: 95%;
    min-width: 500px;
    float: left;
}
.pop-file-history_chart_duration {
    width: 50%;
    min-width: 500px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
}
.pop-file-history_chart_duration_title {
    height: 16px;
    font-weight: bold;
    color: #59616b;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 18px;
}
.pop-file-history_chart_duration_body {
    height: 350px;
    width: 95%;
    min-width: 500px;
    float: left;
}
.play_frequency_table .play_frequency_tr_body {
    overflow-y: overlay;
}
.play_frequency_table .play_frequency_tr_style {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding: 0px !important;
    height: 40px !important;
}
.play_frequency_table .play_frequency_td_props {
    padding: 10px 10px !important;
}
.ReactTable .rt-tbody .play_frequency_tr_style:last-child {
    border-bottom: 1px solid #f2f2f2;
}

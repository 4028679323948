.content_card_view_wrap {

}

.content_card_view_wrap .inline_block {
    display: inline-block;
}

.content_card_view_wrap .w20 {
    width: 20px;
}

.content_card_view_wrap .content_info {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../images/bg/thumb_layer_bg.png) repeat;
    color: #000;
    font-size: 12px;
    text-align: center;
    z-index: 100;
}
.content_card_view_wrap .thumbview_box:hover .content_info {
    display:block;
}
.content_card_view_wrap .content_info:hover {
    display:block;
}

.content_card_view_wrap .content_info.add {
    font-size: 8px;
}

.content_card_view_wrap .content_info.add > div{
    height: 100%;
    text-align: left;
    vertical-align: middle;
}

.content_card_view_wrap .content_info strong {
    display:block;
    padding:0 0 0 0;
}

.content_card_view_wrap .content_info span {
    color:#7f7f7f;
}

.content_card_view_wrap .content_info > div {
    display: table;
    width:100%;
    height:100%;
}

.content_card_view_wrap .content_info > div > div {
    width:100%;
    text-align:center;
    vertical-align:middle;
    line-height: 10px;
}

.content_icon {
    background-position: 0 5px;
    font-size: 11px;
    color: #848484;
}

.content_icon > img {
    margin-right: 6px;
}

.content_icon.embedded > img {
    position: absolute;
}
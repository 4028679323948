.server_info .power.on {
    background: rgb(70, 213, 181) none repeat scroll 0% 0%;
}

.server_info .power.off {
    background: rgb(214, 214, 214) none repeat scroll 0% 0%;
}

.server_info .power {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-top: 10px;
    margin-right: 8px;
    border-radius: 25px;
    vertical-align: top;
    line-height: 25px;

}
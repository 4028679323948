div.box{
	margin: 2px 2px;
    width: 128px;
    height: 58px;
    border-radius: 4px;
    padding: 4px 5px 0px 4px;
    float: left;
	color: #363636;
}

div.box .description {
    font-family: Tahoma;
    font-size: 11px;
    color: #FFFFFF;
    letter-spacing: 0;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 3px;
    margin-bottom: 3px;
}

div.box .name {
    font-family: Tahoma;
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 0;
    margin-left: 0px;
}

div.box.condition .op-icon {
	width: 12px;
	height: 12px;
}

div.box.condition .op-icon.circle {
	position: absolute;
	margin-top: 2px;
}

div.box.condition .op-icon.eq {
	background: url('./images/ic_equal.png') no-repeat 0 center;
}

div.box.condition .op-icon.ne {
	background: url('./images/ic_not_equal.png') no-repeat 0 center;
}

div.box.condition .op-icon.etc {
	background: url('./images/ic_etc.png') no-repeat 0 center;
}

div.box.condition .op-icon.invert {
	background: url('./images/ic_invert.png') no-repeat 0 center;
}

div.box.condition{
	opacity: 1;
	transform: translate(0, 0);
}

div.box.play{
    opacity: 1;
	transform: translate(0, 0);
    background-color: #727272;
    border: 4px solid;
    border-color: #727272;
}

div.box.new{
    background-color: #bbffc6;
}

div.box.condition.public{
    background-color: #dce6f2;
}
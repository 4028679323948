.group_container {
    height:90px;
    width: 420px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    padding:10px;
    margin-top:10px;
    overflow-y:auto;
}

.publish_software_popup .mis_dialog_body_wrap {
    padding-top : 0px;
}

.text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.save_playlist_popup_wrap {
    position: relative;
    padding: 0px 25px;
}

.save_playlist_popup_wrap .error_txt2 {
    width: 100%;
    color: #f79394;
    line-height: 13px;
}

.dialog_button_wrap {
    border-top: 1px solid #f5f5f5;
    margin: 0 25px;
    padding: 20px 0;
    text-align: center;
}
.dialog_button_wrap > div > button:first-child {
    margin-right: 15px;
}
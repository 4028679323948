.ledcabinet_warning_rule_wrap .quest_img_noti {
    float: right;
}

.ledcabinet_warning_rule_wrap .quest_img_noti span {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 13px;
    background: url('../../../../images/img/qu_notice.png') no-repeat;
    cursor: pointer;
}

.ledcabinet_warning_rule_wrap .quest_img_noti span:hover {
    background: url('../../../../images/img/qu_notice2.png') no-repeat;
}

.ledcabinet_warning_rule_wrap .quest_img_noti .opacity_notice_wrap {
    padding: 10px 20px;
    z-index: 2;
}

.ledcabinet_warning_rule_wrap .quest_img_noti .opacity_notice_wrap {
    background: rgba(224, 224, 224, 0.85);
    border: 1px solid #fff;
    position: fixed;
    padding: 20px;
    color: #000;
    line-height: 22px;
}

.ledcabinet_warning_rule_wrap .quest_img_noti .opacity_notice_wrap span {
    height: 30px;
}

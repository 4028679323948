.upload_ads_content_popup_wrap .ui-dialog-content {
    overflow-y: inherit !important;
    overflow-x:unset !important;
}

.upload_ads_content_popup {
    padding: 0 25px;
    position: relative;
}

.upload_ads_content_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.upload_ads_content_popup table th{
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.upload_ads_content_popup table td{
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.upload_ads_content_popup_wrap .upload_ads_content_popup .detail_view {
    margin-right: 10px;
    border-bottom: 1px solid #f4f4f4;
}

.upload_ads_content_popup .detail_view .tag_input {
    min-width:66px;
    display:none;
    position: relative;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid #D6DEE8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 1px;
}

.upload_ads_content_popup .detail_view .content_type { 
    display: flex;
}

.upload_ads_content_popup .detail_view .content_type .content_type_input{ 
    display: flex;
}

.upload_ads_content_popup .detail_view .tag_input > input[type="text"] {
    line-height: 28px;
    background: #eff2f6;
    padding: 0 !important;
    border: 0 !important;
    cursor: text !important;
}

.upload_ads_content_popup .detail_view .tag_input > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url('../../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
}

.upload_ads_content_popup .detail_view .circle_btn {
    padding: 0;
    height: 28px;
    width: 28px;
    border: 1px solid #dde0e5;
    color: #848484;
    background: #fff;
    border-radius: 15px;
    text-align: center;
}

.upload_ads_content_popup .detail_view .circle_btn span{
    display: inline-block;
    height: 28px;
    width: 28px;
}

.upload_ads_content_popup .detail_view .circle_btn.search span{
    background: url('../../../images/icon/search_circle_normal.png') no-repeat 8px 7px;
}

.upload_ads_content_popup .detail_view .circle_border {
    border: 1px solid #dde0e5 !important;
}

.upload_ads_content_popup .detail_view .content_empty_button{
    position: absolute;
    right: 12px;
    background: no-repeat url('../../../images/button/popup_btn_circle_close_normal.png');
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    vertical-align: middle;
    margin-top: 5px;
}
.upload_ads_content_popup .detail_view .content_empty_button:hover {
    background: no-repeat url('../../../images/button/popup_btn_circle_close_hover.png');
    background-size: 15px 15px;
}
.device_approval_popup_wrap .ui-dialog {

}

.device_approval_popup_wrap .ui-dialog-overlay {

}

.device_approval_body_wrap {
    height: 90%;
    max-height: 500px;
    overflow-y: auto;
}

.device_approval_body_wrap > table > tbody > tr {
    height: 40px;
}

.device_approval_body_wrap > table > tbody > tr .group_button {
    font-family: Tahoma, Arial, Dotum,sans-serif;
    color: #818181;
    font-size: 12px;
    line-height: 16px;
}

.device_approval_body_wrap > table > tbody > tr .checkboxWrap {
    margin-left: 5px;
}

.device_approval_body_wrap .device_date_picker {
    width: 120px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.device_approval_body_wrap .background_bar {
    background: url('../../../images/bg/lnb_bg.png') no-repeat left center;
}

.device_approval_body_wrap > table > tbody > tr > td .input_date_wrap .react-datepicker-popper {
    position: fixed !important;
    top: 195px !important;
    left: 167px !important;
    display: block;
}

.time_select_wrap .time_select_input {
    padding: 0 10px;
    border: 1px solid #dde0e5;
    border-radius: 5px;
    line-height: 26px;
    font-size: 12px;
    color: #686b72;
    height: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
}

.time_select_wrap .time_select_input:disabled {
    background: rgb(235, 235, 228);
}

.rc-time-picker-panel.rc-time-picker-panel-narrow.rc-time-picker-panel-column-2.rc-time-picker-panel-placement-bottomLeft {
    z-index: 2002;
}
.applied_status_detail_wrap > span:first-child {
    width: 220px;
    height: 473px;
    background: #f3f4f5;
}

.applied_status_detail_wrap > span:first-child > div {
    line-height: 41px;
    /* width: 100%; */
    padding-left: 18px;
    /* background: #f3f4f5 url('../images/bg/lnb_arrow_down.png') no-repeat 160px 18px; */
    font-size: 14px;
    color: #5f6366;
}

.applied_status_detail_wrap > span:first-child > div:hover {
    color:#3196ff;
    /* background: #f7f8f9 url('../images/bg/lnb_arrow_down.png') no-repeat 174px 17px; */
}

.applied_status_detail_wrap > span:first-child > ul .sw_status_item {
    line-height: 39px;
    background: #f3f4f5 !important;
    padding-left: 28px;
    color: #59616b;
    font-size: 12px;
    cursor: pointer;
}

.applied_status_detail_wrap > span:first-child > ul .sw_status_item:hover {
    color:#3196ff;
    /* background:#f7f8f9 url('../images/bg/lnb_arrow_down.png') no-repeat 174px 17px; */
}

.applied_status_detail_wrap > .status_data {
    border: 1px solid #e7e7e7;
}

.applied_status_detail_wrap .device_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 70px;
}

.applied_status_detail_wrap .device_name_cell:hover,
.applied_status_detail_wrap .link_cell:hover {
    color: blue;
    cursor: pointer;
}
.applied_status_popup_wrap .mis_dialog.modal .ui-dialog {

}

.applied_status_popup_wrap .ui-dialog-overlay {

}
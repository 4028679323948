.panel_status {position:relative;margin-right:35px;}
.panel_status.registered {width:25%;margin-right:43px;}
.panel_status .total_number {top:25px;left:5px;}
.panel_status .list {height:199px !important;overflow:scroll;overflow-x:hidden;background:#fff}
.panel_status .list td {line-height:26px}
.panel_status .list td.ta_r {text-align:right}
.panel_status .list_wrap th {border-right:1px solid #f0f0f0;}
.panel_status .list_wrap {border:1px solid #e7e7e7;border-radius:5px}
.panel_status .list_wrap thead {border-top:0;}
.panel_status .ta_c {text-align:center}
.panel_status .ta_r {text-align:right}

.foot_total_number {line-height:36px;background:#f9f9fb;padding:0;width:100%;}
.foot_total_number > span {display:inline-block;width:50%;float:left;}
.foot_total_number > span > span {padding-left:17px}
.foot_total_number > .total > span {padding-left:10px}

.custom_popup_message_wrap {
    width: 100%;
    height: 83px;
    text-align: left;
    display: table;
}

.custom_popup_message_wrap_text {
    display: table-cell;
    vertical-align: middle;
    font-size: 13px;
    color: #666666;
}
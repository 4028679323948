.edge_table {
    table-layout: fixed;
}

.edge_table div {
    position: relative;
}

.edge_table div .option_list {
    width: 100%;
    position: absolute;
    top: 37px;
    left: 0;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0 1px 0;
    z-index: 1;
}

.edge_table div .option_list div {
    position: relative;
}

.edge_table div .option_list div ul li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}

.edge_table .number_input {
    text-align: right;
    width: 75px;
}

.edge_table .tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

.edge_table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
    display: flex
}

.edge_table span.checkbox {
    position: absolute;
    left: 440px;
    margin: 0 0 0 25px;
    height: 27px;
}

.edge_table .button {
    opacity: 0.8;
    border: 1px solid #595A5B;
    border-radius: 14px;
    padding: 0 15px 0 15px;
}

.edge_table .tr {
    display: table-row;
}

.edge_div_ul_show {
    display: block;
}

.edge_div_ul_hide {
    display: none;
}

.edge_ul {
    overflow-y: auto;
}

.edge_ul li:hover {
    background-color: #dde0e5;
}

.edge_alert {
    height: 22px;
    width: 22px;
    margin: 0 10px 0 0;
    float: left;
    background: url('../../../../images/icon/status6.png') no-repeat 0 0;
}

.show_slave_button {
    height: 22px;
    width: 22px;
    border: 0;
    float: left;
    background: url('../../../../images/button/plus_icon_press.png') no-repeat 0 0;
    cursor: pointer;
    margin: 0 5px 0 0;
}

.show_slave_button:hover {
    height: 22px;
    width: 22px;
    border: 0;
    float: left;
    background: url('../../../../images/button/plus_icon_over.png') no-repeat 0 0;
    cursor: pointer;
    margin: 0 5px 0 0;
}

.hide_slave_button {
    height: 22px;
    width: 22px;
    border: 0;
    float: left;
    background: url('../../../../images/button/minus_icon_press.png') no-repeat 0 0;
    cursor: pointer;
    margin: 0 5px 0 0;
}

.hide_slave_button:hover {
    height: 22px;
    width: 22px;
    border: 0;
    float: left;
    background: url('../../../../images/button/minus_icon_over.png') no-repeat 0 0;
    cursor: pointer;
    margin: 0 5px 0 0;
}

.edge_name {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.edge_name:hover {
    text-decoration: underline
}

.edge_slave_name {
    margin: 0 0 0 35px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.edge_slave_name:hover {
    text-decoration: underline
}

.button_text {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    width: 85%;
}

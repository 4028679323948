.storage_space_view {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
}
.storage_space_chart {
    width: 100%;
    height: 90px;
    padding-left: 20px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 20px;
}
.storage_space_chart_title {
    width: 95%;
    margin: inherit;
}
.storage_space_chart_size {
    width: 95%;
    text-align: end;
}
.storage_space_chart_bar {
    width: 95%;
    height: 22px;
    background-color: #f6f6f6;
    position: relative;
    display: inline-flex;
    flex-direction: row;
}
.storage_space_chart_bar_volume {
    height: 22px;
}
.storage_space_list_view {
    width: 100%;
    height: calc(100% - 183px);
    margin-left: 20px;
}
.storage_space_list_table {
    width: 95%;
    height: calc(100% - 108px);
    position: relative;
}
.storage_space_list_view .storage_space_tr_group {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 40px;
    padding: 0px !important;
    height: 40px !important;
    cursor: initial !important;
}
.ReactTable .rt-tbody .storage_space_tr_group:last-child {
    border-bottom: 1px solid #f2f2f2;
}
.storage_space_list_view .storage_space_td_props {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-right: none !important;
}
.storage_space_list_view .storage_space_list_view_column {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.storage_space_total {
    width: 95%;
    height: 32px;
    background-color: #fafafa;
    display: flex;
    position: relative;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #fafafa;
    color: #818181;
    font-size: 12px;
    margin-bottom: 70px;
}
.storage_space_total_title {
    width: calc(100% - 312px);
    text-align: right;
    line-height: 28px;
}
.storage_space_total_size {
    width: 311px;
    text-align: center;
    line-height: 28px;
}

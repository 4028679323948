.content_schedule_frame_wrap .option_list{
    width: 100%;
    position: absolute;
    top: 97px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
}

.content_schedule_frame_wrap .option_list > div {
    position: relative;
}

.content_schedule_frame_wrap .option_list > div > ul > li:first-child {
    padding-top: 11px;
}
.content_schedule_frame_wrap .option_list > div > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}

.content_schedule_frame_wrap .frameBtn.on {
    background-color:
            rgba(49, 150, 255, 0.59);
}
.content_schedule_frame_wrap .frameBtn {
    cursor: pointer;
}

.content_schedule_frame_wrap .fc-toolbar {
    text-align: center;
}
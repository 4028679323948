.setting_wrap {
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    margin: 20px 24px 0 24px;
    padding: 10px 0 10px 0;
}

.setting_container > h3 {
    font-size: 16px;
    color: #686b72;
    font-weight: normal;
    margin: 0;
    padding-left: 24px;
    padding-top: 29px;
}

.setting_wrap > table {
    width: 100%;
}

.setting_wrap > table th {
    padding-left: 16px;
    line-height: 39px;
    color: #929292;
}

.setting_wrap > table td {
    padding-left: 22px;
    line-height: 39px;
    color: #616161;
}

.setting_wrap > table tr th.changed {
    color: #e55b48 !important;
}

.setting_wrap .input_number_wrap .number_up {
    top: 8px;
}

.setting_wrap .input_number_wrap .number_down {
    top: 19px;
}

.setting_container .comment {
    float: right;
    font-size: 12px;
    padding-right: 25px;
}
.setting_container .red {
    color:'#f79394';
    padding: 1px;
}

.setting_wrap .rightButton .whiteButton {
    margin-right: 10px;
}
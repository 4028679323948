.content_detail_tag_wrap .whiteButton {
    float: right;
    border: 1px solid #dde0e5;
}
.content_detail_popup_tag {
    border-top: 0;
    border-bottom-left-radius:2px;
    font-size:12px;
    overflow-x:hidden;
    overflow-y:auto;
}

.content_detail_popup_tag Button {
    border-radius: 5px;
    text-align: left;
    padding: 0 5px 0 9px;
}

.content_detail_popup_tag table {
    border-collapse: collapse;
    border-spacing: 0;
}

.content_detail_popup_tag table thead {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 43px;
    z-index:1;
    background: #eeeeef;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-left: 1px solid #d2d2d3;
    border-right: 1px solid #d2d2d3;
}

.content_detail_popup_tag table thead th {
    background: #eeeeef;
    vertical-align:middle;
    padding:7px 10px 7px 10px;
    border-top:1px solid #d2d2d3;
    border-bottom:1px solid #d2d2d3;
    color: #797979;
}

.content_detail_popup_tag table tr {
    border-bottom: 1px solid #f2f2f2;
}

.content_detail_popup_tag table tr th:first-child {
    padding-left: 17px;
    background: url(../../../images/bg/table_divider.png) no-repeat right;
}

.content_detail_popup_tag table tr th:last-child {
    border-right:0;
}

.content_detail_popup_tag table tr td {
    color:#818181;
    vertical-align: middle;
    height: 28px;
    padding: 10px 0 10px 17px;
    cursor:pointer;
}

.content_detail_popup_tag table tr td label {
    color:#818181;
    display: inline-block;
}

.content_detail_popup_tag table tr td:first-child {
    padding-left:10px;
    backgroupd: #fafafa;
}

.content_detail_popup_tag table tr:last-child td {
    border-bottom: 0 solid #f2f2f2;
}

.content_detail_popup_tag table tr:hover td {
    background:#f3f9ff;
}

.content_detail_popup_tag table tr.on td,
.content_detail_popup_tag table tr:active td {
    background:#e1eefb;
    color:#2a2a2a;
}

.content_detail_popup_tag table tr td input[type="radio"] {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
}

.content_detail_popup_tag table tr td input[type="radio"] + label {
    display:inline;
    padding-left:24px;
    color:#59616b;
    background:url('../../../images/img/radio_normal.png') no-repeat;
    padding-bottom:2px;
    cursor:pointer;
}

.content_detail_popup_tag table tr td input[type="radio"]:checked + label {
    background:url('../../../images/img/radio_checked.png') no-repeat;
}

.content_detail_popup_tag table tr:active td input[type="radio"] + label,
.content_detail_popup_tag table tr.on td input[type="radio"] + label {
    background:url('../../../images/img/radio_checked.png') no-repeat;
    cursor: pointer;
}

.content_detail_popup_tag table tr td label > span {
    display:inline-block;
    background:#eeba07;
    font-size:9px;
    color:#fff;
    border-radius:10px;
    padding:0 5px;
    margin-left:9px;
}

.content_detail_popup_tag .tag_condition_btn {
    width: 130px !important;
}

.content_detail_popup_tag .tag_condition_input {
    width: 110px !important;
}


.content_detail_popup_tag .tag_condition_option_list {
    position: relative;
    top: 5px;
    right: 62px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 10;
    width: 190px;
    overflow-y: auto;
    max-height: 150px;
}

/*
.content_detail_popup_tag .tag_condition_option_list > div {position:relative;}
.content_detail_popup_tag .tag_condition_option_list > div > span {display:block;width:100%;height:8px;position:absolute;top:-8px;background:url('../../../images/bg/option_list_bg.png') no-repeat 10px 0;}
.content_detail_popup_tag .tag_condition_option_list > div > ul {
    overflow-y: auto;
}
.content_detail_popup_tag .tag_condition_option_list > div > ul > li {
    padding:6px 5px 4px 12px;
    font-size:12px;
    position:relative;
    text-align:left;
}
.content_detail_popup_tag .tag_condition_option_list > div > ul > li > input {
    display: none;
}
.content_detail_popup_tag .tag_condition_option_list > div > ul > li > label {
    padding-left: 25px;
}

.content_detail_popup_tag .tag_condition_option_list > div > ul > li > input[type="checkbox"] + label {
    background: url(../../../images/img/checkbox_normal.png) no-repeat;
}
.content_detail_popup_tag .tag_condition_option_list > div > ul > li > input[type="checkbox"].on + label {
    background: url(../../../images/img/checkbox_checked.png) no-repeat;
}

.content_detail_popup_tag .option_list > div > ul > li > input[type="text"] {
    padding: 0 10px;
    margin: 0;
    border: 1px solid #dde0e5;
    border-radius: 5px;
    line-height: 26px;
    font-size: 12px;
    color: #686b72;
    height: 25px;
    vertical-align: middle;
}

.content_detail_popup_tag .option_list > div > ul > li > button {
    padding: 0;
    width: 35px;
    height: 27px;
    float: right;
    border: 1px solid #dde0e5;
    border-radius: 15px;
    background: #fff;
    font-size:12px;
    text-align: center;
    cursor: pointer;
}
.content_detail_popup_tag .option_list > div > ul > li > button:hover {
    cursor: pointer;
    border: solid 1px #e7eaf0;
    background: #e7eaf0;
    color: #000000;
}

.content_detail_popup_tag .option_list > div > ul > li:first-child {padding-top:11px}
.content_detail_popup_tag .option_list > div > ul > li:last-child {border-bottom:0;padding-bottom:10px}
.content_detail_popup_tag .option_list > div > ul > li.last {border-top:1px solid #DDE0E5;width:132px;border-bottom:0;padding-bottom:10px;}
.content_detail_popup_tag .option_list > div > ul > li > a {display:inline-block;color:#707070;text-decoration: none;width:100%;font-size:12px;vertical-align:middle;}
.content_detail_popup_tag .option_list > div > ul > li > a img {vertical-align:top;margin-right:8px;}
.content_detail_popup_tag .option_list > div > ul > li.on > a {color:#3196ff !important;}
.content_detail_popup_tag .option_list > div > ul > li > a:hover {color:#609be8 !important;cursor:pointer;}
.content_detail_popup_tag .option_list > div > ul > li > a:active {color:#2789f4 !important;}*/

.advertisement_list_view_wrap table {
    width: 100%;
    position: relative;
}

.advertisement_list_view_wrap table thead {
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    background: #eeeeef;
    position: relative;
}

.advertisement_list_view_wrap > table > thead > tr > th:first-child {
    background: none;
    padding: 7px 0 7px 17px;
}
.advertisement_list_view_wrap > table > thead > tr > th {
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    background: url('../../../../images/bg/table_divider.png') no-repeat right;
    position: relative;
}


.advertisement_list_view_wrap table tr td {border-bottom:1px solid #f2f2f2;vertical-align:middle;text-align:left;padding:7px 10px 7px 17px;line-height:20px;position:relative;}
.advertisement_list_view_wrap table tr td:first-child {border-right:0;padding:10px 0 10px 17px;}
.advertisement_list_view_wrap table tr:hover td {background:#eceef3;border-bottom:1px solid #d2d2d3;}
.advertisement_list_view_wrap table tr.on:hover td {background:#e6f2ff;border-bottom:1px solid #e6f2ff;color:#2a2a2a;}
.advertisement_list_view_wrap table tr td.icon img {margin-right:4px;}
.advertisement_list_view_wrap table tr.on,
.advertisement_list_view_wrap table tr:active td {background:#e6f2ff;border-bottom:1px solid #e6f2ff;color:#2a2a2a;}
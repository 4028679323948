.content_schedule_frame_popup {
    width: 730px;
    display: inline-block;
}

.content_schedule_frame_popup .device_step.step7 {
    background: url('../../../../images/img/device_pop_step_7.png') no-repeat;
}
.content_schedule_frame_popup .device_step {
    height: 59px;
}

.content_schedule_frame_popup .device_step > div {
    float: left;
}

.content_schedule_frame_popup .device_step > div > .step {
    line-height: 58px;
    font-size: 26px;
    padding-left: 22px;
}
.content_schedule_frame_popup .device_step > div > span {
    display: block;
    float: left;
    color: #b4cbd7;
}

.content_schedule_frame_popup .device_step > .on > span {
    color: #5d5d5d;
}

.content_schedule_frame_popup .device_step > div > .step_title.txt1 {
    padding-top: 20px;
}
.content_schedule_frame_popup .device_step > div > .step_title {
    line-height: 17px;
    font-size: 14px;
    padding-left: 13px;
}

.content_schedule_frame_popup .selected_frame_edit_wrap {
    text-align: center;
    display: inline-block;
}

.content_schedule_frame_popup .selected_frame_edit_wrap > .mainFrame > div {
    text-align: center;
    white-space: nowrap;
}
.content_schedule_frame_popup .frameBtn {
    cursor: pointer;
}

.content_schedule_frame_popup .detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}
.content_schedule_frame_popup .detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.content_schedule_frame_popup .button_wrap {
    padding: 15px 0;
    margin: 0 55px;
    text-align: right;
    border-width: 1px 0 0 0;
}

.content_schedule_frame_popup .button_wrap > button {
    margin-right: 10px;
}

.content_schedule_frame_popup .edit_frame {
    height: 410px;
    overflow-y: auto;
    margin-bottom: 12px;
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
}

.content_schedule_frame_popup .edit_frame ul li {
    float: left;
    position: relative;
    margin: 0 8px 8px 0;
}

.content_schedule_frame_popup .edit_frame ul li.on {
    background-color: rgba(49, 150, 255, 0.59);
}

.content_schedule_frame_popup .circle_btn.more > span {
    background: url('../../../../images/icon/list_more_normal.png') no-repeat 5px 5px;
    display: inline-block;
    height: 28px;
    width: 28px;
}

.content_schedule_frame_popup .selected_frame_edit_wrap > .mainFrame > div > div {
    text-align: center;
    position: absolute;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.content_schedule_frame_popup .selected_frame_edit_wrap > .mainFrame > div > div.on {
    background-color: rgba(49, 150, 255, 0.59);
}

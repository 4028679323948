.container {
    min-width: 1024px;
    min-height: 768px;
    position: absolute;
    height: 100%;
    width: 100%;
}

.recovery_title {
    font-size: 15px;
    margin: 0 50px;
    padding: 0 30px;
    line-height: 30px;
    border: 1px solid;
    background-color: #202425;
    color: #fff;
    width: 90%;
}

.recovery_item {
    font-size: 15px;
    margin: 15px 50px;
    padding: 30px;
    line-height: 33px;
    border: 1px solid;
    border-radius: 7px !important;
    /*background-color: #3963AD;
    color: #fff;*/
    width: 90%;
    height: 145px;
    overflow-y: auto;

    background-color: #F7F7F7;
    color: #000;
}

.recovery_item .database_table {
    margin-bottom: 30px;
    width: 100%;
    font-weight: bold;
}
.recovery_item .database_table > table > tr > th {
    color: #818181 !important;
    vertical-align: middle;
    height: 15px;
    padding: 3px 5px 3px 17px;
    cursor: pointer;
    line-height: 15px;
    border: 1px solid #C0C0C0;
    background-color: #E5E5E5;
}
.recovery_item .database_table > table > tr > td {
    color:#C0C0C0 !important;
    vertical-align: middle;
    height: 15px;
    padding: 3px 3px 3px 17px;
    cursor: pointer;
    line-height: 13px;
    border: 1px solid;
}
.recovery_item .recovery_table_wrapper {
    float: left;
    width: 500px;
    margin-right: 30px;
}
.recovery_item h1 {
    margin: 5px 0;
}
.recovery_table_container {
    margin-top: 10px;
}
.recovery_item .normal {
    color: #41AC04;
}
.recovery_item .error {
    color: #F2140F;
}
.show_layout {
    background: #dee1e5;
    width: 929px;
    height: 511px;
    border-radius: 5px;
    text-align: -webkit-center;
}
.show_layout > div {
    background: #9da0a9;
    border-radius: 3px;
}
.show_layout > div table {
    margin-top: 13px;
}
.show_layout > div table th {
    color: #d4d5d9;
    font-size: 13px;
    line-height: 22px;
    padding-left: 20px;
}
.show_layout > div table td {
    color: #ffffff;
    font-size: 13px;
    line-height: 22px;
    padding-left: 20px;
}

.show_layout .signage_layout_item_wrap {
    background-color: #5B5B5B;
}

.show_layout .signage_layout_item_frame {
    border: 1px solid #fff;
    background: #262626;
}

.show_layout .signage_layout_item {
    background: #5B5B5B;
}

.show_layout .vwl_layout_item_wrap {
    position: relative;
    background-color: #262626;
    border: 1px solid #fff;
}

.show_layout .vwl_layout_item {
    text-align: left;
    color: #FFF;
    background-color: #5B5B5B;
}

.show_layout .vwl_layout_item .vwl_layout_img_capture{
    background-repeat: no-repeat;
    background-position:center;
    background-size: contain;
    display:inline-block;
}

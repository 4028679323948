.daily-connected-device {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
}

.daily-connected-device-chart {
    width: 100%;
    height: 150px;
}

.daily-connected-device-guide {
    margin-top:5px;
    display: flex;
    margin-bottom: 3px;
    justify-content: flex-end;
    padding-top:10px;
}

.daily-connected-device-guide-color-index {
    width:6px;
    height: 6px;
    border-radius: 3px;
    margin-top: 5px;
}

.daily-connected-device-guide-title-index {
    margin-left: 4px;
    font-size: 10px;
    color: #909090;
    letter-spacing: 0;
    width: 45px;
}

.daily-connected-device-guide-ago {
    position: absolute;
    left: 25px;
    font-size: smaller;
}

.daily-connected-device-guide-current {
    position: absolute;
    right: 0;
    font-size: smaller;
}

.daily-connected-device-label {
    width: 100%;
    height: 23px;
    margin-left: 25px;
    display: inline-flex;
    flex-direction: row;
}

.daily-connected-device-label-time {
    width: 27px;
    font-size: 10px;
}
.error_report_list_wrap .device_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 70px;
}

.health_description_cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}
.select_input {
    display: inline-block;
    position: relative;
}

.select_input .base.un_radius {
    border-radius: 5px;
}
.select_input .base.un_radius img {
    padding-top: 2px;
}

.select_input .base.icon img {
    padding-top: 4px;
    vertical-align: top;
    margin-right: 8px;
}

/* select box button */
.select_input .base.select {
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
    width: 100%;
    /* min-width: 450px; */
}
.select_input .base.select span {
    width: 95%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.select_input .base.select span > .arrow {
    display: inline-block;
    /* float: right; */
    width: 10px;
    height: 20px;
    background: url('../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
    position: absolute;
    top: 4px;
    right: 5px;
}

.select_input .base.select:hover span > .arrow {
    display: inline-block;
    background: url('../../images/button/select_down.png') no-repeat 0 10px;
}
.select_input .base.select:disabled {
    box-shadow: #d1d2d4 0 0 0 inset;
}

.select_input .base.select:disabled span > .arrow {
    background: url('../../images/button/select_down_dim.png') no-repeat 0 10px;
}

.select_input .base.select.on span > .arrow {
    background: url('../../images/button/select_up.png') no-repeat 0 10px;
}

.select_input .base.select:hover {
    color: #848484; /*border-color:#b4b9c3;background:#ffffff;*/
}

.select_input .base.select.on {
    color: #848484;
    border-color: #dde0e5; /*background:#ffffff;*/
}

.select_input .base.select:active {
    color: #848484;
    border-color: #e7e7e7; /*background:#e7e7e7;*/
}

.select_input .base.select:disabled {
    color: #c1c1c1;
    border-color: #f3f3f3;
    background: #f3f3f3;
    cursor: initial;
}

.select_input .base {
    height: 28px;
    border: 1px solid #dde0e5;
}

.select_input .option_list {
    width: 100%;
    max-height: 225px;
    display: inline-block;
    position: absolute;
    /*top: 37px;*/
    left: 0;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.select_input .option_list .extend {
    background: url('../../images/button/btn_next.png') no-repeat;
    background-position: top 4px right 4px;
}
.select_input .sub_option_list {
    display: none;
    left: 150px;
}
.select_input .sub_options_list:hover {
    display: block;
}
/* .select_input div.devicepop_wrap .option_list {
    max-height: 200px;
    overflow-x: auto;
    overflow-y: auto;
} */
.select_input div#quick_control .option_list {
    max-height: 190px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.select_input .option_list > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}
.select_input .option_list > ul > li:first-child {
    padding-top: 11px;
}
.select_input .option_list > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}
.select_input .option_list > ul > li.last {
    border-top: 1px solid #dde0e5;
    width: 132px;
    border-bottom: 0;
    padding-bottom: 10px;
}
.select_input .option_list > ul > li {
    /* display: inline-block; */
    color: #707070;
    text-decoration: none;
    width: 100%;
    font-size: 12px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.select_input .option_list > ul > li > img {
    vertical-align: top;
    margin-right: 8px;
}
.select_input .option_list > ul > li.on > {
    color: #3196ff !important;
}
.select_input .option_list > ul > li:hover {
    color: #609be8 !important;
    cursor: pointer;
}
.select_input .option_list > ul > li:active {
    color: #2789f4 !important;
}
.select_input .option_list > ul > li.sb_menu {
    background: url('../../images/button/date_select_next.png') no-repeat 95% 4px;
}
.select_input .option_list > ul > li.sb_menu:hover {
    background: url('../../images/button/date_select_next_on.png') no-repeat 95% 4px;
}
.select_input .option_list > ul > li.sb_menu.on a {
    background: url('../../images/button/date_select_next_on.png') no-repeat 95% 4px;
}
.select_input .option_list > ul > li .delete {
    float: right;
    border: 0;
    width: 19px;
    height: 19px;
    background: url('../../images/icon/dashboard_delete_normal.png');
    margin-top: -1px;
}
.select_input .option_list > ul > li .delete:hover {
    background: url('../../images/icon/dashboard_delete_hover.png');
}
.select_input .option_list > ul > li .delete:active {
    background: url('../../images/icon/dashboard_delete_select.png');
}
.select_input .option_list > ul > li > .sb_option_list {
    position: absolute;
    top: -4px;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
    background: #fff;
    padding: 5px 0;
}
.select_input .option_list > ul > li > .sb_option_list li {
    padding: 6px 5px 5px 13px;
}

.select_input .option_list.checklist > ul > li {
    padding: 4px 5px 4px 8px;
}
.select_input .option_list.checklist > ul > li:first-child {
    padding: 10px 5px 4px 8px;
}
.select_input .option_list.checklist > ul > li:last-child {
    padding: 4px 5px 9px 8px;
}
.select_input .option_list.checklist > ul > li label {
    width: 100%;
}
.select_input .option_list.checklist > ul > li label img {
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -1px;
}

.select_input .option_list.icon_list > ul > li {
    padding: 4px 5px 5px 8px;
}
.select_input .option_list.icon_list > ul > li:first-child {
    padding: 10px 5px 4px 8px;
}
.select_input .option_list.icon_list > ul > li:last-child {
    padding: 4px 5px 11px 8px;
}
.select_input .option_list.icon_list > ul > li.line {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 12px;
}

.select_input .option_list.ta_c > ul > li {
    padding: 6px 5px 5px 5px;
    text-align: center;
}
.select_input .option_list.ft11 > ul > li {
    font-size: 11px;
}

.select_input .option_list .check_on label {
    width: 100%;
    background: url('../../images/button/option_list_check_uncheck.png') no-repeat 93% 4px;
    padding-left: 0;
}
.select_input .option_list .check_on label:hover {
    background: url('../../images/button/option_list_check_uncheck.png') no-repeat 93% 4px;
}
.select_input .option_list .check_on input[type='checkbox']:checked + label {
    background: url('../../images/button/option_list_check_ckecked.png') no-repeat 93% 4px;
}
.select_input .errorTxt {
    width: 100%; color: #f79394; line-height: 13px;
}
.select_input .base.error {border-color: #f79394 !important;}

.select_input .text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}


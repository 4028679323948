
.cm_area {
    margin-top: 30px;
}

.cm_area .area_title {
    font-weight: bold;
    font-size: 13px;
    color: #363636;
    letter-spacing: 0;
}

.cm_area .icon {
    display: block;
    margin-top:-5px;
}

.cm_area .icon .content {
    background-image: url('../../../images/img/gnb.png');
    background-position: -282px 0px;
    width: 30px;
    height: 28px;
}

.cm_area .icon .playlist {
    background-image: url('../../../images/img/gnb.png');
    background-position: -282px -82px;
    width: 30px;
    height: 28px;
}

.cm_area .icon .ruleset {
    background-image: url('../../../images/img/gnb.png');
    background-position: -282px -776px;
    width: 30px;
    height: 28px;
}

.cm_area .icon .schedule {
    background-image: url('../../../images/img/gnb.png');
    background-position: -282px -164px;
    width: 30px;
    height: 28px;
}
.cm_area .resource_wrap .icon {
    margin-top: -10px;
    width: 100px;
    height: 20px;
}
.cm_area .resource_wrap .icon > div{
    font-size: 13px;
    color: #363636;
    line-height: 65px;
}

.cm_area .resource_wrap .resource {
    background-color: #FFFFFF;
    border: 1px solid #BDC2C8;
    border-radius: 6px;
    height: 52px;
    display: flex;
    flex: 1;
    margin: 5px;
}

.cm_area .resource_wrap .resource .running {
    font-size: 36px;
    color: #363636;
    text-align: right;
    line-height: 50px;
}

.cm_area .resource_wrap .resource .total {
    padding-right: 20px;
}
  .ruleset_play_detail_view {
    position: relative;
  }
  .ruleset_play_detail_view.t_line {
    border-top: 1px solid #f4f4f4;
  }
  .ruleset_play_detail_view table {
    width: 700px;
  }
  .ruleset_play_detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-left: 10px;
  }
  .ruleset_play_detail_view.user_info table th {
    font-size: 13px;
  }
  .ruleset_play_detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
  }
  .ruleset_play_detail_view table tr.bullet_line td {
    padding: 2px 0 1px 0;
    color: #474747;
  }
  .ruleset_play_detail_view table tr.va_t th {
    vertical-align: top;
    padding-top: 11px;
  }
  .ruleset_play_detail_view table td label {
    color: #474747;
    display: inline-block;
  }
  .ruleset_play_detail_view table td.icon img {
    vertical-align: middle;
    margin-right: 4px;
  }
  .ruleset_play_detail_view table th img {
    margin-left: 6px;
    vertical-align: middle;
  }
  
  .ruleset_play_detail_view table th h4 {
    margin: 0;
    font-size: 16px;
    color: #676767;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .ruleset_play_detail_view .space {
    display: block;
  }
  .ruleset_play_detail_view .speed_controler {
    margin-top: 0;
  }
  .ruleset_play_detail_view .speed_controler > span {
    width: 273px;
  }
  .ruleset_play_detail_view .speed_controler > span > .txt {
    font-size: 10px;
    top: 20px;
    background: none;
    color: #b3b4b5;
  }
  .ruleset_play_detail_view.tline {
    border-top: 1px solid #f5f5f5;
  }
  .ruleset_play_detail_view table td .txt {
    color: #8d8d8d;
    font-size: 12px;
    display: block;
    line-height: 17px;
  }
  
  .ruleset_setting {
    width: 100%;
  }
  
  .holiday_add {
    width: 19px;
    height: 19px;
    background: url("./images/dashboard_add_normal.png");
    margin-left: 10px;
    border: 0;
    position: relative;
    top: 5px;
  }
  .holiday_add:hover {
    background: url("./images/dashboard_add_hover.png");
  }
  .holiday_add:active {
    background: url("./images/dashboard_add_select.png");
  }
  
  .replash2 {
    display: block;
    border: 0;
    width: 25px;
    height: 25px;
    background: url("./images/reflash_normal2.png");
  }
  
  .tag_input {
    position: relative;
    display: inline-block;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid #d6dee8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 1px;
  }
  .tag_input > input[type="text"] {
    line-height: 28px;
    background: #eff2f6; /* width:90%; */
    padding: 0 !important;
    border: 0 !important;
  }
  .tag_input > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url("./images/dashboard_delete_normal.png") no-repeat;
    width: 19px;
    height: 19px;
  }
  .tag_input > button:hover {
    background: url("./images/dashboard_delete_hover.png") no-repeat;
  }
  .tag_input > button:active {
    background: url("./images/dashboard_delete_select.png") no-repeat;
  }
  
  .tag_input > span > input[type="text"] {
    line-height: 28px;
    background: #eff2f6;
    width: 90%;
    padding: 0 !important;
    border: 0 !important;
  }
  .tag_input > span > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url("./images/dashboard_delete_normal.png") no-repeat;
    width: 19px;
    height: 19px;
  }
  .tag_input > span > button:hover {
    background: url("./images/dashboard_delete_hover.png") no-repeat;
  }
  .tag_input > span > button:active {
    background: url("./images/dashboard_delete_select.png") no-repeat;
  }
  
  #repeatTypeWrap {
    width: 224px;
    height: 160px;
    border: 1px solid #b4b9c3;
    background-color: #ffffff;
  }
  
  #repeatTypeWrap div {
    width: 30px;
    height: 27px;
    float: left;
    text-align: center;
    border: 1px solid #fcfcfc;
  }
  
  .repeatTypeMonCal:hover {
    background-color: #d9e0e7;
  }
  .repeatTypeMonCal.on {
    background-color: rgba(49, 150, 255, 0.59);
  }
  
  .ruleset_play_selected_item_box {
    height: 150px;
    width: 530px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    margin: 5px 0;
    overflow-y: auto;
    background-color: #ffffff;
  }
  .ruleset_play_selected_item_box table th,
  .ruleset_play_selected_item_box table td {
    padding: 2px 0 !important; 
    white-space: pre-line !important;    
  }
  .ruleset_play_selected_item_box table th > span,
  .ruleset_play_selected_item_box table td > span {
    padding-left: 4px;
  }
  /* .pop_list {
    border-left: 1px solid #dad4d4;
    border-right: 1px solid #dad4d4;
    border-bottom: 1px solid #dad4d4;
    border-top: 1px solid #dad4d4;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: hidden;
    overflow-y: auto;
  } */

  .selectedDeviceDelBtn  {height: 19px;width: 19px;background: url('./images/tab_close_normal.png') no-repeat 0 0;border: 0;padding: 0; margin: 0;}
  .selectedDeviceDelBtn:hover {height: 19px;width: 19px;background: url('./images/tab_close_hover.png') no-repeat 0 0;border: 0;padding: 0; margin: 0;}
  .selectedDeviceDelBtn:active {height: 19px;width: 19px;background: url('./images/tab_close_select.png') no-repeat 0 0;border: 0;padding: 0; margin: 0;}
  
  .base.searchBtn{
      width:30px;
      height:30px;
      background: #fff url('./images/search_select.png') no-repeat 8px 7px !important;
  }

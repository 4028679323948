.tabWrap {
    height: 75px;
    background: #e6ebf0;
    position: relative;
}
.lnb_control {
    position: absolute;
    right: 11px;
    top: 38px;
    width: 38px;
    height: 38px;
    background: none;
    border: 0;
    background: url('../images/img/sub_close.png') no-repeat 0 0;
    padding: 0;
}

.lnb_control:hover {
    background: url('../images/img/sub_close_hover.png') no-repeat 0 0;
}

.lnb_control.on:hover {
    background: url('../images/img/lnb_control_arrow_on_hover.png') no-repeat 0 0;
}
.lnb_control.on {
    background: url('../images/img/lnb_control_arrow_on_normal.png') no-repeat 0 0;
}

.menu_title {
    width: 253px;
    height: 75px;
    background: #555c6b;
    position: relative;
    float: left !important;
    background: rgb(230, 235, 240) none repeat scroll 0% 0%;
}

.menu_title.insightTitle {
    width: 253px!important;
}

.menu_title.hasSubMenu {
    background: rgb(51, 60, 78) none repeat scroll 0% 0%
}
.menu_title > h1 {
    font-size: 20px;
    color: rgb(87, 88, 89);
    float: left !important;
    padding-top: 38px !important;
    padding-left: 22px !important;
    font-weight: normal;
}
.menu_title.hasSubMenu > h1 {
    color: #f4f6f8;
}
.tabArea {
    position: relative;
    float: left !important;
}
.tabMenu {
    margin-top: 33px;
    float: left;
}
.tabMenu > ul > li {
    position: relative;
    height: 42px;
    float: left;
    line-height: 46px;
    padding-left: 35px;
}

.tabMenu > ul > li > .divider {
    width: 1px;
    height: 17px;
    margin-top: 13px;
    margin-left: -35px;
    background-color: #a0a2a6;
    float: left;
}

.tabMenu > ul > li.on > .divider {background-color:#fff;}
.tabMenu > ul > li.on:hover > .divider {background-color:#fff;}
.tabMenu > ul > li:hover > .divider {background-color:#e3e3e3;}

.tabMenu > ul > li > a > span {position:absolute;top:10px;right:10px;display:inline-block;width:19px;height:19px;/*background:url('..../images/img/tab_close_normal.png') no-repeat;*/}
.tabMenu > ul > li:hover > a > span {background:url('../images/img/tab_close_hover.png') no-repeat;cursor:pointer;}
.tabMenu > ul > li.on > a > span {background:url('../images/img/tab_close_select.png') no-repeat;}



.tabMenu > ul > li.on {background:#fff;color:#3196ff;}


.tabMenu > ul > li:first-child {
    margin-left: 20px;
}
.tabMenu > ul > li.on {
    color: #3196ff;
}

.tabMenu > ul > li.on.DASHBOARD {
    background: #fff url('../images/icon/tab_icon_content_selected.png') no-repeat 15px center;
}
.tabMenu > ul > li.DASHBOARD {
    background: #e7eaf0 url('../images/icon/tab_icon_dash.png') no-repeat 15px center;
    color: #363636;
}
.tabMenu > ul > li.DASHBOARD:hover {background:#e3e3e3 url('../images/icon/tab_icon_content_over.png') no-repeat 15px center;color:#00000;}

.tabMenu > ul > .CONTENT {background:#e7eaf0 url('../images/icon/tab_icon_content.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .CONTENT:hover {background:#e3e3e3 url('../images/icon/tab_icon_content_over.png') no-repeat 15px center;color:#00000;}
.tabMenu > ul > .CONTENT.on {background:#fff url('../images/icon/tab_icon_content_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .PLAYLIST {background:#e7eaf0 url('../images/icon/tab_icon_play.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .PLAYLIST:hover {background:#e3e3e3 url('../images/icon/tab_icon_playlist_over.png') no-repeat 15px center;color:#00000;}
.tabMenu > ul > .PLAYLIST.on {background:#fff url('../images/icon/tab_icon_playlist_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .NEW_PLAYLIST, .tabMenu > ul > .EDIT_PLAYLIST {background:#e7eaf0 url('../images/icon/tab_icon_play.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .NEW_PLAYLIST:hover, .tabMenu > ul > .EDIT_PLAYLIST:hover {background:#e3e3e3 url('../images/icon/tab_icon_playlist_over.png') no-repeat 15px center;color:#00000;}
.tabMenu > ul > .NEW_PLAYLIST.on, .tabMenu > ul > .EDIT_PLAYLIST.on {background:#fff url('../images/icon/tab_icon_playlist_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .SCHEDULE {background:#e7eaf0 url('../images/icon/tab_icon_schedule.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .SCHEDULE:hover {background:#e3e3e3 url('../images/icon/tab_icon_schedule_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .SCHEDULE.on {background:#fff url('../images/icon/tab_icon_schedule_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .DEVICE {background:#e7eaf0 url('../images/icon/tab_icon_device.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .DEVICE:hover {background:#e3e3e3 url('../images/icon/tab_icon_device_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .DEVICE.on {background:#fff url('../images/icon/tab_icon_device_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .LED_CABINET {background:#e7eaf0 url('../images/icon/tab_icon_device.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .LED_CABINET:hover {background:#e3e3e3 url('../images/icon/tab_icon_device_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .LED_CABINET.on {background:#fff url('../images/icon/tab_icon_device_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > #childDeviceTab {background:#e7eaf0 url('../images/icon/tab_icon_device.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > #childDeviceTab:hover {background:#e3e3e3 url('../images/icon/tab_icon_device_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > #childDeviceTab.on {background:#fff url('../images/icon/tab_icon_device_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .STATISTICS {background:#e7eaf0 url('../images/icon/tab_icon_statistics.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .STATISTICS:hover {background:#e3e3e3 url('../images/icon/tab_icon_statistics_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .STATISTICS.on {background:#fff url('../images/icon/tab_icon_statistics_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .USER {background:#e7eaf0 url('../images/icon/tab_icon_user.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .USER:hover {background:#e3e3e3 url('../images/icon/tab_icon_user_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .USER.on {background:#fff url('../images/icon/tab_icon_user_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .SETTING {background:#e7eaf0 url('../images/icon/tab_icon_edit.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .SETTING:hover {background:#e3e3e3 url('../images/icon/tab_icon_edit_on.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .SETTING.on {background:#fff url('../images/icon/tab_icon_edit_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .RULESET,
.tabMenu > ul > .EDIT_RULESET,
.tabMenu > ul > .NEW_RULESET
{
    background:#e7eaf0 url('../images/icon/tab_icon_ruleset.png') no-repeat 15px center;color:#363636
}
.tabMenu > ul > .RULESET:hover {background:#e3e3e3 url('../images/icon/tab_icon_ruleset_on.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .RULESET.on {background:#fff url('../images/icon/tab_icon_ruleset_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .RULE_MANAGER {background:#e7eaf0 url('../images/icon/tab_icon_analytics.png') no-repeat 15px center;color:#363636}
.tabMenu > ul > .RULE_MANAGER:hover {background:#e3e3e3 url('../images/icon/tab_icon_analytics_over.png') no-repeat 15px center;color:#000000;}
.tabMenu > ul > .RULE_MANAGER.on {background:#fff url('../images/icon/tab_icon_analytics_selected.png') no-repeat 15px center;color:#3196ff;}

.tabMenu > ul > .EDIT_SCHEDULE,
.tabMenu > ul > .NEW_SCHEDULE,
.tabMenu > ul > .NEW_ADVERTISEMENT,
.tabMenu > ul > .EDIT_ADVERTISEMENT,
.tabMenu > ul > .NEW_MESSAGE,
.tabMenu > ul > .EDIT_MESSAGE,
.tabMenu > ul > .EDIT_EVENT,
.tabMenu > ul > .NEW_EVENT,
.tabMenu > ul > .NEW_EVENT_CONDITION,
.tabMenu > ul > .EDIT_EVENT_CONDITION
{
    background:#e7eaf0 url('../images/icon/tab_icon_schedule.png') no-repeat 15px center;color:#363636
}

.tabMenu > ul > .EDIT_SCHEDULE.on, .tabMenu > ul > .EDIT_SCHEDULE.on:hover,
.tabMenu > ul > .NEW_SCHEDULE.on, .tabMenu > ul > .NEW_SCHEDULE.on:hover,
.tabMenu > ul > .NEW_ADVERTISEMENT.on, .tabMenu > ul > .NEW_ADVERTISEMENT.on:hover,
.tabMenu > ul > .EDIT_ADVERTISEMENT.on, .tabMenu > ul > .EDIT_ADVERTISEMENT.on:hover,
.tabMenu > ul > .NEW_MESSAGE.on, .tabMenu > ul > .NEW_MESSAGE.on:hover,
.tabMenu > ul > .EDIT_MESSAGE.on, .tabMenu > ul > .EDIT_MESSAGE.on:hover,
.tabMenu > ul > .EDIT_EVENT.on, .tabMenu > ul > .EDIT_EVENT.on:hover,
.tabMenu > ul > .NEW_EVENT.on, .tabMenu > ul > .NEW_EVENT.on:hover,
.tabMenu > ul > .NEW_EVENT_CONDITION.on, .tabMenu > ul > .NEW_EVENT_CONDITION.on:hover,
.tabMenu > ul > .EDIT_EVENT_CONDITION.on, .tabMenu > ul > .EDIT_EVENT_CONDITION.on:hover
{
    background:#fff url('../images/icon/tab_icon_schedule_selected.png') no-repeat 15px center;color:#3196ff;
}

.tabMenu > ul > .EDIT_SCHEDULE:hover,
.tabMenu > ul > .NEW_SCHEDULE:hover,
.tabMenu > ul > .NEW_ADVERTISEMENT:hover,
.tabMenu > ul > .EDIT_ADVERTISEMENT:hover,
.tabMenu > ul > .NEW_MESSAGE:hover,
.tabMenu > ul > .EDIT_MESSAGE:hover,
.tabMenu > ul > .EDIT_EVENT:hover,
.tabMenu > ul > .NEW_EVENT:hover,
.tabMenu > ul > .NEW_EVENT_CONDITION:hover,
.tabMenu > ul > .EDIT_EVENT_CONDITION:hover
{
    background:#e3e3e3 url('../images/icon/tab_icon_schedule_over.png') no-repeat 15px center;color:#000000;
}

.tabMenu > ul > .EDIT_RULESET.on, .tabMenu > ul > .EDIT_RULESET.on:hover,
.tabMenu > ul > .NEW_RULESET.on, .tabMenu > ul > .NEW_RULESET.on:hover
{
    background:#fff url('../images/icon/tab_icon_ruleset_selected.png') no-repeat 15px center;color:#3196ff;
}

.tabMenu > ul > .EDIT_RULESET:hover,
.tabMenu > ul > .NEW_RULESET:hover
{
    background:#e3e3e3 url('../images/icon/tab_icon_ruleset_over.png') no-repeat 15px center;color:#000000;
}

.tabMenu > ul > li > .tabName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    margin-top: -2px;
    font-size: 12px;
}

/*login wrap*/
.tabWrap .loginWrap {
    position: absolute;
    right: 5px;
    top: 40px;
}

.tabWrap .loginWrap #user_login_id {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
}

.language_wrap #selectLanguageBtn {
    background: url('../images/icon/icon_earth.png') no-repeat;
    width: 16px;
    height: 16px;
    border: 0px;
}

#tutorialBtn, .white_list_question, #mngGroupTutorialBtn {
    border: 0px;
    background: url('../images/button/ic_tutorial.svg') no-repeat;
    width: 28px;
    height: 28px;
    cursor: pointer;
}
#tutorialBtn:hover, .white_list_question:hover {
    background: url('../images/button/ic_tutorial_over.svg') no-repeat;
}

#selectMngOrgBtn span {
    display:inline-block;
    width:17px;
    height:17px;
    background:url('../images/button/select_down.png') no-repeat 5px 10px;
}

.mngOrg_select_box .option_list {width:100%;position:absolute;top:37px;left:0;background:#fff;border:1px solid #dde0e5;box-shadow:#dedede 0px 1px 0px;z-index:1}
.mngOrg_select_box .option_list > div {position:relative;}
.mngOrg_select_box .option_list > div > span {display:block;width:100%;height:8px;position:absolute;top:-8px;background:url('../images/bg/option_list_bg.png') no-repeat 10px 0;}
.mngOrg_select_box .option_list > div > ul > li {padding:6px 5px 4px 13px;font-size:12px;position:relative;line-height:15px;text-align:left}
.mngOrg_select_box .option_list > div > ul > li:first-child {padding-top:11px}
.mngOrg_select_box .option_list > div > ul > li:last-child {border-bottom:0;padding-bottom:10px}
.mngOrg_select_box .option_list > div > ul > li.last {border-top:1px solid #DDE0E5;width:132px;border-bottom:0;padding-bottom:10px;}
.mngOrg_select_box .option_list > div > ul > li > a {display:inline-block;color:#707070;text-decoration: none;width:100%;font-size:12px;vertical-align:middle;}
.mngOrg_select_box .option_list > div > ul > li > a img {vertical-align:top;margin-right:8px;}
.mngOrg_select_box .option_list > div > ul > li.on > a {color:#3196ff !important;}
.mngOrg_select_box .option_list > div > ul > li > a:hover {color:#609be8 !important;cursor:pointer;}
.mngOrg_select_box .option_list > div > ul > li > a:active {color:#2789f4 !important;}
.mngOrg_select_box .option_list > div > ul > li > .sb_option_list {position:absolute;top:-4px;border:1px solid #dde0e5;box-shadow:#dedede 0px 1px 0px;z-index:1;background:#fff;padding:5px 0;}
.mngOrg_select_box .option_list > div > ul > li > .sb_option_list li {padding:6px 5px 5px 13px;}
.mngOrg_select_box .base.select.on {color:#848484;border-color:#dde0e5;/*background:#ffffff;*/}
.mngOrg_select_box .base.select {color:#363636;border-color:#dde0e5;/*background:#ffffff;*/}
.mngOrg_select_box .select_box > button {border: 1px solid #dde0e5}

#btn_sign_out {background-color: #E6EBF0;}
#btn_sign_out:hover {border-color: #E6EBF0}

.tag_condition_input {
    width: 100px;
    padding: 0 9px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #363636;
}


.tag_condition_input:disabled {
    background: #f3f3f3;
    border-color: #f3f3f3;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.tag_condition_btn span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url('../../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
}

.tag_condition_btn {
    border: 1px solid #dde0e5;
    border-radius: 5px;
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
    width: 102px;
    height: 27px;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
}

.tag_condition_btn:hover {
    border-color: #b4b9c3;
    background: #e7eaf0;
    color: #848484;
    cursor: pointer;
}

.tag_condition_btn:disabled {
    color: #c1c1c1;
    border-color: #f3f3f3;
    background: #f3f3f3;
}

.tag_condition_option_list {
    /*width: 100%;*/
    /*height: 100px;*/
    /*max-width: 100px;*/
    position: absolute;
    top: 50px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
    width: 200px;
    display: block;
    overflow-y: auto;
    max-height: 150px;
}

.tag_condition_option_list.playlist{
    top: 87px!important;
}

.tag_condition_option_list > ul > li {
    padding:6px 5px 4px 12px;
    font-size:12px;
    position:relative;
    text-align:left;
    display: flex;
    line-height: 16px;
}

.tag_condition_option_list > ul > li > input {
    display: none;
}
.tag_condition_option_list > ul > li > label {
    padding-left: 25px;
}

.tag_condition_option_list > ul > li > input[type="checkbox"] + label {
    background: url(../../../images/img/checkbox_normal.png) no-repeat;
    cursor: pointer;
}
.tag_condition_option_list > ul > li > input[type="checkbox"].on + label {
    background: url(../../../images/img/checkbox_checked.png) no-repeat !important;
}

.tag_condition_option_list > ul > li > input[type="radio"] + label {
    display:inline;
    padding-left:24px;
    color:#59616b;
    background:url('../../../images/img/radio_normal.png') no-repeat;
    padding-bottom:2px;
    cursor:pointer;
}

.tag_condition_option_list > ul > li > input[type="radio"]:checked + label {
    background: url('../../../images/img/radio_checked.png') no-repeat;
}

.tag_condition_option_list > ul > li:first-child {padding-top:11px}
.tag_condition_option_list> ul > li:last-child {border-bottom:0;padding-bottom:10px}
.tag_condition_option_list> ul > li.last {border-top:1px solid #DDE0E5;width:132px;border-bottom:0;padding-bottom:10px;}
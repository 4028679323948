.content_schedule_progress_popup .detail_view table {
    width: 100%;
}

.content_schedule_progress_popup .detail_view table th {
    vertical-align: middle;
    color:
            #8d8d8d;
    padding-right: 10px;
}

.content_schedule_progress_popup .detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.content_schedule_progress_popup .popup_btn_wrap.noline {
    border-top: 0px;
}
.content_schedule_progress_popup .popup_btn_wrap {
    border-top: 1px solid #f5f5f5;
    margin: 0 25px;
    padding: 20px 0;
    text-align: center;
}

.content_schedule_progress_popup .detail_btn {
    border: 0;
    background: none;
    color: #8d8d8d;
    line-height: 28px;
    padding: 0;
    text-align: left;
}

.content_schedule_progress_popup .detail_btn.on > span {
    background: url('../../../../images/button/select_up.png') no-repeat;
}

.content_schedule_progress_popup .detail_btn.on > span {
    background: url('../../../../images/button/select_up.png') no-repeat;
}

.content_schedule_progress_popup .pop_list {
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
    fonts-size: 13px;
    overflow-x: hidden;
    overflow-y: auto;
}

.content_schedule_progress_popup .pop_list.content_upload th:first-child {
    padding-left: 16px;
}
.content_schedule_progress_popup .pop_list table tr th:first-child {
    padding: 7px 0 7px 11px;
    padding-left: 11px;
}
.content_schedule_progress_popup .pop_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url(../../../../images/bg/table_divider.png) no-repeat right #eeeeef;
}

.content_schedule_progress_popup .pop_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url(../../../../images/bg/table_divider.png) no-repeat right #eeeeef;
}

.content_schedule_progress_popup .pop_list.content_upload td {
    padding: 6px 10px 6px 10px;
}

.content_schedule_progress_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.content_schedule_progress_popup caption, th, td {
    text-align: left;
    vertical-align: top;
    font-weight: normal;
    white-space: nowrap;
}

.content_schedule_progress_popup .pop_list.content_upload td:first-child {
    padding: 6px 10px 6px 16px;
}
.content_schedule_progress_popup .pop_list table tr:last-child td {
    border-bottom: 1px solid #f2f2f2;
}

.content_schedule_progress_popup .pop_list.content_upload td {
    padding: 6px 10px 6px 10px;
}

.content_schedule_progress_popup .pop_list table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 9px 10px 9px 10px;
    line-height: 20px;
    position: relative;
    color: #797979;
}

.content_schedule_progress_popup .uploading .total_status_txt {
    position: absolute;
    top: -33px;
    color: #666666;
}


.content_schedule_progress_popup .popup_contents table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.content_schedule_progress_popup .popup_contents table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.content_schedule_progress_popup .uploading .file_total {
    position: absolute;
    right: 5px;
    top: -25px;
    font-size: 12px;
    color: #848484;
}

.content_schedule_progress_popup .detail_btn > span {
    width: 7px;
    height: 5px;
    display: inline-block;
    background: url('../../../../images/button/select_down.png') no-repeat;
    vertical-align: middle;
    margin-right: 9px;
}

.content_schedule_progress_popup .pop_list table tr td .bul {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    margin-right: 4px;
    border-radius: 25px;
    vertical-align: top;
}

.content_schedule_progress_popup .content_schedule_progress_device_table .device_name .status.on {
    background: #46d5b5;
}
.content_schedule_progress_popup .content_schedule_progress_device_table .device_name .status {
    background: #d6d6d6;
}


.content_schedule_progress_popup .uploading {
    position: relative;
    background: #e8e8e8;
    width: 100%;
    height: 19px;
    margin-top: 49px;
}
.content_multi_edit_popup .detail_wrap {
    width: 500px;
    position: relative;
}

.content_multi_edit_popup .detail_wrap .pop_list_2{
    font-size: 13px;
    height:320px;
    width:490px;
    padding:10px;
}

.content_multi_edit_popup .category_wrap {
    border: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
}

.content_multi_edit_popup .tag_wrap {
    height:400px !important;
}

.content_multi_edit_popup .expiration_date_wrap {
    border: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
}

.content_multi_edit_popup .expiration_date_wrap .title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
    white-space: pre-wrap;
}

.content_multi_edit_popup .expiration_date_wrap .expiration_date {
    width: 118px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.content_multi_edit_popup .detail_wrap .t_line{
    border-top:1px solid #f4f4f4;
}
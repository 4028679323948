.connection_status {margin-right:35px;width:222px;}
.connection_status > div {border:0 !important;width:100%;}
.connection_status ul li {background:#f0f0f0;font-size:12px;color:#717a89;font-weight:bold;padding:20px 20px 0 20px;line-height:16px;height:73px;}
.connection_status ul li > span {display:block;font-size:28px;color:#c6c6c7;font-weight:normal;padding-top:10px;}
.connection_status ul li.bgc_f3cf29 {color:#fbeeb2}
.connection_status ul li.bgc_f3cf29 span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_f7e076 {color:#fdf9e4}
.connection_status ul li.bgc_f7e076 span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_a6bddb {color:#eff3ff}
.connection_status ul li.bgc_a6bddb span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_2b8cbe {color:#fdf9e4}
.connection_status ul li.bgc_2b8cbe span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_fa9fb5 {color:#eff3ff}
.connection_status ul li.bgc_fa9fb5 span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_c51b8a {color:#fdf9e4}
.connection_status ul li.bgc_c51b8a span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_c2e699 {color:#818181}
.connection_status ul li.bgc_c2e699 span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_78c679 {color:#818181}
.connection_status ul li.bgc_78c679 span {padding-top:10px;color:#fff}
.connection_status ul li.bgc_bcbddc {color:#717a89}
.connection_status ul li.bgc_bcbddc span {padding-top:10px;color:#717a89}
.connection_status ul li.bgc_756bb1 {color:#fdf9e4}
.connection_status ul li.bgc_756bb1 span {padding-top:10px;color:#fff}
.connection_status ul li.connection_status_item {color:#fff}
.connection_status ul li.connection_status_item span {padding-top:10px;color:#fff}


.upload_streaming_content_popup {
    padding: 0 25px;
    position: relative;
}

.upload_streaming_content_popup .warring{
    display:inline-block;
    font-size:11px;
    height:40px;
    line-height:20px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width:500px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space:pre-wrap;
}

.upload_streaming_content_popup .device_icon {
    display: flex;
}

.upload_streaming_content_popup .no_padding {
    padding: 0 0 0 0;
}

.upload_streaming_content_popup .device_icon > img{
    margin-right: 4px;
}

.upload_streaming_content_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.upload_streaming_content_popup table th{
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.upload_streaming_content_popup table td{
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

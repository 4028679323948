.backup_play_wrap .device_step {
    height: 59px;
}
.backup_play_wrap .device_step.step1 {
    background: url('../../../images/img/device_pop_step_1.png') no-repeat center;
    background-size: cover;
}
.backup_play_wrap .device_step.step2 {
    background: url('../../../images/img/device_pop_step_2.png') no-repeat center;
    background-size: cover;
}
.backup_play_wrap .device_step.step3 {
    background: url('../../../images/img/device_pop_step_3.png') no-repeat center;
    background-size: cover;
}
.backup_play_wrap .device_step.step4 {
    background: url('../../../images/img/device_pop_step_4.png') no-repeat center;
    background-size: cover;
}
.backup_play_wrap .device_step > span {
    display: inline-block;
    color: #333232;
    font-size: 14px;
    line-height: 59px;
    padding-left: 25px;
    background: url('../../../images/img/device_pop_step_check.png') no-repeat 0 24px;
}
.backup_play_wrap .device_step > div {
    float: left;
}
.backup_play_wrap .device_step > div > span {
    display: block;
    float: left;
    color: #b4cbd7;
}
.backup_play_wrap .device_step > .on > span {
    color: #5d5d5d;
}
.backup_play_wrap .device_step > div > .step {
    line-height: 58px;
    font-size: 26px;
    padding-left: 22px;
}
.backup_play_wrap .device_step > div > .step_title {
    line-height: 17px;
    font-size: 14px;
    padding-left: 13px;
}
.backup_play_wrap .device_step > div > .step_title.txt1 {
    padding-top: 20px;
}
.backup_play_wrap .device_step > div > .step_title.txt2 {
    padding-top: 12px;
}

.backup_play_wrap .warning {
    display: inline-block;
    background: url('../../../images/icon/bullet_1.png') no-repeat 0 3px;
    padding-left: 17px;
    color: #a9a9a9;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

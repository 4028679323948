.vwl_device_replace_wrap input[type='radio'] {
    width: 0;
    height: 0;
    margin: 0;
    display: none; /*position:absolute;top:-9999px;*/
}

.vwl_device_replace_wrap input[type='radio'] + label {
    display: inline;
    padding-left: 24px;
    color: #59616b;
    background: url('../../../images/img/radio_normal.png') no-repeat;
    padding-bottom: 2px;
    cursor: pointer;
}

.vwl_device_replace_wrap input[type='radio']:checked + label {
    background: url('../../../images/img/radio_checked.png') no-repeat;
}

.vwl_device_replace_wrap input[type='radio']:disabled + label {
    cursor: default;
    filter: grayscale(100%);
}

.vwl_device_replace_wrap input[type='radio']:checked:disabled + label {
    background: url('../../../images/img/radio_checked_disabled.png') no-repeat;
}

.language_wrap {
    height: 24px;
    margin-left:5px;
}

.language_wrap .option_list {
    min-width: 110px;
    top: 70px;
    right: 5px;
    right: 5px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 2;
    position:fixed;

}

.language_wrap .option_list > div > span {
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background: url('../images/bg/option_list_bg.png') no-repeat 10px 0;
}

.language_wrap .option_list.checklist > div > ul > li:first-child {
    padding: 10px 5px 4px 8px;
}
.language_wrap .option_list.checklist > div > ul > li {
    padding: 4px 5px 4px 8px;
}
.language_wrap .option_list.checklist > div > ul > li:last-child {
    padding: 4px 5px 9px 8px;
}
.language_wrap .option_list > div > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}
.language_wrap .option_list > div > ul > li > a:hover {
    color: #609be8 !important;
    cursor: pointer;
}
.language_wrap .option_list > div > ul > li > a {
    display: inline-block;
    color: #707070;
    text-decoration: none;
    width: 100%;
    font-size: 12px;
    vertical-align: middle;
}

.language_wrap .option_list > div > ul > li > a.active {
    color: rgb(96, 155, 232);
}
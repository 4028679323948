
.select_multiple_content_dialog {
    height: auto;
    width: auto;
    top: -150px;
    left: 0px;
    display: block;
    position: absolute;
    padding: .2em;
    outline: 0;
    background-color: #fff;
}

.schedule_content_wrap .space {
    font-size: 11px;
    color:
            #727b8a;
}
.select_multiple_content_dialog .dialog_body_wrap {
    width: auto;
    min-height: 70px;
    max-height: none;
    height: auto;
}

.select_multiple_content_dialog .sub_tabwrap {
    width: 100%;
    height: 38px;
    position: relative;
    overflow: hidden;
    background: url('/image/bg/datetab_bg2.png') repeat-x bottom;
    margin-bottom: 20px;
}
.sub_tabwrap > ul {
    margin-left: 7px;
    position: absolute;
    top: 0;
    left: 0px;

}

.sub_tabwrap > ul > li {
    float: left;
    line-height: 17px;
    text-align: center;
}

.sub_tabwrap > ul > li > a {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    width: auto;
    height: 28px;
    letter-spacing: -1px;
    font-size: 13px;
    display: inline-block;
}

.sub_tabwrap > ul > li.on > a {
    color: #3196ff;
    background: url('/image/bg/datetab_bg_selected.png') no-repeat center bottom 1px;
    background-size: auto;
    background-size: 80% 2px;
}


.select_multiple_content_dialog .lnb {
    width: 180px;
    float: left;
    background: #f3f4f5 !important;
    height: 417px;
}

.select_multiple_content_dialog .lnb > ul > li {
    line-height: 41px;
    width: 100%;
}

.select_multiple_content_dialog .lnb > ul > li > a {
    box-sizing: border-box;
    padding-left: 28px;
    font-size: 14px;
    color: #5f6366;
    background: none;
    width: 100%;
}

.select_multiple_content_dialog .lnb > ul > li.on > a {
    background: #f3f4f5 url('/images/bg/lnb_arrow_up.png') no-repeat 160px 17px;
    color: #000000;
}

.select_multiple_content_dialog .lnb > ul > li.on:hover > a {
    background: #f7f8f9 url('/image/bg/lnb_arrow_up.png') no-repeat 160px 17px;
    color: #000000;
}

.select_multiple_content_dialog .lnb .insight_ul > li.on > a {
    color: #3196ff !important;
}

.schedule_content_wrap {
    width: 460px;
    height: 415px;
    float: left;
    border: 1px solid #e7e7e7;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: relative;
}

.select_multiple_content_dialog table {
    border-collapse: collapse;
    border-spacing: 0;
}
.select_multiple_content_dialog table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 0px 0px 5px 5px;
    line-height: 20px;
    position: relative;
    color: #59616b !important;
}
.select_multiple_content_dialog table tr:hover td {background:#f3f9ff;border-bottom:1px solid #f3f9ff;}
.select_multiple_content_dialog table tr.on td {background-color:#e6f2ff;}


.select_multiple_content_dialog table tr td.thum .thumb_wrap {
    width: 85px;
    height: 50px;
    text-align: center;
}
.select_multiple_content_dialog table tr td h4 {
    font-size: 11px;
    margin: 0 0 0 0;
    color: #818181;
}
.ml20 {
    margin-left: 20px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mt10 {
    margin-top: 10px !important;
}

.select_multiple_content_dialog .dialog_bottom_wrap {
    float:right;
    border-color: #f5f5f5;
    padding: 15px 0;
    margin: 0 25px;
}
.select_multiple_content_dialog .dialog_bottom_wrap button{
    margin-left: 15px
}

.select_multiple_content_dialog .schedule_content_wrap .pop_list table tr td li {
    font-size: 11px;
    line-height: 13px;
    padding: 0px 0px 0px 0px;
    display:flex;
    justify-content: space-between;
}
.select_multiple_content_dialog .schedule_content_wrap .pop_list table tr td li .info_button {
    width: 22px;
    height: 22px;
    background: url('../../../../images/icon/info_normal.png.png') no-repeat right bottom;
    margin-top: 4px;
    margin-right: 20px;
}

.select_multiple_content_dialog .input_source_wrap ul li:hover a {
    background:
            #fbfbfc;
}
.select_multiple_content_dialog .input_source_wrap ul li.more_list a {
    padding-left: 23px;
    background: #f7f7f8;
}
.select_multiple_content_dialog .input_source_wrap ul li a {
    display: inline-block;
    width: 100%;
    line-height: 39px;
    padding-left: 13px;
    color:
            #595b5f;
}

.select_multiple_content_dialog .input_source_wrap ul li a > span {
    width: 23px;
    display: inline-block;
    background: url('/image/icon/input_source.png') no-repeat;
    vertical-align: middle;
    margin-right: 12px;

}

.select_multiple_content_dialog .input_source_wrap ul li.more a {background:#f7f7f8 url('/image/bg/lnb_arrow_down.png') no-repeat 85% 17px;}
.select_multiple_content_dialog .input_source_wrap ul li.more.on a {background:#f7f7f8 url('/image/bg/lnb_arrow_up.png') no-repeat 85% 17px;color:#000;}
.select_multiple_content_dialog .input_source_wrap ul li.on a {background:#fbfbfc;color:#3196ff;}
.select_multiple_content_dialog .input_source_wrap ul li:hover a {background:#fbfbfc}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_1 {height:15px;background-position:0 0}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_2 {height:14px;background-position:0 -38px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_3 {height:14px;background-position:0 -77px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_4 {height:14px;background-position:0 -116px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_5 {height:14px;background-position:0 -155px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_6 {height:14px;background-position:0 -194px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_7 {height:14px;background-position:0 -233px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_8 {height:14px;background-position:0 -272px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_9 {height:7px;background-position:0 -316px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_10 {height:12px;background-position:0 -385px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_11 {height:15px;background-position:0 -422px }
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_12 {height:14px;background-position:0 -461px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_13 {height:15px;background-position:0 -500px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_14 {height:21px;background-position:0 -536px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_15 {height:18px;background-position:0 -577px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_16 {height:17px;background-position:0 -615px}
.select_multiple_content_dialog .input_source_wrap ul li a > .input_icon_17 {height:17px;background-position:0 -652px}
.select_multiple_content_dialog .input_source_wrap ul li a > .more_btn {height:3px;background-position:0 -356px}

.select_multiple_content_dialog .input_source_wrap ul li:hover a {
    background: #fbfbfc;
}
.select_multiple_content_dialog .input_source_wrap ul li.on a {
    background: #fbfbfc;
    color: #3196ff;
}


.select_multiple_content_dialog #inputSourceWrapIconLists {
    display: table;
    width: 100%;
}

.select_multiple_content_dialog .input_source_wrap {
    width: 202px;
    float: left;
    background:
            #f3f4f5 !important;
    height: 417px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.select_multiple_content_dialog .schedule_content_wrap {
    width: 500px;
    height: 415px;
    float: left;
    border: 1px solid #e7e7e7;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: relative;
}

.select_multiple_content_dialog .list_wrap_empty.noline {
    border-top: 0;
}
.select_multiple_content_dialog .list_wrap_empty {
    width: 100%;
    text-align: center;
}
.select_multiple_content_dialog .list_wrap_empty .empty_title {
    font-size: 26px;
    color:
            #d9e0e7;
    display: block;
    margin-bottom: 17px;
}

.select_multiple_content_dialog .list_wrap_empty > div > span {
    font-size: 15px;
    color: #cbcbcb;
    line-height: 20px;
}

.select_multiple_content_dialog .input_source_txt {
    color: #999999;
    text-align: center;
    border-top: 1px solid
    #f2f2f2;
    line-height: 18px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 24px 0 25px 0;
}


.selected_content_wrap {width:300px;height:415px;float:left;border:1px solid #e7e7e7;border-left:0;border-top-right-radius:3px;border-bottom-right-radius:3px;position:relative;overflow:hidden;}
.selected_content_wrap.data_list {width:725px;}
.selected_content_wrap .space {font-size:11px;color:#727b8a;}
.selected_content_wrap .pop_list {border:0;border-top:1px solid #e7e7e7;}
.selected_content_wrap .pop_list table tr td {padding:0px 0px 5px 5px;}
.selected_content_wrap .pop_list.swPublishHistory table tr td {padding: 7px 10px 7px 10px;}
.selected_content_wrap .pop_list table tr td h4 {font-size:11px;margin:0 0 0 0;color:#818181;}
.selected_content_wrap .pop_list table tr.on td h4 {color:#000000 !important;}
.selected_content_wrap .pop_list table tr td li {font-size:11px;line-height:13px;padding:0px 0px 0px 0px;}
.selected_content_wrap .pop_list table tr td li.Playtime > span {display:inline-block;width:170px;background:url('../images/bar_4.png') no-repeat right 2px;margin-right:7px;}
.selected_content_wrap .pop_list table tr td li.playlist > span {display:inline-block;width:136px;background:url('../images/bar_4.png') no-repeat right 2px;margin-right:7px;}
.selected_content_wrap .pop_list table tr td.thum .thumb_wrap>div{padding-top:7px;}
.selected_content_wrap .pop_list table tr td.thum .thumb_wrap {width: 85px;height:50px;text-align:center;}
.selected_content_wrap .pop_list table thead {border:0;}
.selected_content_wrap .pop_list table tr th {border-top:0;}

.selected_content_wrap .thumbnailview_wrap {padding-left:0;width:100%;overflow-y:auto;}
.selected_content_wrap .thumbnailview_wrap ul {margin-bottom:30px;overflow:hidden;}
.selected_content_wrap .thumbnailview_wrap li {float:left;margin:0 8px 8px 0;}
.selected_content_wrap .thumbnailview_wrap .thumbview_box {width:75px;height:42px;position:relative;text-align:center;line-height:42px;vertical-align:middle;}
.selected_content_wrap .thumbnailview_wrap li span.sync {background-image: url('../images/status9.png');background-repeat: no-repeat; position:absolute;width:17px;height:17px;z-index:1;}
/* .selected_content_wrap .thumbnailview_wrap .thumbview_box > img {width:75px;height:42px;} */
.selected_content_wrap .thumbnailview_wrap .device_info {width:75px;height:42px;}
.selected_content_wrap .thumbnailview_wrap .device_info > span {width:70px;height:42px;display:block;vertical-align:middle;color:#000;font-size:9px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.selected_content_wrap .thumbnailview_wrap .check {width:100%;height:100%;}
.selected_content_wrap .thumbnailview_wrap .check {display:none;position:absolute;top:0;left:0;width:75px;height:42px;;background:url('../images/thumb_layer_bg.png') repeat;}
.selected_content_wrap .thumbnailview_wrap .check span {width:55px;height:39px;display:inline-block;background:url('../images/thumb_check2.png') no-repeat;margin:8px 0 0 20px;background-size:40px;}
.selected_content_wrap .thumbnailview_wrap .thumbview_box.on .check {display:block}
.selected_content_wrap .thumbnailview_wrap .thumbview_box .play {position:absolute;top:78px;left:77px;width:30px;height:32px;border:0;background:url('../images/play.png') no-repeat;z-index: 1}


.select_multiple_content_dialog .thumbnailView_wrap {
    padding-left: 0;
    width: 100%;
}

.select_multiple_content_dialog .thumbnailView_wrap ul {
    margin-bottom: 30px;
    overflow: hidden;
}

.select_multiple_content_dialog .thumbnailView_wrap li {
    float: left;
    margin: 0 8px 8px 0;
}

.select_multiple_content_dialog .thumbnailView_wrap .thumbview_box {
    width: 75px;
    height: 42px;
    position: relative;
    text-align: center;
    line-height: 42px;
    vertical-align: middle;
}

.select_multiple_content_dialog .thumbnailView_wrap .device_info {
    width: 75px;
    height: 42px;
}

.select_multiple_content_dialog .device_info, .device_tag_info {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../../images/bg/thumb_layer_bg.png') repeat;
    color: #000;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    z-index: 100;
}

.select_multiple_content_dialog .thumbnailView_wrap .check {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
    height: 42px;
    background: url('../../../../images/bg/thumb_layer_bg.png') repeat;
}

.select_multiple_content_dialog .thumbnailView_wrap .device_info > span {
    width: 70px;
    height: 42px;
    display: block;
    vertical-align: middle;
    color: #000;
    font-size: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select_multiple_content_dialog .thumbnailView_wrap .check span {
    width: 55px;
    height: 39px;
    display: inline-block;
    background: url('../../../../images/img/thumb_check2.png') no-repeat;
    background-size: auto;
    margin: 8px 0 0 20px;
    background-size: 40px;
}

.select_multiple_content_dialog .thumbnailView_wrap .thumbview_box.on .check {
    display: block;
}


.pop_list .statisticsDelete {border:0;width:19px;float:right;height:19px;background:url('../images/dashboard_delete_normal.png') no-repeat;}
.pop_list .statisticsDelete:hover {background:url('../images/dashboard_delete_hover.png')}
.pop_list .statisticsDelete:active {background:url('../images/dashboard_delete_select.png')}


@import '../../../components/fullcalendar/core/main.css';
@import '../../../components/fullcalendar/daygrid/main.css';
@import '../../../components/fullcalendar/timegrid/main.css';
@import '../../../components/fullcalendar/list/main.css';

.content_schedule_manager_warp {
    padding-left: 20px;
}

.fc-today-button.fc-button.fc-button-primary {
    width: 70px;
    color: #848484;
    background: #fff;
    border: 1px solid #dde0e5;
    line-height: 14px;
    border-radius: 15px;
}
.fc-toolbar h2 {
    font-size: 13px;
    font-weight: bold;
    line-height: 28px;
}


.fc button {
    height: 28px;
    border: 1px solid #dde0e5;
    color: #848484;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    border-radius: 15px;
    text-align: center;
}

.fc .fc-left > button {
    float: left;
}
.fc .fc-left > div {
    float: left;
}
.fc .fc-left > div > h2{
    float: left;
}

.content_schedule_manager_warp .fc .fc-center {
    margin-left: -200px;
}

.schedule_manager_buttonsWrap > button {
    margin-left: 10px;
}

.schedule_manager_iconWrap > div {
    margin-right: 10px;
    margin-bottom: 25px;    
    clear:right;
    display:flex;
    float:right;
}

.schedule_manager_iconWrap > div > span {
    margin-right: 10px;
}

.schedule_manager_iconWrap > div > span.name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.schedule_manager_iconWrap > div > div {
    margin-right: 10px;
}
.content_schedule_manager_warp .fc-left {
    display: flex;
}
.content_schedule_manager_warp .fc-button-group > .fc-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 13px;
}
.fc-button-primary:focus {
    box-shadow: none;
}
.fc-button:focus {
    outline: none;
    box-shadow: none;
}
.content_schedule_manager_warp .fc-button-group > .fc-button.fc-button-active:focus {
    border: none;
    background: none;
    box-shadow: none;
}

.content_schedule_manager_warp .fc-button-group > .fc-button.fc-button-active {
    color: #000;
}
.content_schedule_manager_warp .fc-header-toolbar .fc-right > div {
    margin-right: 20px;
}
.content_schedule_manager_warp .fc-header-toolbar .fc-right > div > div{
    float: left;
}
.content_schedule_manager_warp .fc-header-toolbar .fc-right > div > div {
    margin-left: 15px;
}
.content_schedule_manager_warp .fc-header-toolbar .fc-right > div > div:first-child {
    margin:0px;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
    color: #000;
}

.fc-button .fc-icon {
    margin-top: -10px;
}
.fc-today-button.fc-button.fc-button-primary {
    padding-top: -15px;
}
.fc-toolbar button:hover {
    background-color: none;
}

.contentSelectSearchBtn {
    border: 0px;
    width: 22px;
    height: 22px;
    background: #fff url('../../../images/icon/search_select.png') no-repeat 5px 7px !important;
    margin-top: 2px;
}

.input_date > button {
    position: absolute;
    top: -3px;
    right: 3px;
    width: 22px;
    height: 22px;
    background: url('../../../images/icon/calander_icon_normal.png');
    border: 0;
}

.fc-highlight {
    background: #3a87ad;
    opacity: 0.7;
}

.fc-event {
    border: 0;
    color: #fff !important;
}

.content_schedule_manager_warp .fc-widget-header {
    border: 0;
    height: 40px;
    line-height: 40px;
}

.content_schedule_manager_warp .fc-day-header {
    font-size: 13px;
    font-weight: bold;
}

.content_schedule_manager_warp .fc-day-header.fc-today span {
    background: #00ddca;
    color: #fff;
}

.fc button:hover {
    background: #e7eaf0;
    border: 1px solid #dde0e5;
}

.content_schedule_manager_warp .react-datepicker.moveCalendar, .advertisement_schedule_wrap .react-datepicker.moveCalendar{
    position: absolute;
    background-color: white;
    z-index: 9;
    top: 100px;
    left: 350px;
}

.advertisement_schedule_wrap .react-datepicker.moveCalendar
{
    left: 300px;
}


.fc-event.expired .thumbnail img {
    opacity: 0.3;
}
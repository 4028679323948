.plus_button {
    width: 19px;
    height: 19px;
    background: url('../../images/icon/dashboard_add_normal.png');
    margin-left: 10px;
    border: 0;
    position: relative;
    top: 5px;
    cursor: pointer;
}

.plus_button:hover {
    background: url('../../images/icon/dashboard_add_hover.png');
}

.plus_button:active {
    background: url('../../images/icon/dashboard_add_select.png');
}

.repeat_type_monthly div {
    width:30px;
    height:27px;
    float:left;
    text-align:center;
    border: 1px solid #fcfcfc;
    cursor: pointer;
}

.repeat_type_monthly div:hover{
    background-color: #d9e0e7;
}
.repeat_type_monthly div.on{
    background-color: rgba(49, 150, 255, 0.59);
}

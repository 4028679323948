.setting_sub_table {
    width: 700px;
    border: 1px solid #e7e7e7;
}

.setting_sub_table th, .setting_sub_table td {
    color: #59616b !important;
}

.setting_sub_table th {
    color: #59616b !important;
    background-color: #e7e7e7;
}

.setting_log_filter_button {
    margin-left: 10px;
    float: left;
}

.setting_log_filter_button > button{
    border: 1px solid #5e5e5e !important;
    color: #363636 !important;
    background: #fff !important;
    min-width: 76px;
}

.setting_log_filter_button > button > span{
    width: 100%;
}
.setting_log_filter_button > button > span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url(../../images/button/select_down.png) no-repeat 0 10px;
    margin-left: 10px;
}

.setting_log_filter_button > button:hover {
    cursor: pointer !important;
    border: solid 1px #e7eaf0 !important;
    background: #e7eaf0 !important;
    color: #000000 !important;
}

.setting_log_filter_button > button:disabled {
    cursor: inherit !important;
    border-color: #c2c2c2 !important;
    color: #d0d0d0 !important;
    background: #fff !important;
}

.setting_log_filter_button ul {
    width: 76px !important;
}

.period {
    width: 50px;
    padding-left: 50px;
    line-height: 28px;
}

.service_history .ReactTable .rt-tbody .rt-td:first-child,
.service_history .ReactTable .rt-thead .rt-th:first-child , 
.service_history .ReactTable .rt-thead .rt-td:first-child {
    padding-left:15px;
}
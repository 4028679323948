.dim_wrap {
    z-index: 999;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
}

.popup_wrap {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 3px;
    background: rgba(0,0,0,0.15);
    padding-bottom: 2px;
    border-width: 0.1em;
    border-style: solid;
    border-color: #cfcfcf;
    position: absolute;
    box-shadow: 0 10px 13px
    #888888;
}

.popup_wrap.type1 > div {width:978px;}
.popup_wrap.type2 > div {width:682px;}
.popup_wrap.type3 > div {width:457px;}
.popup_wrap.type4 > div {width:391px;}
.popup_wrap.type5 > div {width:470px;}
.popup_wrap.type6 > div {width:1200px;}
.popup_wrap.type7 > div {width:610px;}
.popup_wrap.type8 > div {width:592px;}
.popup_wrap.schedulePopup > div {width:682px;}

.popup_header {
    position: relative;
    border-bottom: 1px solid
    #ebebeb;
    line-height: 56px;
}

.popup_header h1 {
    margin: 0;
    font-size: 18px;
    color: #797979;
    padding-left: 25px;
    text-overflow: ellipsis;
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    font-weight: normal;
}

.popup_header > div {
    position: absolute;
    top: 9px;
    right: 13px;
}

.pop_circle {
    width: 27px;
    height: 27px;
    border: 0;
    padding: 0;
    border-radius: 15px;
    cursor: pointer;
}

.pop_circle .close:hover {
    background: url('../../images/button/popup_btn_circle_close_hover.png');
}

.pop_circle > span {
    display: inline-block !important;
    width: 27px;
    height: 27px;
}

.pop_circle .close {
    background: url('../../images/button/popup_btn_circle_close_normal.png');
    cursor: default;
}

.popup_wrap > div {background:#fff;border-radius:2px;}



.group_context_menu{
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999999;
    width: 130px;
    z-index: 99999;
}

.group_context_menu div.normal > a {
    display: block;
    padding: 0 2em 0 2em;
    text-decoration: none;
    width: auto;
    color: black;
    white-space: nowrap;
    line-height: 2.4em;
    text-shadow: 1px 1px 0
    white;
    border-radius: 1px;
}

.group_context_menu div > a > i:empty {
    width: 2.4em;
    line-height: 2.4em;
}
.group_context_menu div > a > i {
    text-decoration: none;
    display: inline-block;
    width: 2.4em;
    height: 2.4em;
    background:
            transparent;
    margin: 0 0 0 -2em;
    vertical-align: top;
    text-align: center;
    line-height: 2.4em;
}

.group_context_menu div > a:hover {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1;
}

.group_context_menu div.disabled a, .group_context_menu div.disabled a:hover {
    color: silver;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0;
}

.group_context_menu .group_code a {
    line-height: 2.4em;
    display: block;
}

.group_context_menu .group_code .group_info {
    margin: 0px;
    background: url('../../images/icon/info_normal.png.png') 3px 3px no-repeat !important;
}

.group_context_menu .context_separator {
    line-height: 2px;
}

.group_context_menu .context_separator > a {
    display: block;
    background: white;
    border: 0;
    border-top: 1px solid #e2e3e3;
    height: 1px;
    line-height: 2px;
    min-height: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0 0 0 2.4em;
    border-left: 1px solid #e0e0e0;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0;
}
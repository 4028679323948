.pagination_wrap {
    border-top: 1px solid #d6d7d8;
    padding: 10px 0;
    position: fixed;
    height: 30px;
    background-color: #ECECEC;
    bottom: 0px;
    background: #fff;
    width: 100%;
    text-align: center;
}

.pagination_wrap .pagination_info,.pagination_size {
    margin: 10px;
    float: left;
}

.pagination_wrap .pagination_pages {
    display: inline-block;
    padding-left: 0;
    margin: 5px 0;
    border-radius: 4px;
    margin-left: -500px;
}

.pagination_wrap .pagination_pages > li {
    position: relative;
    float: left;
    padding: 2px 6px;
    line-height: 1.42857143;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #d6d7d8;
    margin-left: -1px;
    cursor: pointer;
}

.pagination_wrap .pagination_pages > li.active {
    z-index: 2;
    color: #ffffff;
    background-color: #3196ff;
    border: 1px solid #d6d7d8;
    cursor: default;
}
.curr_time .plus_btn {
    float:left;
    margin-top: 2px;
    width:27px;
    height:27px;
    border:0px;
    background:url('../images/folder_plus.png') no-repeat 2px 1px;
    position:static;
}

li.time_range .delete {
    border: 0;
    width: 19px;
    height: 19px;
    background: url('../images/dashboard_delete_normal.png') no-repeat;
    vertical-align: middle;
}

li.time_range .delete:hover {
    background:url('../images/dashboard_delete_hover.png')
}
.user-summary {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: row;
    background-image: url(../../../../images/icon/img_usersummary.svg);
    background-repeat: no-repeat;
    background-position: 0px 30px;
    justify-content: center;
}

.user-summary-all {
    width: 33%;
    height: 125px;
    background: #F3E7F2;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
    color: #363636;
    font-size: 34px;
    line-height: 66px;
    text-align: center;
    padding-top: 10px;
}

.user-summary-all > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-summary-drawn {
    width: 34%;
    height: 125px;
    font-size: 34px;
    background: #e8cae3;
    cursor: pointer;
    color: #363636;
    line-height: 66px;
    text-align: center;
    padding-top: 10px;
}

.user-summary-drawn > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-summary-request {
    width: 33%;
    height: 125px;
    font-size: 34px;
    background: #d79bca;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    line-height: 66px;
    text-align: center;
    padding-top: 10px;
}

.user-summary-request > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-summary .count {
    height: 65px;
    font-size: 60px;
    color: #000000;
    text-align: center;
}

.user-summary .title {
    height: 26px;
    font-size: 13px;
    color: #000000;
    text-align: center;
}
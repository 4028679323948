.advertisement_tab {

}

.advertisement_tab .items_wrap {
    border: solid 1px #dde0e5;
    height: 200px;
    position: relative;
    display: block;
}

.advertisement_tab .items_wrap .item_row {
    display: flex;
    height: 20px;
    line-height: 20px;
    padding: 10px;
    border-bottom: 1px solid #dde0e5;
}

.advertisement_tab .items_wrap .item_row .name {
    width: 90%;
    line-height: 20px;
}

.advertisement_tab .items_wrap .item_row .icon {
    line-height: 20px;
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;

}

.advertisement_tab .items_wrap .item_row .icon-close {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../../../images/button/popup_btn_circle_close_normal.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    cursor: pointer;
}

.advertisement_tab .items_wrap .item_row .icon-close:hover {
    background-image: url(../../../images/button/popup_btn_circle_close_hover.png);
}

.advertisement_tab .selected_items_container {
    position: absolute;
    margin-top: 28px;
    overflow: auto;
    height: 90px;
    width: 190px;
    background-color: #fff;
    z-index: 1;
    border: 1px solid #dde0e5;
}

.advertisement_tab .selected_items_container .selected_item {
    height: 25px;
    line-height: 25px;
    padding: 5px;
    cursor: pointer;
}
.advertisement_tab .selected_items_container .selected_item:hover {
    color: #0a6aa1;
}

.advertisement_tab table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.advertisement_tab table thead {
    z-index:1;
    background: #eeeeef;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-left: 1px solid #d2d2d3;
    border-right: 1px solid #d2d2d3;
    display: table;
}

.advertisement_tab table thead th {
    background: #eeeeef;
    vertical-align:middle;
    padding:7px 10px 7px 10px;
    border-top:1px solid #d2d2d3;
    border-bottom:1px solid #d2d2d3;
    color: #797979;
}

.advertisement_tab table tr {
    border-bottom: 1px solid #f2f2f2;
}

.advertisement_tab table tr th:first-child {
    padding-left: 17px;
    background: url(../../../images/bg/table_divider.png) no-repeat right;
}

.advertisement_tab table tr th:last-child {
    border-right:0;
}

.advertisement_tab table tr td {
    color:#818181;
    vertical-align: middle;
    height: 28px;
    padding: 10px 0 10px 17px;
}

.advertisement_tab table tr td label {
    color:#818181;
    display: inline-block;
}

.advertisement_tab table tr td:first-child {
    padding-left:10px;
    backgroupd: #fafafa;
}

.advertisement_tab table tr:last-child td {
    border-bottom: 0 solid #f2f2f2;
}

.advertisement_tab table tr:hover td {
    background:#f3f9ff;
}

.advertisement_tab table tr.on td,
.advertisement_tab table tr:active td {
    background:#e1eefb;
    color:#2a2a2a;
}
.advertisement_tab table tbody {
    display: block;
    height: 175px;
    width: 420px;
    overflow-y: scroll;
    overflow-x: scroll;
}

.content_schedule_associate_tags .tag_associated_title {
    line-height: 37px;
    font-size: 13px;
    color: #676767;
    padding-left: 6px;
}

.content_schedule_associate_tags .tag_associated .float_l {
    padding-left: 10px;
    width: 141px;
    height: 448px;
    background: #f9fafb;
    border: 1px solid
    #e5e5e5;
    border-radius: 3px;
    text-align: center;
}

.content_schedule_associate_tags .associatedTagWrap {
    width: 130px;
    background: #eff2f6;
    font-size: 12px;
    max-width: 140px;
    text-align: center;
    line-height: 30px;
    height: 30px;
    margin-top: 3px;
}

.content_schedule_associate_tags .tag_associated .float_r {
    width: 540px;
    height: 440px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    overflow-x: hidden;
    overflow-y: auto;
}

.content_schedule_associate_tags .tag_associated table th {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background: #f9fafb;
    text-align: center;
}

.content_schedule_associate_tags .tag_associated table td li span {
    display: block;
    font-size: 12px;
    color: #8d8d8d;
    width: 100%;
    text-align: center;
    line-height: 20px;
}

.content_schedule_associate_tags .tag_associated table td > div > span {
    line-height: 16px;
    font-size: 11px;
    color: #fff;
    font-weight: normal;
    display: inline-block;
    background: #28cda8;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 10px;
}

.content_schedule_associate_tags .tag_associated table td > div {
    font-size: 12px;
    color:
            #585858;
    font-weight: bold;
    line-height: 50px;
}

.content_schedule_associate_tags .tag_associated table td {
    border-bottom: 1px solid
    #e5e5e5;
    padding: 0 0 0 22px;
}

.content_schedule_associate_tags .popup_contents table {
    width: 100%;
}
.content_schedule_associate_tags table {
    border-collapse: collapse;
    border-spacing: 0;
}

.content_schedule_associate_tags .tag_associated table ul li > div {
    width: 70px;
    height: 39px;
    line-height: 39px;
    text-align: center;
}

.content_schedule_associate_tags .tag_associated table td li {
    float: left;
    margin-right: 12px;
    margin-bottom: 17px;
}

.content_schedule_associate_tags .circle_btn.information > span {
    background: url('../../../../images/icon/information_img.png') no-repeat 12px 8px;
}

.content_schedule_associate_tags .circle_btn > span {
    display: inline-block;
    height: 28px;
    width: 28px;
}

.content_schedule_associate_tags .associatedTagWrap > button {
    cursor: pointer;
    border: 0;
    background: url('../../../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
    float: right;
    margin-top: 4px;
}
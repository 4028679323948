.content_schedule_channel_wrap .channelList ul > li {
    padding: 4px 5px 4px 8px;
    height: 20px;
}

.content_schedule_channel_wrap .option_list > div > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}

.content_schedule_channel_wrap .option_list {
    width: 100%;
    position: absolute;
    top: 97px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
}

.content_schedule_channel_wrap ul li .channelName {
    width: 150px;
    display: inline-block;
    float: left;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.content_schedule_channel_wrap ul li .channelNo {
    width: 30px;
    float: right;
    display: inline-block;
}

.content_schedule_channel_wrap > .base.select > span.arrow {
    float: right;
    width: 10px;
    height: 20px;
    background: url('../../images/button/select_down.png') no-repeat 0 5px;
    margin-left: 10px;
    margin-top: 5px;
}

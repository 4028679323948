.ruleset_add_or_menu .quest_img_noti a {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 13px;
    background: url('../../../images/img/qu_notice.png') no-repeat;
}

.ruleset_add_or_menu .opacity_notice_wrap {
    background: rgba(224,224,224,0.85);
    border: 1px solid #fff;
    position: fixed;
    padding: 20px;
    color: #000;
    line-height: 22px;
}

.ruleset_add_or_menu .opacity_notice_wrap > table > tbody > tr > td {
    color: #000000;
    padding: 0 !important;
}
.addInputBtn {
    height: 22px;
    width: 22px;
    border: 0px;
    float: right;
    background: url('../../images/button/plus_icon_press.png') no-repeat 0 0;
    padding-left: 0px;
    margin-left: 10px;
    margin-top: 5px;
}
.addInputBtn:hover{
    height: 22px;
    width: 22px;
    border:0px;
    float:right;
    background: url('../../images/button/plus_icon_over.png') no-repeat 0 0;
    padding-left: 0px;
    margin-left: 10px;
    margin-top: 5px;
}

.addInputBtn.on{
    height: 22px;
    width: 22px;
    border:0px;
    float:right;
    background: url('../../images/button/minus_icon_press.png') no-repeat 0 0;
    padding-left: 0px;
    margin-left: 10px;
    margin-top: 5px;
}

.message_closebox {
    position: absolute;
    right: 10px;
    display: inline-block;
    width: 19px;
    height: 100%;
    background: url('../../images/button/popup_btn_circle_close_normal.png')  no-repeat;
    border: 0;
    margin: 0;
}
.software-update {
    font-family: Tahoma, Arial, Dotum,sans-serif;
}

.software-update > ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin: 0px 25px 0px 14px;
}

.software-update > ul > li {
    width: 100%;
    height: 28px;
    margin: 3px 3px 3px 0;
    cursor: pointer;
    line-height: 28px;
}

.software-update-icon {
    float: left;
    padding-top: 2px;
}

.software-update-title {
    float: left;
    font-size: 13px;
    color: #454545;
}

.software-update-count {
    display: inline;
    float: right;
    font-size: 20px;
    color: #3196FF;
}
.software-update-period {
    float: right;
    margin: 3px 25px 0px 0px;
    opacity: 0.6;
    font-size: 11px;
    color: #363636;
    letter-spacing: 0;
    text-align: right;
}

.dashboard_widget_popup .dim {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #aaaaaa;
    opacity: .3;
    width: 100%;
    height: 100%;
    z-index: 102;
}

.dashboard_widget_popup .widgets_info_wrap {
    position: absolute;
    right: 110px;
    top: 125px;
    background-color: #fafafc;
    padding-bottom: 2px;
    border-radius: 3px;
    width: 250px;
    height: 180px;
    z-index: 103;
    border: 1px solid #c5c5c5;
}

.dashboard_widget_popup .widgets_info_wrap ul li {
    height: 30px;
    line-height: 30px;
    margin: 10px;
}
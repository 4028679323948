.device_fade_in_wrap {
    opacity: 0;
}

.device_fade_in_wrap.transition {
    transition: opacity .5s ease-in;
    opacity: 1;
}

.device_fade_out_wrap {
    opacity: 1;
    z-index: -1;
}

.device_fade_out_wrap.transition {
    /*transition: opacity .5s ease-in;
    opacity: 0;*/ /*chrome transition : flickering issue.*/
    display: none !important;
}
.content_schedule_detail_view .publish_progress {
    width: 196px;
    height: 4px;
    background: #edf2f7;
    position: relative;
    float: left;
    margin-top: 6px;
    margin-right: 20px;
}
.content_schedule_detail_view .publish_progress > span {
    display: inline-block;
    width: 100%;
    height: 4px;
    background: #66ca84;
    position: absolute;
}

.content_schedule_detail_view .publish_txt {
    display: inline-block;
    color: #9a9a9a;
    font-size: 11px;
    float: left;
}


.content_schedule_detail_view .pop_list_2 table thead {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    border: 1px solid #d2d2d3;
    background: #eeeeef;
}

.content_schedule_detail_view .pop_list_2 table tr th:first-child {
    padding-left: 40px;
}
.content_schedule_detail_view .pop_list_2 table thead th {
    background: url(../../../../images/bg/table_divider.png) no-repeat right #eeeeef;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    padding-left: 10px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #f7f7f7;
    color: #797979;
}
.content_schedule_detail_view .pop_list_2 table tr td:first-child {
    padding-left: 10px;
}
.content_schedule_detail_view .pop_list_2 table tr td {
    padding-left: 9px;
    color: #818181;
}
.content_schedule_detail_view .detail_view table td {
    padding: 6px 0 5px 0;
    padding-left: 0px;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.content_schedule_detail_view .pop_list_2 {
    border: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.content_schedule_detail_view .pop_list_2 table tr:hover td{
    background:#f3f9ff;
}
.content_schedule_detail_view .pop_list_2 a:hover{
    color:#609be8;
}
.user_info_wrap {
    position: relative;
    background: #fff;
    border-radius: 3px;
}
.user_info_wrap .user_thum {
    padding: 27px 20px 0 24px;
    float: left;
    width: 130px;
}


.user_info_wrap .user_thum > span {
    display: block;
    font-size: 12px;
    color: #6a6a6a;
    font-weight: bold;
    padding-top: 15px;
}
.user_info_wrap .user_thum > span > span {
    display: block;
    font-size: 20px;
    color: #7f7f7f;
    font-weight: normal;
    padding-top: 4px;
    width: 140px;
    height: 25px;
    text-overflow: ellipsis;
    display: block;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 250px;
}
.user_info_wrap .user_detail {
    float: left;
    padding: 0 0 0 43px;
}

.user_info_wrap .user_detail ul > li:first-child > span {
    padding-top: 10px;
}
.user_info_wrap .user_detail ul > li > span {
    display: block;
    font-size: 12px;
    color: #6a6a6a;
    font-weight: bold;
    padding-top: 18px;
}

.user_info_wrap .user_detail ul > li > span > span {
    display: block;
    font-size: 16px;
    color: #7f7f7f;
    font-weight: normal;
    padding-top: 6px;
}

.logout_user_info .ui-dialog {
    top: 250px;
    left: initial;
    right: 560px;
}

.logout_popup .dim {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #aaaaaa;
    opacity: .3;
    width: 100%;
    height: 100%;
    z-index: 102;
}
.logout_popup .user_info_wrap {
    position: absolute;
    right: 80px;
    top: 80px;
    background: rgba(0,0,0,0.15);
    padding-bottom: 2px;
    border-radius: 3px;
}


.logout_popup .user_info_wrap > div {
    position: relative;
    max-width: 600px;
    width: auto;
    background: #fff;
    border-radius: 3px;
}

.logout_popup .user_info_wrap > div > span {
    position: absolute;
    top: -13px;
    right: 22px;
}
.logout_popup .user_info_wrap h3 {
    margin: 0;
    font-size: 13px;
    line-height: 47px;
    border-bottom: 1px solid #ebebeb;
    padding-left: 20px;
}

.logout_popup .user_info_wrap > div > .clearfix {
    border-bottom: 1px solid #ebebeb;
    margin: 0 25px;
    height: auto;
}

.logout_popup .user_info_wrap .user_detail {
    float: right;
    padding: 10px 10px 0 0px;
}

.logout_popup .user_info_wrap .user_detail ul > li {
    cursor: pointer;
}

.logout_popup .user_info_wrap .user_detail ul > li:first-child > span {
    padding-top: 10px;
}

.logout_popup .user_info_wrap .user_detail ul > li > span {
    display: block;
    font-size: 10px;
    color: #6a6a6a;
    font-weight: bold;
    padding-top: 18px;
}

.logout_popup .user_info_wrap .user_detail ul > li > span > span {
    display: block;
    font-size: 16px;
    color: #7f7f7f;
    font-weight: normal;
    padding-top: 6px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 250px;
}

.logout_popup .user_info_wrap .user_detail ul > li > a > span {
    display: block;
    font-size: 10px;
    color: #6a6a6a;
    font-weight: bold;
    padding-top: 18px;
}

.logout_popup .user_info_wrap .user_detail ul > li:last-child {
    margin-bottom: 37px;
}


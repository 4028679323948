.date_picker_wrap {
    position: relative;
    z-index: 1;
}

.date_picker_wrap input {
    text-align: center;
}

.date_picker {

}

.date_picker_wrap .date_picker_row {
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    color: #686b72;
}

.date_picker_wrap .date_picker_row:hover {
    background-color: #cccccc;
}

.date_picker_wrap .date_picker_row.selected {
    background-color: #d0d3d8;
}
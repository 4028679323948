.detail_table.table_head table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url('../../../../images/bg/table_divider.png') no-repeat right #eeeeef;
}

.detail_table.table_body table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 9px 10px 9px 10px !important;
    line-height: 20px;
    position: relative;
    color: #797979;
}
.detail_table.table_body {
    overflow-x: hidden;
    overflow-y: auto;
}
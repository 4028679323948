.detail_view.setting table td {
    width: 70%;
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
    line-height: 28px;
}

.detail_view.setting table td > input.light_gray:disabled {
    border-color: #e5e8ec;
    background: #f5f5f5;
    color: #aeb0b3;
}

.detail_view.setting table td > input.ta_c {
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.detail_view.setting table td > input.light_gray {
    background: #fbfcfd;
    color: #686b72;
}

.detail_view.setting table td > input {
    float: left;
}

.playlist.detail_view {
    padding-top: 0px!important;
}

.detail_view.setting table {
    border-collapse: collapse;
    border-spacing: 0;
}

.detail_view.setting > table > tbody > tr.tp20 > th {
    padding: 20px 0 10px 0;
}
.detail_view.setting > table > tbody > tr > th {
    vertical-align: middle;
    color: #646464;
}
.detail_view.setting > table > tbody > tr.line > td {
    padding: 20px 0;
    border-bottom: 1px solid #eeeff0;
}

.detail_view.setting table tr.advertisement {
    background: #fcfcfd;
    border: 1px solid #efeff0;
}

.detail_view .advertisement th {
    width: 10%;
    text-align: right;
    padding-right: 20px;
}

.detail_view .advertisement td {
    width: 20%!important;
}

.detail_view.setting table tr.advertisement .info_button:hover {
    background: url('../../../images/icon/info_over.png.png') no-repeat right center;
}
.detail_view.setting table tr.advertisement .info_button {
    width: 22px;
    height: 22px;
    background: url('../../../images/icon/info_normal.png.png') no-repeat right bottom;
}

.detail_view table td label {
    color: #474747;
    display: inline-block;
}

.playlist_setting_popup .ui-dialog-content {
    overflow: visible!important;
}
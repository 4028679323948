.all_device_wrap {
    width: 100%;
}

.all_device_wrap .contents_buttonWrap .question_icon_wrap {
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.all_device_wrap .contents_buttonWrap .question_icon {
    margin-top: 2px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
    background: url('../../images/icon/icon_info.png') no-repeat 0 0;
    display: inline-block;
}

.all_device_wrap .device_image_view_wrap {
    position: relative;
    padding-left: 25px;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 0;
    margin-right: 0;
    min-width: 500px;
    margin-bottom: 30px;
}

.all_device_wrap .device_image_view_wrap li {
    float: left;
    margin: 8px 8px 0 0;
}

.all_device_wrap .device_image_view_wrap .thumbview_box.device_thumb.landscape {
    display: table-cell;
}
.all_device_wrap .device_image_view_wrap .thumbview_box.device_thumb {
    vertical-align: middle;
    text-align: center;
}
.all_device_wrap .device_image_view_wrap .thumbview_box {
    width: 100px;
    height: 100px;
    position: relative;
}

.all_device_wrap .device_image_view_wrap .thumbview_box.device_thumb.landscape > div.dev_thumb_img_wrapper {
    width: 100%;
    height: 100%;
    background-color: #DDDEDE;
    text-align: center;
}
.all_device_wrap .device_image_view_wrap .thumbview_box.device_thumb.landscape > div.dev_thumb_img_wrapper > div {
    width: 100%;
    height: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: center;
}

.all_device_wrap .device_image_view_wrap .thumbnailview_wrap .check {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../images/bg/thumb_layer_bg.png') repeat;
}

.all_device_wrap .device_image_view_wrap .thumbnailview_wrap .check span {
    width: 100%;
    height: 100%;
    display: inline-block;
    background: url('../../images/img/thumb_check.png');
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60px;
}

.all_device_wrap .device_image_view_wrap .thumbview_box .ov {
    display: none;
    position: absolute;
    top: 30px;
    left: 30px;
    width: 259px;
    height: auto;
    border: 1px solid #fff;
    background: url('../../images/bg/bg_icon_info.png') repeat 0 0;
}

.all_device_wrap .device_image_view_wrap .status_wrap {
    padding: 7px;
    margin-top: -3px;
    color: #fff;
    height: 55px;
}

.all_device_wrap .device_image_view_wrap .status_wrap > span.dev_name {
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.all_device_wrap .device_image_view_wrap .status_wrap > span {
    display: inline-block;
    font-size: 11px;
    margin-bottom: 16px;
    word-wrap: break-word;
    height: 20px;
    /* width: 100%; */
}

.all_device_wrap .device_image_view_wrap .bg_afb3b8 {
    background: #afb3b8;
}

.all_device_wrap .device_image_view_wrap .thumbview_wrapper > div > .status_wrap > div > .info_button {
    width: 22px;
    height: 22px;
    background: url('../../images/icon/info_normal.png.png') no-repeat right bottom;
    margin-top: -4px;
}

.all_device_wrap .device_image_view_wrap .device_empty_view {
    width: 100%;
    text-align: center;
}

.all_device_wrap .device_image_view_wrap .device_empty_view img:first-child {
    margin-bottom: 41px;
}

.all_device_wrap .device_image_view_wrap .device_empty_view div:nth-child(2) {
    font-size: 26px;
    color: #d9e0e7;
    margin-bottom: 17px;
}

.all_device_wrap .device_image_view_wrap .device_empty_view div:last-child {
    font-size: 15px;
    color: #cbcbcb;
    line-height: 20px;
}

.all_device_wrap .tooltip_wrap {
    display: inline-block;
}

.all_device_wrap .tooltip_wrap .tooltip_div {
    position: absolute;
    width: 80px;
    height: 28px;
}

.all_device_wrap .tooltip_wrap .tooltip {
    position: absolute;
    top: 30px;
    left: 540px;
    border: 1px solid black;
    padding: 5px;
    z-index: 99;
}

.all_device_wrap .device_loading_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center; 
    align-items: center;
}
.approved_device {margin-right:35px;width:222px;}
.approved_device > div {border:0 !important;width:100%;}
.approved_device ul li {background:#f0f0f0;font-size:12px;color:#717a89;font-weight:bold;padding:20px 20px 0 20px;line-height:16px;height:73px;}
.approved_device ul li > span {display:block;font-size:28px;color:#c6c6c7;font-weight:normal;padding-top:10px;}
.approved_device ul li.approved_device_item span {padding-top:10px;color:#fff}


.space_wrap {padding:135px 20px 0 20px;}


.statistics_title {font-size:12px;font-weight:bold;color:#686b72;}
.statistics_title > span {font-weight:normal;color:#b8b8b8;padding-left:20px}
.statistics_title .no_data{text-align: center; padding-top: 100px; width: 500px}

.statistics_noresults {border-top:1px solid #f5f5f5;}
.statistics_noresults span {color:#cbcbcb;font-size:15px;display:inline-block;width:100%;text-align:center;padding-top:250px}

.statistics_list .select_box {
    display: inline-block;
    position: relative;
    float: left;
    margin-right: 8px;
}

.statistics_list .select_box > button {
    height: 28px;
    border: 1px solid #dde0e5;
    color: #363636;
    background: #fff;
    font-size: 12px;
    line-height: 28px;
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
    border-radius: 15px;
    cursor: pointer;
}

.statistics_list .float_l {
    float: left !important;
}

.total_number {font-size:11px;position:absolute;right:30px;bottom:-5px;}

.space {line-height:20px}
.space.explain {color:#a9a9a9;font-size:11px;}
.space2 {line-height:27px;background:url('../../images/bg/bar_5.png') no-repeat 0 8px;padding-left:15px;}


.chip_wrap {
    display: inline-block;
    border: none;
    background-color: #eff2f6;
    border-radius: 20px;
    color: #686b72;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding: 5px 30px 5px 10px;
}

.chip_wrap > .delete_btn {
    float: right;
    border: 0;
    background: url('../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
    position: absolute;
    right:7px;
    top:8px;
}

.chip_wrap > .delete_btn:hover {
    background:url('../../images/icon/dashboard_delete_hover.png') no-repeat;
}

.chip_wrap > .delete_btn:active {
    background:url('../../images/icon/dashboard_delete_select.png') no-repeat;
}
.usage_wrap {background:#f7f7f8;width:312px;height:237px;padding-top:44px;position:relative;}
.usage_wrap > div {position:absolute;}
.usage_wrap > span {display:inline-block;position:absolute;font-size:10px;color:#7c7f80;}
.usage_wrap > .time {font-size:12px;color:#555555;}
.usage_wrap > ul {padding-left:37px;}
.usage_wrap > ul > li {width:249px;border-top:1px solid #ececed;height:49px;}
.usage_wrap > ul > li:last-child {border-bottom:1px solid #ececed;}

.cpu {width:40px;height:20px;border-radius:20px 20px 0 0;z-index:100}
.ram {width:80px;height:40px;border-radius:40px 40px 0 0;z-index:90}
.network {width:120px;height:60px;border-radius:60px 60px 0 0;z-index:80}

.usage_name {margin-left:214px;margin-top:15px;}
.usage_name li {float:left;margin-right:48px;color:#616161;}
.usage_name li > span {display:inline-block;width:9px;height:9px;border-radius:5px;margin-right:10px;}
.usage_name li:first-child {margin-right:66px;}
.usage_name li:last-child {margin-right:0px;}
.bgc_6d8cf2 {background:#6d8cf2}
.bgc_f86c79 {background:#f86c79}
.bgc_3acb63 {background:#3acb63}

.detail_view .base:disabled {
    border-color: #b9b9b9;
    color: #b9b9b9;
    background: #fff;
    cursor: default;
}

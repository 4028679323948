.select_box {
    display: inline-block;
    position: relative;
    float: left;
}

.select_box > button {
    height: 28px;
    border: 1px solid #5e5e5e;
    color: #363636;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
    border-radius: 15px;
    cursor: pointer;
}

.filter_container > button.contentSchedule {
    width: 28px;
    border: 1px solid #dde0e5;
    background-color: #333c4e;
}



.select_box > button  {color:#848484;/*border-color:#b4b9c3;background:#ffffff;*/}


.select_box .filterByIcon {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    display: inline-block;
    background: url('../../images/icon/icon_filterby.png') no-repeat -2px 0;
}
.select span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url('../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
}

.filter_container .filter_wrap {
    position: absolute;
    right: -274px;
    top: 42px;
    background: rgba(0,0,0,0.15);
    padding-bottom: 2px;
}
.filter_container .filter_wrap > div {
    position: relative;
    padding: 0 21px 0 21px;
    background: #fff;
    height: auto;
}
.filter_container .filter_wrap > div > .filter_status {
    border-bottom: 1px solid
    #eeeff2;
    padding: 20px 0;
}
.filter_container .filter{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}
.filter_container .filter > div {
    float: left;
    width: 183px;
    margin-right: 5px;
    flex: 0 0 auto;
}
.filter_container .filter > div > div {
    background: #f3f3f3;
}
.filter_container .filter > div > h5 {
    color: #4e4e4e;
    font-weight: normal;
    font-size: 12px;
    color: #4e4e4e;
    margin: 16px 0 17px 0;
}
.filter_container .filter > div > h5 > span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 6px;
}
.filter_container .filter > div > div > ul {
    height: 236px;
}

.filter_container .filter_wrap > div > .btn_wrap {
    width: 100%;
    text-align: center;
    padding: 20px 0;
}

.filter_container .dim_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: #aaa;
    opacity: 0.5;
    filter: Alpha(Opacity=50);
}
.filter_container .filter_wrap > div > .filter_status {
    border-bottom: 1px solid
    #eeeff2;
    padding: 20px 0;
}
.filter_container .filter_wrap > div > .filter_status > span > span {
    vertical-align: top;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;    
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_device_type_filter {
    background-color: #ffa6a6;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_content_type_filter {
    background-color: #ffc446;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_user_filter {
    background-color: #93bde8;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_approval_filter {
    background-color: #60cfa6;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_content_using_status {
    background-color: #ff85cd;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_expiration_status {
    background-color: #e27be8;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_tag_filter {
    background-color: #c997e1;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_size_filter {
    background-color: #883939;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_ruleset_status_filter {
    background-color: #009966;
}
.filter_container .filter_wrap > div > .filter_status > span > span.filter_status_playlist_type {
    background-color: #ffc446;
}

.filter_container .filter_wrap > div > .filter_status > span > span {
    font-size: 11px;
    color: #fff;
}
.filter_container .filter_wrap > div > .filter_status > span > span {
    font-size: 11px;
    display: inline-block;
    border-radius: 10px;
    color:
            #fff;
    padding: 0 8px;
    margin-right: 10px;
    margin-bottom: 6px;
}
.filter_container .filter > div > div > ul > li:first-child {
    padding-top: 4px;
}
.filter_container .filter > div > div > ul > li:active, .filter > div > div > ul > li.on {
    background: #f9f9f9;
}

.filter_container .filter > div > div > ul > li:active, .filter > div > div > ul > li.on a{
    color: #3196ff;
}

.filter_container .filter > div > div > ul > li {
    line-height: 26px;
    padding-left: 11px;
    font-size: 11px;
}

.filter_container .filter .dev_filter_type_checkbox .content_check_box label {
    display:inline-block;
    line-height:18px;
}

.filter_container .filter .dev_filter_type_checkbox .content_check_box .text_all {
    margin-bottom: 7px;
}

.filter_container .filter .dev_filter_type_checkbox .device_type_checkbox label {
    display:inline-block;
    line-height:18px;
}

.filter_container .filter .dev_filter_type_checkbox .device_type_checkbox .text_all {
    margin-bottom: 7px;
}

.filter_container .filter .dev_filter_type_checkbox .content_check_box label span{
    display:inline-flex;
    align-items:center;
    line-height:18px;
}

.filter_container .filter .dev_filter_type_checkbox .device_type_checkbox label span{
    display:inline-flex;
    align-items:center;
    line-height:18px;
}


.input_date_wrap, .schedule_date_warp {
    position: relative;
    width: 118px;
    display: inline-block;
}

.input_date_wrap input, .schedule_date_warp input {
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.input_date_wrap > button, .schedule_date_warp > button {
    position: absolute;
    top: 3px;
    right: 16px;
    width: 22px;
    height: 22px;
    background: url('../../images/icon/calander_icon_normal.png');
    border: 0;
}

.device_name_wrap {
    position: relative;
    padding-left: 25px;
}

.device_name_wrap .tb_arrow {
    width: 18px;
    height: 18px;
    border: 0;
    background: url('../../images/img/list_scroll_down_normal.png') no-repeat 0px;
    position: absolute;
    left: 0px;
    top: 12px;
    cursor: pointer;
}

.device_name_wrap .tb_arrow:hover {
    background: url('../../images/img/list_scroll_down_hover.png') no-repeat 0px;
}

.device_name_wrap .tb_arrow.on {
    background: url('../../images/img/list_scroll_up_normal.png') no-repeat 0px;
}

.device_name_wrap .tb_arrow.on:hover {
    background: url('../../images/img/list_scroll_up_hover.png') no-repeat 0px;
}
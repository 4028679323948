.locationList .radio_group_wrap div {
    display: block;
    margin-bottom: 22px;
}

.location_area {
    width: 530px;
    height: 246px;
    background-color: #F7F7F7;
    border-color: #EAEAEA;
    border-width: 1px;
    border-style: solid;
}

.location_area .separate_line{
    width: 2px;
    height: 169px;
    background-color: #DEDEDE;
    margin-top: 38px;
    float: left;
}

.locationTitle {
    width: 160px;
    height: 16px;
    float: left;
    margin-left: 20px;
    margin-top: 38px;
}

.locationList {
    width: 259px;
    height: 200px;
    float: left;
    margin-left: 20px;
    margin-top: 38px;
}

.pp_area {
    width: 490px;
    height: 226px;
    background-color: #F7F7F7;
    border-color: #EAEAEA;
    border-width: 1px;
    border-style: solid;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 20px 20px 20px;

}

.pp_agree_area {
    width: 520px;
    height: 32px;
    margin-top: 10px;
}

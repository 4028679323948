.content_share_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0px !important;
    max-height: 26px;
    line-height:12px !important;
    cursor: pointer;
}

.content_share_tr_group:hover {
    background:#f3f9ff !important;
}

.selected_row {
    background: #e6f2ff
}

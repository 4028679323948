
.content_download_status_container {
    margin-top: 20px;
}

.content_download_status_container > .title {
    padding-left: 20px;
    height: 50px;

}

.content_download_status_wrap {
    display: flex;
}

.content_download_status_wrap .chart_wrap {
    width: 50%;
    padding: 15px;
}

.content_download_status_wrap .table_wrap {
    width: 50%;
    padding: 15px;
}
.content_download_status_wrap .table_wrap table {
    width: 100%;
}

.content_download_status_wrap .table_wrap table tr th {
    border-bottom: 1px solid #f0f0f0;
    background: #fafafa;
    border-right: 1px solid #f0f0f0;
    line-height: 30px;
    padding-left: 12px;
    border-top: 1px solid #f0f0f0;
}

.content_download_status_wrap .table_wrap table tr:hover td {
    background: #f3f9ff;
}
.content_download_status_wrap .table_wrap table table tr td {
    border-bottom: 1px solid #f0f0f0;
}
.content_download_status_wrap .table_wrap table tr td {
    line-height: 15px;
    vertical-align: middle;
    color: #797979 !important;
    padding: 12px;
}
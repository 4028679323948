.statistics_calendar .date_picker {
    float: left;
}

.statistics_calendar .react-datepicker-wrapper {
    float:left;
    width: 120px;
}

.statistics_calendar  .react-datepicker-popper{
    z-index:1000;
}

.statistics_calendar .statistics_calendar_dash {
    float: left;
    line-height:20px;
    margin-top: 2px;
}

.statistics_calendar .date_input {
    width: 120px;
    height: 30px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

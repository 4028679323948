.ruletree-label[type="condition"] {
	border-radius: 4px;
}

[rule-node-type="curr_time"] {
	border: 4px solid #94379F;
	background: #94379F;
}

[rule-node-type="curr_date"] {
	border: 4px solid #07B7A5;
	background: #07B7A5;
}

[rule-node-type="device_nm"] {
	border: 4px solid #EEA62F;
	background: #EEA62F;
}

[rule-node-type="dev_ip"] {
	border: 4px solid #0087C6;
	background: #0087C6;
}

[rule-node-type="dev_mdnm"] {
	border: 4px solid #77C255;
	background: #77C255;
}

[rule-node-type="screen_rotation"] {
	border: 4px solid #564BB8;
	background: #564BB8;
}

[rule-node-type="screen_size"] {
	border: 4px solid #02B3E6;
	background: #02B3E6;
}

[rule-node-type="media_tag"] {
	border: 4px solid #D35375;
	background: #D35375;
}

[rule-node-type="datalink"] {
	border: 4px solid #9B693A;
	background: #9B693A;
}

.ruletree-label[type="condition"] .op-icon {
	width: 12px;
	height: 12px;
}

.ruletree-label[type="condition"] .op-icon.circle {
	position: absolute;
	margin-top: 2px;
}

.ruletree-label[type="condition"] .op-icon.eq {
	background: url('../images/ic_equal.png') no-repeat 0 center;
}

.ruletree-label[type="condition"] .op-icon.ne {
	background: url('../images/ic_not_equal.png') no-repeat 0 center;
}

.ruletree-label[type="condition"] .op-icon.invert {
	background: url('../images/ic_invert.png') no-repeat 0 center;
}

.ruletree-label[type="condition"] .op-icon.etc {
	background: url('../images/ic_etc.png') no-repeat 0 center;
}

.ruletree-label .description.name {
	cursor: pointer;
}

.ruletree-label .description.name:hover {
	text-decoration: underline;
}
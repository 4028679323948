.sssp_web_app_popup {
    padding: 0 25px;
    position: relative;
}

.sssp_web_app_popup .warringText {
    white-space: normal;
    width:500px;
    display:inline-block;
}

.sssp_web_app_popup .tag_input {
    min-width:66px;
    display:none;
    position: relative;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid #D6DEE8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 1px;
}

.sssp_web_app_popup .tag_input > input[type="text"] {
    line-height: 28px;
    background: #eff2f6;
    padding: 0 !important;
    border: 0 !important;
    cursor: text !important;
}

.sssp_web_app_popup .tag_input > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url('../../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
}

.sssp_web_app_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.sssp_web_app_popup table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.sssp_web_app_popup table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.sssp_web_app_popup .circle_btn {
    padding: 0;
    height: 28px;
    width: 28px;
    border: 1px solid #dde0e5;
    color: #848484;
    background: #fff;
    border-radius: 15px;
    text-align: center;
}

.sssp_web_app_popup .circle_border {
    border: 1px solid #dde0e5 !important;
}

.sssp_web_app_popup .circle_btn span{
    display: inline-block;
    height: 28px;
    width: 28px;
}

.sssp_web_app_popup .circle_btn.search span{
    background: url('../../../images/icon/search_circle_normal.png') no-repeat 8px 7px;
}

.sssp_web_app_popup .refresh_interval {

}

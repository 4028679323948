.ruletree-label {
    display: block;
    width: 190px;
    height: 85px;
    padding: 0px 6px;
    line-height: 16px;
    text-align: left;
    border-radius: 4px;
    position: absolute;
    left: 0;
    z-index: 10;
    background: #fff;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.ruletree-label .description {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    font-family: Tahoma;
    margin-bottom: 2px;
    margin-left: 4px;
}

.ruletree-label .description.name {
    margin-left: 0px !important;
}

.ruletree-label .name {
    font-size: 13px;
    font-family: Tahoma;
    font-weight: bold;
    margin-bottom: 8px;
}

.ruletree-label > div {
	color: #FFFFFF;
	position: relative;
	top: 50%; 
	transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    margin-right: 13px;
}

.ruletree-label .option_button {
	position: absolute;
    right: 0;
    margin-top: 6px;
	margin-right: 6px;
	width: 12px;
	height: 12px;
    border: 0px;
	background: url('./images/more option_v.png') no-repeat 0 0;
}

.ruletree-label-plus-button {
    display: block;
    width: 190px;
    padding: 10px 0px;
    text-align: left;
    border: 3px solid transparent;
    position: absolute;
    left: 0;
    z-index: 10;
    background: unset;
}

.ruletree-label-drag-hover {
	border: 4px dashed #00b0f0 !important;
	border-radius: 5px;
}

.ruletree-label-plus-button > button {
	color: #000000;
    position: relative;
    top: 29px;
    left: -3px;
	transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.ruletree-label-no-result-button {
    display: block;
    width: 190px;
    height: 85px;
    padding: 10px 0px;
    border: 3px solid transparent;
    text-align: left;
    position: absolute;
    left: 0;
    z-index: 10;
    background: unset;
}

.ruletree-tooltip {
    position: relative;
    display: inline-block;
}

.ruletree-tooltip .ruletree-tooltip-text {
    visibility: hidden;
    background-color: #484848;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 0.9em;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 200ms;
}

.ruletree-tooltip .ruletree-tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #484848;
}

.ruletree-tooltip:hover .ruletree-tooltip-text {
    visibility: visible;
    opacity: 1;
}

.ruleset_condition_no_result {
    position: absolute !important;
    top: 42px !important;
    left: 190px;
    width: 100px;
    height: 85px;
	border: 3px solid transparent;
}

.ruleset_condition_no_result_button {
	position: absolute;
    top: 33px;
    left: -11px;
	height: 15px;
    width: 15px;
    border: 0px; 
	background: url('./images/icon_status_nor.png') no-repeat 0 0;
}

.ruleset_condition_add_button {
    position: absolute;
	top:27px;
	height: 30px;
    width: 30px;
    border: 0px; 
	background: url('./images/ic_rule_add.png') no-repeat 0 0;
}

.arrow-right {
    top:31px;
	left:-6px;
    position:absolute;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 10px solid black;
}

.verification-mode-result-count {
    position: absolute !important;
    top: -5px !important;
    left: -23px;
    background-color: #92d050;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align:center;
    margin:0 auto;
    font-size:12px;
    font-family: Tahoma;
    font-weight: bold;
    vertical-align:middle;
    line-height:40px;
}

.ruletree-label.highlight {
	border: 4px solid #00B0F0 !important;
}

.ruletree-label.fixed-highlight {
	border: 4px solid #0070c0 !important;
}
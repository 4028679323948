.expired_content_popup .content_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0px !important;
    max-height: 40px;
}

.expired_content_popup .notify_wrap {
    color: #999999;
    font-size: 11px;
    margin-bottom: 12px !important;
}

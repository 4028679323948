
.pop_version_list {
    border:1px solid #e7e7e7;
    border-top: 0px;
    border-bottom-left-radius:2px;
    font-size:13px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.pop_version_list table {
    border-collapse: collapse;
    border-spacing: 0;
}

.pop_version_list table thead {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 13px;
    z-index:1;
    background:#eeeeef;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #d2d2d3;
}

.pop_version_list table thead th {
    vertical-align:middle;
    padding:7px 10px 7px 10px;
    color:#797979;
}

.pop_version_list table thead th:first-child {
    background: url(../../../images/bg/table_divider.png) no-repeat right #eeeeef;
}
.pop_version_list table tr th {
    text-align: center;
}
.pop_version_list table tr th:not(:last-child) {
    background: url('../../../images/bg/table_divider.png') no-repeat right #eeeeef;
}

.pop_version_list table tr th:last-child {
    border-right:0;
}

.pop_version_list table tr td {
    color:#818181;
    vertical-align: middle;
    height: 28px;
    padding: 6px 0 5px 0;
    cursor:pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.pop_version_list table tr td label {
    color:#818181;
    display: inline-block;
}

.pop_version_list table tr td:first-child {
    padding-left:10px;
    backgroupd: #fafafa;
}

.pop_version_list table tr:last-child td {
    border-bottom:0px solid #f2f2f2;
}

.pop_version_list table tr:hover td {
    background:#f3f9ff;
}

.pop_version_list table tr.on td,
.pop_version_list table tr:active td {
    background:#e1eefb;color:#2a2a2a;
}

.pop_version_list table tr td input[type="radio"] {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
}

.pop_version_list table tr td input[type="radio"] + label {
    display:inline;
    padding-left:24px;
    color:#59616b;
    background:url('../../../images/img/radio_normal.png') no-repeat;
    padding-bottom:2px;
    cursor:pointer;
}

.pop_version_list table tr:active td input[type="radio"] + label,
.pop_version_list table tr.on td input[type="radio"] + label {
    background:url('../../../images/img/radio_checked.png') no-repeat;
    cursor: pointer;
}

.pop_version_list table tr td label > span {
    display:inline-block;
    background:#eeba07;
    font-size:9px;
    color:#fff;
    border-radius:10px;
    padding:0 5px;
    margin-left:9px;
}
.event_schedule_wrap .contents_buttonwrap {
    border-bottom: 1px solid #ecedee;
    padding-bottom: 21px;
    height: 38px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-left: 20px;
}

.event_schedule_wrap .contents_buttonwrap button {
    margin-right: 10px;
}

.event_schedule_wrap .schedule_message h2 {
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
}

.event_schedule_wrap .schedule_message {
    padding: 0;
}
.event_schedule_wrap .contents {
    word-break: break-all;
}

.event_schedule_wrap .schedule_message > div {
    padding: 0 0 0 24px;
}


.event_schedule_wrap .message_detail_setting > table > tbody > tr > th {vertical-align:middle;color:#646464;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr.line > th {padding:20px 0;border-bottom:1px solid #eeeff0;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr.line > td {padding:20px 0;border-bottom:1px solid #eeeff0;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr.line.pt10 > th {padding:10px 0 20px 0;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr.line.pt10 > td {padding:10px 0 20px 0;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr > td {padding:10px 0;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr.tp20 > th {padding:20px 0 10px 0;}
.event_schedule_wrap .message_detail_setting > table > tbody > tr.tp20 > td {padding:20px 0 10px 0;}

.event_schedule_wrap .message_detail_setting > table > tbody > tr {line-height: 25px;}


.event_schedule_wrap .base.light_gray.select:disabled {
    border-color: #e5e8ec;
    color: #b2b3b3;
    background: #f8f9f9;
}
.event_schedule_wrap .base.light_gray.select:hover {
    border-color: #b4b9c3;
    color: #848484;
}
.event_schedule_wrap .base.un_radius.select {
    border-radius: 5px;
}
.event_schedule_wrap .base.light_gray.select {
    border: 1px solid #dde0e5;
    color: #848484;
    background: #fbfcfd;
}

.event_schedule_wrap .contents_buttonwrap.line {border-bottom:1px solid #ecedee;padding-bottom:21px;}

.event_schedule_wrap .condition_checkbox_wrap > span {
    margin-left: 10px;
}
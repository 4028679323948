.tag_information_for_sync_schedule {
    height:180px;
    border: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.tag_information_for_sync_schedule table {
    width: 100%
}

.tag_information_for_sync_schedule table tr th {
    background: #fafafa;
    border-right: 1px solid #f0f0f0;
    line-height: 30px;
    padding-left: 12px;
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.tag_information_for_sync_schedule table tr td:first-child {
    padding-left: 14px;
}
.tag_information_for_sync_schedule table tr td {
    line-height: 15px;
    vertical-align: middle;
    color: #797979 !important;
    padding: 12px;
    padding-left: 12px;
}

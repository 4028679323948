.slot_setting_popup .schedule_slots_setting > li {
    padding: 6px 10px 6px 16px;
    border-bottom: 1px solid #f2f2f2;
    line-height: 20px;
    position: relative;
    color: #797979;
    height: 30px;
    display: flex;
}

.slot_setting_popup .schedule_slots_setting > li:hover {
    background: #eff0f1;
}

.slot_setting_popup .schedule_slots_setting > li > .delete {
    width: 19px;
    height: 19px;
    background: url('../../../../images/icon/dashboard_delete_normal.png');
    cursor: pointer;
    line-height:20px;
    display: none;
    margin-left: 60px;
    border: 0px;
}
.slot_setting_popup .schedule_slots_setting li:hover .delete {display:block}
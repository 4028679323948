.multiSelect {
    display: inline-block;
    position: relative;
}

.multiSelect_button {
    border: 1px solid;
}


.mr8 {
    margin-right: 8px !important;
}

.float_l {
    float: left !important;
}

.multiSelect .base.select {
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
    min-width:120px;
    width: 100%;
}

.multiSelect .rightButton .base {
    height: 28px;
    border: 1px solid #5e5e5e;
    color: #363636;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
}

.multiSelect .base.select   span {
    width: 100%;
}

.multiSelect .base.select {
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
}


.multiSelect .base.select .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url('../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
}

.multiSelect .option_list {
    width: fit-content;
    position: absolute;
    top: 37px;
    left: 0;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 100;
    min-width: 170px;
    display: block;
}

.multiSelect .div {
    display: block;
}

.multiSelect .option_list > div > span {
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background: url('../../images/bg/option_list_bg.png') no-repeat 10px 0;
}

.multiSelect .option_list > ul {
    margin: 0;
    padding: 0;
}

.multiSelect .option_list > ul >li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}

.playlist_filter_label{
    word-break: break-all;
    display: inline-block;
}
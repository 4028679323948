.ruletree-label[type="result"], .ruletree-label[type="default"] {
    border-radius: 4px;
	background: #727272;
}

#play-rule-content-info .opacity_notice_wrap {
    position: absolute;
    width: 200px;
    padding: 10px;
    z-index: 2000; 
    background: rgba(224,224,224,1);
    border: 1px solid #fff;
    color: #000;
    line-height: 22px;
}

#play-rule-content-info .opacity_notice_wrap > table > tbody > tr > td {
    display: inline-block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000000;
    padding: 0 !important;
}
.role_user_pop_wrap {
    overflow-y: auto;
    overflow-x: hidden;
}
.role_user_detail_view {
    position: relative;
}
.role_user_list {
    overflow-y: auto;
    height: 250px;
}
.role_user_detail_view table {
    width: 100%;
}
.role_user_detail_view.user_info table th {
    font-size: 13px;
}
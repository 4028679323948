.detail_view .replash2 {
    display: block;
    border: 0;
    width: 25px;
    height: 25px;
    background: url(../../../../images/button/reflash_normal2.png);
}

.detail_view .replash2:hover {
    background: url(../../../../images/button/reflash_hover2.png);
}

.detail_view .replash2:active {
    background: url(../../../../images/button/reflash_select2.png);
}

.detail_view .log_collect_desc {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
    white-space: nowrap;
}

.detail_view .log_collect_table {
    height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 5px;
}

.device_detail_view_wrap .log_list {
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: auto;
    overflow-y: auto;
}

.device_detail_view_wrap .log_list ul {
    top: 0px;
    left: 0px;
    z-index: 1;
}
.device_detail_view_wrap .log_list ul li {
    color: #797979;
    vertical-align: middle;
    padding: 5px 0px 0px 5px;
}
/* .log_list ul li:first-child {padding:7px 0 7px 11px;}
.log_list ul li:last-child {border-right:0;} */
.device_detail_view_wrap .log_list ul li.icon:hover {
    background: #f3f3f3;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}
.device_detail_view_wrap .log_list ul li.icon:active {
    background: #f0f0f0;
}
.device_detail_view_wrap .log_list ul li.icon > a {
    display: none;
}
.device_detail_view_wrap .log_list ul li.icon:hover > a {
    display: block;
    width: 15px;
    height: 15px;
    float: right;
    margin-right: 0px;
    padding: 0;
    background: url('../../../../images/img/list_arrow.png') no-repeat 5px 7px;
}

.device_detail_view_wrap .log_list table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 9px 10px 9px 10px;
    line-height: 20px;
    position: relative;
    color: #797979;
}
/* .log_list table tr td:first-child {border-right: 1px solid #f2f2f2; border-right:0;padding:7px 0 7px 11px;} */
.device_detail_view_wrap .log_list table tr:last-child td {
    border-bottom: 1px solid #f2f2f2;
}
.device_detail_view_wrap .log_list table tr:hover td {
    background: #f3f9ff;
    border-bottom: 1px solid #f3f9ff;
}
.device_detail_view_wrap .log_list table tr td.icon img {
    margin-right: 4px;
}
.device_detail_view_wrap .log_list table tr.on td,
    /* .log_list table tr:active td {background:#e6f2ff;border-bottom:1px solid #dae6f2;color:#2a2a2a;} */
    /* .log_list table tr th:hover {background:#f3f9ff;border-bottom:1px solid #f3f9ff;}*/
    /* .log_list table tr th.on {background-color:#e6f2ff;} */

.device_detail_view_wrap .log_list table tr td.on {
    background-color: #e6f2ff;
}

/*.log_list table tr th input[type="checkbox"]:checked + label {background:url('..../images/img/checkbox_checked.png') no-repeat;}
.log_list table tr.on td input[type="checkbox"]:checked:hover + label {background:url('..../images/img/checkbox_checked.png') no-repeat;}*/
.device_detail_view_wrap .log_list table tr.on td input[type='checkbox'].on + label {
    background: url('../../../../images/img/checkbox_checked.png') no-repeat;
}
.device_detail_view_wrap .log_list td.thum .thumb_wrap {
    position: relative;
    width: 100px;
    height: 56px;
}

.device_detail_view_wrap .log_list td.thum .thumb_wrap .play {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 30px;
    height: 32px;
    border: 0;
    background: url('../../../../images/button/play.png') no-repeat;
}
.device_detail_view_wrap .log_list table tr th label {
    text-indent: -9999px;
}
.device_detail_view_wrap .log_list table tr th.checkall label {
    text-indent: 0px;
}
.device_detail_view_wrap .log_list table tr td label {
    text-indent: -9999px;
}
.device_detail_view_wrap .log_list table tr th.ch_label_use label {
    text-indent: 0px !important;
}
.device_detail_view_wrap .log_list table tr th > .data_name > label {
    text-indent: 0px !important;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 50px;
    text-overflow: ellipsis;
    font-size: 10px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.device_detail_view_wrap .log_list table tr th > .serviceName {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 90px;
    text-overflow: ellipsis;
}
.device_detail_view_wrap .log_list table tr td.va_t {
    vertical-align: top;
    padding-top: 13px;
}
.device_detail_view_wrap .log_list table tr th:first-child {
    padding: 7px 0 7px 11px;
}
/*.log_list table tr th {background:#fafafa;border-right:1px solid #f0f0f0;color:#797979;vertical-align:middle;padding:7px 10px 7px 10px;border-top:1px solid #e7e7e7;border-bottom:1px solid #fafafa;}*/
.device_detail_view_wrap .log_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url('../../../../images/bg/table_divider.png') no-repeat right #eeeeef;
}
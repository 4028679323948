.sync-playlists {
    width: 100%;
    height: 100%;
    oveflow-x: visible;
}

.sync-playlists-body {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.sync-playlists-groups {
}

.sync-playlists-group {
    min-width: 100%;
    width: max-content;
    height: 226px;
    border-bottom: 1px solid #ddd;
    display: inline-flex;
    flex-direction: column;
}

.sync-playlists-group-title {
    width: 100%;
    height: 38px;
    margin-top: 20px;
    display: flex;
}

.sync-playlists-group-title-group {
    font-weight: bold;
    font-size: 13px;
}

.sync-playlists-group-items {
    height: 133px;
    display: flex;
}

.sync-playlists-group-item-body {
    position: relative;
}

.sync-playlists-group-item {
    position: relative;
    float: left;
    display: inline-flex;
    flex-direction: column;
}

.sync-playlists-group-item-thumbnail {
    display: inline-flex;
    position: relative;
}

.sync-playlists-group-item-thumbnail-img {
    width: 78px;
    height: 78px;
    border: 4px solid #fff;
    box-shadow: 0px 1px 0px #e7e7e7;
}

.sync-playlists-group-item-thumbnail-drag-img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 78px;
    height: 78px;
    border: 4px solid #fff;
    box-shadow: 0px 1px 0px #e7e7e7;
}

.sync-playlists-group-item-front {
    display: inline-flex;
    flex-direction: row;
    min-width: 30px;
    height: 100%;
}

.sync-playlists-group-item-end {
    display: inline-flex;
    flex-direction: row;
    min-width: 78px;
    height: 100%;
}

.sync-playlists-group-item-next {
    min-width: 64px;
    display: inline-flex;
    flex-direction: row;
}

.sync-playlists-group-item-next-button {
    width: 7px;
    height: 12px;
    margin: 37px 25px;
    background: url(../../../images/button/date_select_pop_next.png) no-repeat;
}

.sync-playlists-group-item-box {
    width: 78px;
    height: 78px;
    border: 2px solid #ddf;
}

.sync-playlists-group-item-empty-box-selected {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 82px;
    height: 82px;
    border: 2px solid #ddf;
}

.sync-playlists-group-item-delete-button {
    position: absolute;
    top: -3px;
    left: 72px;
    width: 19px;
    height: 19px;
    background: url(../../../images/icon/dashboard_delete_normal2.png);
    cursor: pointer;
}

.sync-playlists-group-item-name {
    width: 80px;
    height: 20px;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.sync-playlists-group-item-time {
    width: 126px;
    margin-left: -10px;
}

.sync-playlists-group-item-time input[type="text"] {
    width: 13px;
    height: 22px;
    display: inline-block;
    float: left;
}

.sync-playlists-group-item-time span {
    display: inline-block;
    float: left;
}
.sync-playlists-group-item-none {
    position: relative;
    width: 78px;
    height: 78px;
    border: 4px solid #fff;
    box-shadow: 0px 1px 0px #e7e7e7;
    background: url(../../../images/img/add_img1.png) no-repeat 0 0;
    float: left;
}
.sync-playlists-group-item-none .add_lfd {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 15px;
    height: 16px;
    background: url(../../../images/img/add_img_lfd.png) no-repeat 0 0;
    border: 0;
    z-index: 1;
}
.sync-playlists-group-item-none .add_img {
    position: absolute;
    top: 8px;
    left: 32px;
    width: 15px;
    height: 16px;
    background: url(../../../images/img/add_img_image.png) no-repeat 0 0;
    border: 0;
    z-index: 1;
}
.sync-playlists-group-item-none .add_vod {
    position: absolute;
    top: 8px;
    left: 54px;
    width: 17px;
    height: 18px;
    background: url(../../../images/img/add_img_vod.png) no-repeat 0 0;
    border: 0;
    z-index: 1;
}
.sync-playlists-group-item-check-on {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 78px;
    height: 78px;
    background: url(../../../images/img/playlist_list_on.png) no-repeat;
    background-size: 78px 78px;
}
.sync-playlists-group-item-check-playtime {
    position: absolute;
    top: 56px;
    left: 24px;
    background: rgba(224,224,224,0.85);
    color: #000;
    padding: 5px;
}

.sync-playlists-playlist-name {
    color: #686b72;
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
    height: 48px;
    float: left;
    line-height: 48px;
    margin-right: 20px;
}
.content_schedule_wrap .schedule_lnb_title {
    padding-left: 25px;
    padding-right: 25px;
    display: inline-block;
    position: relative;
    border-bottom: 1px solid #e6e7e8;
    line-height: 54px;
}

.content_schedule_wrap .list_top > div {
    margin-left: 5px;
}

.content_schedule_wrap .schedule_lnb_title button:first-child {
    margin-left: 0;
}
.content_schedule_wrap .schedule_lnb_title button {
    margin-left: 15px;
}

.content_schedule_wrap .list_top {
    padding: 20px 15px;
}
.content_schedule_wrap .schedule_contents {
    display: inline-block;
    width: 100%;
}
.content_schedule_wrap .schedule_contents .thumbnail {
    float: left;
    height: 70px;
    margin: 10px;
}

.content_schedule_wrap .schedule_contents .list {
    width: 100%;
    height: 70px;
    margin: 5px;
    overflow: hidden;
}

.content_schedule_wrap .schedule_contents .list .thumbnail_box {
    text-align: center;
}

.content_schedule_wrap .schedule_contents .list .detail {
    width: 45%;
    height: 70px;
    padding: 10px;
    margin-left: 5px;
    padding: 5px 10px 10px 10px;
    color: #dedede;
}

.content_schedule_wrap .schedule_contents .list .contentName {
    text-overflow: ellipsis;
    white-space: norwrap !important;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: block;
    height: 16px;
}

.content_schedule_wrap .schedule_contents .thumbnail .thumbnail_box {
    text-align:center;
}

.content_schedule_wrap .schedule_contents .list .embedded {
    text-align: left !important;
}
.content_schedule_wrap .schedule_contents .thumbnail p {
    width: 90px;
    margin: 0 0 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #dedede;
}

.content_schedule_wrap .schedule_contents .source {
    height: 40px;
    width: 100%;
}

.content_schedule_wrap .schedule_contents .source a {
    display: inline-block;
    width: 100%;
    line-height: 39px;
    padding-left: 13px;
    color: #dedede;
}

.content_schedule_wrap .loading {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
}

.content_schedule_wrap .schedule_contents .source a {display:inline-block;width:100%;line-height:39px;padding-left:13px;color:#dedede;}
.content_schedule_wrap .schedule_contents .source a > span {width:23px;display:inline-block;background:url('../../../images/icon/input_source_2019.png') no-repeat;vertical-align:middle;margin-right:12px;}
.content_schedule_wrap .schedule_contents .source.more_list a {padding-left:23px;background:#f7f7f8}

.content_schedule_wrap .schedule_contents .source.more a {background:#f7f7f8 url('../../../images/bg/lnb_arrow_down.png') no-repeat 85% 17px;}
.content_schedule_wrap .schedule_contents .source.more.on a {background:#f7f7f8 url('../../../images/bg/lnb_arrow_up.png') no-repeat 85% 17px;color:#000;}

.content_schedule_wrap .schedule_contents .source.on a {background:#fbfbfc;color:#3196ff;}
.content_schedule_wrap .schedule_contents .source:hover a {background:#2a303b;}

.content_schedule_wrap .schedule_contents .source a > .input_icon_1 {height:15px;background-position:0 0}
.content_schedule_wrap .schedule_contents .source a > .input_icon_2 {height:14px;background-position:0 -38px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_3 {height:14px;background-position:0 -77px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_4 {height:14px;background-position:0 -116px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_5 {height:14px;background-position:0 -155px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_6 {height:14px;background-position:0 -194px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_7 {height:14px;background-position:0 -233px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_8 {height:14px;background-position:0 -272px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_9 {height:7px;background-position:0 -316px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_10 {height:12px;background-position:0 -385px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_11 {height:15px;background-position:0 -422px }
.content_schedule_wrap .schedule_contents .source a > .input_icon_12 {height:14px;background-position:0 -461px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_13 {height:15px;background-position:0 -500px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_14 {height:21px;background-position:0 -536px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_15 {height:18px;background-position:0 -577px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_16 {height:17px;background-position:0 -615px}
.content_schedule_wrap .schedule_contents .source a > .input_icon_17 {height:17px;background-position:0 -652px}
.content_schedule_wrap .schedule_contents .source a > .more_btn {height:3px;background-position:0 -356px}

/* scroll */
/*
 * Container style
 */
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    height: 15px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 15px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
    z-index: 1;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 6px;
    /* there must be 'right' for ps__thumb-y */
    right: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: #999;
    height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #999;
    width: 11px;
    z-index: 1;
}



/* MS supports */
@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.content_schedule_wrap .schedule_lnb_title > div {
    margin-right: 10px;
}


.content_schedule_wrap .list_top {
    padding: 20px 15px;
}
.content_schedule_wrap .list_top > div {
    margin-right: 8px;
}
.content_schedule_wrap .schedule_lnb_title .option_list {
    margin-top:10px;
}
.upload_ftp_content_popup_wrap .ui-dialog-content {
    overflow-y: inherit !important;
    overflow-x:unset !important;
}

.upload_ftp_content_popup {
    padding: 0 25px;
    position: relative;
}

.upload_ftp_content_popup .pt6{
    padding-top: 6px;
}

.upload_ftp_content_popup .pt17{
    padding-top: 17px;
}

.upload_ftp_content_popup .pt25{
    padding-top: 25px;
}

.upload_ftp_content_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.upload_ftp_content_popup table th{
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.upload_ftp_content_popup table td{
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.upload_ftp_content_popup .space {
    line-height: 28px;
    display: block;
}

.upload_ftp_content_popup .port_td {
    display: flex;
}

.upload_ftp_content_popup .port_td span{
    padding: 6px 0 5px 0;
    vertical-align: middle;
}

.upload_ftp_content_popup .warring_text {
    color:red;
}

.notice_area .notice_container .row .import{
    width: 25px;
    height: 25px;
    background: url(../../../images/icon/ic_noti_import.svg) no-repeat;
    background-size: 25px 25px;
    border: 0;
    outline: 0;
    line-height: 30px;
    margin-left:5px;
    display: inline-block;
    margin-top:5px;
}

.notice_area .notice_container .row .new{
    width: 25px;
    height: 25px;
    background: url(../../../images/icon/ic_noti_new.svg) no-repeat;
    background-size: 25px 25px;
    border: 0;
    outline: 0;
    line-height: 35px;
    margin-left:5px;
    display: inline-block;
    margin-top: 3px;
}

.notice_area .notice_container .row .arrow {
    display: inline-block;
    width: 10px;
    height: 20px;
    background: url('../../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
    margin-top:5px;
    margin-right: 5px;
}

.notice_area .notice_container .row .arrow.up {
    display: inline-block;
    width: 10px;
    height: 20px;
    background: url('../../../images/button/select_up.png') no-repeat 0 10px;
    margin-left: 10px;
    margin-top:5px;
    margin-right: 5px;
}

.notice_area .notice_container .notices {
    background-color: #fff;
    border: 1px solid #cccccc;
    height: 300px;
    overflow-y : auto;
}
.notice_area .notice_container .notices .border {
    width: 100%;
    border-bottom: 1px solid #cccccc;
}
.notice_area .notice_container .notices .content {
    padding-left: 30px;
    padding-right: 30px;
    white-space: pre-wrap;
    margin-top: -30px;
}

.notice_area .notice_container .row .close {
    background: url('../../../images/button/popup_btn_circle_close_normal.png') no-repeat;
    cursor: pointer;
    display: inline-block;
    width: 20px;
    margin-left: 10px;
    margin-top:5px;
    margin-right: 5px;
}
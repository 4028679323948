.upload_web_url_content_popup_wrap .ui-dialog-content {
    overflow-y: inherit !important;
    overflow-x:unset !important;
}

.upload_web_url_content_popup {
    padding: 0 25px;
    position: relative;
}

.upload_web_url_content_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.upload_web_url_content_popup table th{
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.upload_web_url_content_popup table td{
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.upload_web_content_popup .refresh_interval {

}

.upload_web_url_content_popup span img{
    margin-top: 5px;
}

.upload_web_url_content_popup .option_list_wrap{
    position: relative;
    display: inline-block;
}

.upload_web_url_content_popup .option_list{
    width: 100%;
    max-height: 200px;
    /*display: inline-block;*/
    position: absolute;
    top: 37px;
    left: 0;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
}

.upload_web_url_content_popup .option_list > div{
    position:relative;
}

.upload_web_url_content_popup .option_list > div > span{
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background: url('../../../images/bg/option_list_bg.png') no-repeat 10px 0;
}

.upload_web_url_content_popup .option_list .taglist_dropdown{
    max-height: 200px;
    overflow-y: auto;
}

.upload_web_url_content_popup .option_list > div > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
    color: #707070;
    text-decoration: none;
    width: 95%;
    font-size: 12px;
    vertical-align: middle;
}

.upload_web_url_content_popup .option_list > div > ul > li:first-child {
    padding-top: 11px;
}
.upload_web_url_content_popup .option_list > div > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}

.upload_web_url_content_popup .option_list > div > ul > li.on > {
    color: #3196ff !important;
}
.upload_web_url_content_popup .option_list > div > ul > li:hover {
    color: #609be8 !important;
    cursor: pointer;
}
.upload_web_url_content_popup .option_list > div > ul > li:active {
    color: #2789f4 !important;
}

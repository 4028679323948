.content_list_view .content_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0px !important;
    /*max-height: 71px;*/
}

.thumbnailview_wrap {
    width: 100%;
    padding-left: 25px
}

.image_view_wrap .thumbnailview_wrap ul{
    /*margin-bottom: 30px;*/
}

.image_view_wrap .check_all {
    height: 30px;
}

.content_export_button {
    margin-left: 10px;
}

.content_export_button > button{
    border: 1px solid #5e5e5e !important;
    color: #363636 !important;
    background: #fff !important;
    min-width: 76px;
}

.content_export_button > button > span{
    width: 100%;
}
.content_export_button > button > span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url(../../images/button/select_down.png) no-repeat 0 10px;
    margin-left: 10px;
}

.content_export_button > button:hover {
    cursor: pointer !important;
    border: solid 1px #e7eaf0 !important;
    background: #e7eaf0 !important;
    color: #000000 !important;
}

.content_export_button > button:disabled {
    cursor: inherit !important;
    border-color: #c2c2c2 !important;
    color: #d0d0d0 !important;
    background: #fff !important;
}

.content_export_button ul {
    width: 76px !important;
}

.no_data_wrap {
    width: 100%;
    text-align: center;
    margin-top: 20px !important;
}

.no_data_wrap > div > img{
    margin-bottom: 41px;
}

.no_data_wrap > div > span{
    font-size: 15px;
    color: #cbcbcb;
    line-height: 20px;
}

.no_data_wrap .empty_title{
    font-size: 26px;
    color: #d9e0e7;
    display: block;
    margin-bottom: 17px;
}
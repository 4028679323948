

.playlistNotDeletePopup_contents {padding:0 25px;}

.playlistNotDeletePopup_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.playlistNotDeletePopup_tr_group .rt-tr {
    /*max-height: 41px;*/
    min-height: 41px;
    /*height: 41px;*/
}

.playlistNotDeletePopup_message > p {
    color:#999999;
    font-size: 11px;
    margin-bottom: 11px !important;
    margin-top: 0px;
}


.playlistNotDeletePopup_tr_group .rt-td:first-child div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dm_area {
    margin-top: 20px;
    padding-left:10px;
    padding-right: 10px;
}

.dm_area .area_title {
    font-weight: bold;
    font-size: 13px;
    color: #363636;
    letter-spacing: 0;
    height: 30px;
}

.dm_area .dm_container {
    background: #FFFFFF;
    border: 1px solid #BDC2C8;
    border-radius: 8px;
    width: 49.6%;
    height: 630px;
}

.dm_area .dm_container .area_center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dm_area .dm_container .no_data {
    font-size: 15px;
    color: #241f20;
    letter-spacing: 0;
}

.dm_area .resource_status {
    border-radius: 6px 0 0 6px;
    margin: 5px;
    width: 100%;
    height: 60px;
}

.dm_area .resource_status .resource_title {
    float: left;
    width: 160px;
    padding: 10px;
    margin-left: 20px;
    lineHeight: 25px;
    textAlign: left;
    color: #fff;
    font-size: 16px;
    margin-top: 15px;
}

.dm_area .resource_status .resource_info {
    float: right;
    textAlign: right;
    justify-content: center;
    alignItems: center;
    display: flex;
    color: #ffffff;
    font-size: 42px;
    line-height: 55px;
}

.dm_area .dm_container .device_status_box .resource_status:first-child{
    border-radius: 6px 0 0 6px;
}

.dm_area .dm_container .device_status_box .resource_status:last-child{
    border-radius: 0 6px 6px 0;
}
.save_content_schedule_wrap .selectedDeviceDelBtn {
    height: 19px;
    width: 19px;
    background: url('../../../../images/img/tab_close_normal.png') no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}

.save_content_schedule_wrap .detail_btn {
    border: 0;
    background: none;
    color: #8d8d8d;
    line-height: 28px;
    padding: 0;
    text-align: left;
}

.save_content_schedule_wrap .detail_btn > span {
    width: 7px;
    height: 5px;
    display: inline-block;
    background: url('../../../../images/button/select_down.png') no-repeat;
    vertical-align: middle;
    margin-right: 9px;

}

.save_content_schedule_wrap .detail_view {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 470px;
}
.edit-notice-popup {
    width: 648px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
}

.edit-notice-popup-title {
    height: 29px;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    line-height: 24px;
}

.edit-notice-popup-title .title {
    width: 102px;
    height: 27px;
    color: #8d8d8d;
    font-size: 12px;
    line-height: 27px;
}

.edit-notice-popup-title > input {
    height: 27px !important;
}

.edit-notice-popup-period {
    height: 31px;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    line-height: 24px;
}

.edit-notice-popup-period .title {
    width: 102px;
    height: 27px;
    color: #8d8d8d;
    font-size: 12px;
    line-height: 27px;
}

.edit-notice-popup-period .date {
    width: 130px;
    height: 27px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.edit-notice-popup-content {
    height: 352px;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    line-height: 24px;
}

.edit-notice-popup-content .title {
    width: 102px;
    height: 27px;
    color: #8d8d8d;
    font-size: 12px;
    line-height: 27px;
}

.edit-notice-view-popup {
    width: 590px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    border-bottom: 1px solid #f5f5f5;
}


.submenu_event_schedule_container .lnb_buttonwrap {
    min-height: 48px;
    padding-top: 22px;
    padding-left: 20px !important;
    display: inline-block;
}

.submenu_event_schedule_container ul > ul > li {
    line-height: 41px;
    width: 100%;
}
.submenu_event_schedule_container ul li {
    position: relative;
}

.submenu_event_schedule_container ul > li > a {
    box-sizing: border-box;
    padding-left: 28px;
    font-size: 14px;
    color: #dedede;
    background: none;
    background-image: none;
    width: 100%;
}

.submenu_event_schedule_container ul > li {
    line-height: 41px;
    width: 100%;
}

.submenu_event_schedule_container .message_closebox {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    width: 19px;
    height: 19px;
    background: url('../../../images/img/list_close.png') no-repeat;
    border: 0;
    margin: 0;
}

.submenu_event_schedule_container ul > li.on > a{
    color: #3196ff;
}

.pop-file-history {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.pop-file-history-top {
    display: inline-flex;
    flex-direction: column;
    margin: 20px;
}
.pop-file-history-export {
    margin-left: 10px;
}
.pop-file-history-period {
    display: inline-flex;
    flex-direction: row;
    height: 28px;
    line-height: 28px;
    margin-top: 13px;
}
.pop-file-history-period-title {
    width: 94px;
    padding-left: 15px;
    font-size: 12px;
    color: #8d8d8d;
    font-weight: normal;
}
.pop-file-history-chart {
    width: 1400px;
    display: inline-flex;
    flex-direction: column;
    margin: auto 32px;
}
.pop-file-history-chart-guide {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 3px;
    margin-left: auto;
    margin-right: 0;
}
.pop-file-history-chart-guide-color-index {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-top: 5px;
}
.pop-file-history-chart-guide-title-index {
    margin-left: 4px;
    font-size: 12px;
    color: #818181;
}
.pop-file-history-chart-bar {
    width: 1400px;
    height: 278px;
    position: relative;
}
.pop-file-history-chart-bar .pop-file-history-date {
    text-align: right;
}
.pop-file-history-table {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: auto;
}

.pop-file-history-table-date {
    font-size: 18pt;
    color: #3196ff;
    opacity: 0.9;
    margin: 20px 38px;
}
.pop-file-history-table-body {
    display: inline-flex;
    flex-direction: row;
    margin: 0px 46px 46px 46px;
}
.pop-file-history-table-list {
    width: 669px;
    display: inline-flex;
    flex-direction: column;
}
.pop-file-history-table-list-column-data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pop-file-history-table-list .pop-file-history-tbody-style {
    overflow-x: hidden;
}
.pop-file-history-table-list .pop-file-history-tr-style {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 33px;
    padding: 0px !important;
    height: 33px !important;
}
.ReactTable .rt-tbody .pop-file-history-tr-style:last-child {
    border-bottom: 1px solid #f2f2f2;
}
.pop-file-history-table-list .pop-file-history-tr-style {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-right: none !important;
}

.pop-file-history-table-list-pagination-wrap {
    border-top: 1px solid #d6d7d8;
    padding: 10px 0;
    height: 30px;
    background-color: #ECECEC;
    bottom: 0px;
    background: #fff;
    width: 100%;
    text-align: center;
}
.pop-file-history-table-list-pagination-info {
    margin: 10px;
    float: left;
}

.pop-file-history-table-list-pagination-size {
    margin: 10px;
    display: flex;
    position: relative;
}
.pop-file-history-table-list-received-count {
    position: absolute;
    right: 0px;
    font-size: 10pt;
    color: #363636;
}
.pop-file-history-table-list-pagination-pages {
    display: inline-block;
    padding-left: 0;
    margin: 5px 0;
    border-radius: 4px;
}

.pop-file-history-table-list-pagination-pages > li {
    position: relative;
    float: left;
    padding: 2px 6px;
    line-height: 1.42857143;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #d6d7d8;
    margin-left: -1px;
    cursor: pointer;
}

.pop-file-history-table-list-pagination-pages > li.active {
    z-index: 2;
    color: #ffffff;
    background-color: #3196ff;
    border: 1px solid #d6d7d8;
    cursor: default;
}
.advertisement_manager_wrap .sc_menu .sc_header_scroll{
    position: absolute;
    left: 0;
    top: 0;
}

.advertisement_manager_wrap > #leftButtonList > div > button {
    margin-right: 10px;
}

.advertisement_manager_wrap .sc_menu .sc_header_cell {
    text-align: center;
}

.advertisement_manager_wrap .calendar_arrow.prev {
    top: 5px;
    left: 4px;
}
.advertisement_manager_wrap .calendar_arrow.next {
    top:5px;
    margin-left: 10px;
}
.advertisement_manager_wrap .calendar_arrow {
    width: 18px;
    height: 18px;
    border: 0;
    border-radius: 9px;
    position: absolute;
    padding: 0;
    background: #f4f6f8;
}
.advertisement_manager_wrap .calendar_arrow:hover {background:#fafafb}
.advertisement_manager_wrap .calendar_arrow:active {background:#e7e7e7}
.advertisement_manager_wrap .calendar_arrow.prev > span {
    background: url('../../../images/button/date_select_prev.png') no-repeat 6px 4px;
}
.advertisement_manager_wrap .calendar_arrow > span {
    width: 18px;
    height: 18px;
    display: inline-block;
}

.advertisement_manager_wrap .calendar_arrow.next > span {
    background: url('../../../images/button/date_select_next.png') no-repeat 8px 4px;
}

.advertisement_manager_wrap .sc_header_scroll .sc_time > span > span.today {
    background: #00ddca;
    color: #fff;
}

.advertisement_manager_wrap .advertisement_schedule_wrap  .sc_wrapper {
    display: flex;
    position: absolute;
    overflow-y: auto;
}

.advertisement_manager_wrap .sc_main_scroll .sc_main .tl.today {
    background: #fcf8e3;
}

.advertisement_manager_wrap .sc_main_scroll .sc_main .tl.day {
    border-left: 1px solid #ccc;
}

.advertisement_manager_wrap .sc_main_scroll .sc_main .tl {
    float: left;
    height: 100%;
    border-left: dotted 1px #ccc;
    fort-size: 6px;
    color: #eee;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.advertisement_manager_wrap .sc_main .timeLine:first-child {
    border-bottom: 0;
}

.advertisement_manager_wrap .sc_main .timeLine:last-child {
    border-bottom: 1px solid #ccc;
}

.advertisement_manager_wrap .sc_data .timeLine span {
    padding: 10px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.adScheduleWrap .sc_main_scroll .sc_main .tl.day {
    border-left: 1px solid #ccc;
}

.advertisement_manager_wrap .sc_menu .sc_header {
    float: left;
    height: 26px;
    position: relative;
    overflow: hidden;
    border: 1px solid
    #fff;
}

.advertisement_manager_wrap .sc_menu .sc_header_cell, .advertisement_manager_wrap .sc_header .sc_time, .advertisement_manager_wrap .sc_main_scroll .sc_time {
    color: #FFFFFF;
    padding: 4px 0;
    line-height: 18px;
    height: 18px;
    display: block;
}

.advertisement_manager_wrap .adScheduleTitle {
    position: relative;
    width: 100%;
    height: 56px;
    border-bottom: 1px solid
    #eeeff0;
}

.advertisement_manager_wrap .select_date {
    position: absolute;
    top: 14px;
    left: 19px;
}

.advertisement_manager_wrap .select_date > .todaybtn > span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: #00ddca;
    margin-right: 3px;
    vertical-align: 1px;
}

.advertisement_manager_wrap .select_date > .todaybtn:hover {
    border: 1px solid #b4b9c3;
    background: #fff;
}

.advertisement_manager_wrap .schedule_tabwrap {
    width: 100%;
    padding: 0 auto;
}

.advertisement_manager_wrap .schedule_tabwrap > ul {
    width: 260px;
    margin: 0 auto;
}

.advertisement_manager_wrap .schedule_tabwrap > ul > li {
    float: left;
    margin-right: 30px;
    line-height: 55px;
}

.advertisement_manager_wrap .schedule_tabwrap > ul > li.on > a {
    color: #000;
    background: url('../../../images/bg/datetab_bg_selected.png') no-repeat center bottom;
}

.advertisement_manager_wrap .schedule_tabwrap > ul > li:hover > a {
    color: #000;
    background: url('../../../images/bg/datetab_bg.png') no-repeat center bottom;
}
.advertisement_manager_wrap .schedule_tabwrap > ul > li > a {
    display: inline-block;
    width: 100%;
    color: #ababac;
    font-size: 13px;
    color: #686b72;
}
.advertisement_manager_wrap .select_date > .todaybtn {
    width: 70px;
    color: #848484;
    background: #fff;
    line-height: 26px;
    border: 1px solid #f9fafb;
    line-height: 24px;
    border-radius: 15px;
}

.advertisement_manager_wrap .select_date > .date {
    background:
            #fff;
    line-height: 28px;
    padding: 0 35px;
    border-radius: 15px;
    position: relative;
}

.advertisement_manager_wrap .sc_header_scroll {
    display: flex;
}


.advertisement_manager_wrap .sc_header_scroll .sc_time > span > span {
    display: inline-block;
    width: 21px;
    height: 21px;
    background:
            #f9fafb;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
    color:
            #515151;
}

.advertisement_manager_wrap .sc_menu .sc_header .sc_time {
    text-align: left;
    color: #818181;
}

.advertisement_manager_wrap .sc_menu .sc_header_cell, .advertisement_manager_wrap .sc_header .sc_time, .advertisement_manager_wrap .sc_main_scroll .sc_time {
    color: #FFFFFF;
    line-height: 18px;
}

.sc_header_scroll .sc_time > span {
    width: 96%;
    text-align: left;
}
.advertisement_manager_wrap .sc_menu .sc_header .sc_time {
    text-align: left;
    color:
            #818181;
}

.advertisement_manager_wrap .sc_data .timeLine {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    width: 100%;
    border-right: 1px solid
    #ddd;
}

.advertisement_manager_wrap .timeLine, .adScheduleWrap .sc_main .tb {
    display: inline-block;
}

.advertisement_manager_wrap .sc_menu .sc_header_cell, .advertisement_manager_wrap .sc_header .sc_time, .advertisement_manager_wrap .sc_main_scroll .sc_time {
    color: #FFFFFF;
    padding: 4px 0;
    line-height: 18px;
    height: 18px;
    display: block;
}

.advertisement_manager_wrap .sc_menu .sc_header_cell {
    float: left;
    font-weight: bold;
    color: #818181;
    position: relative;
}

.advertisement_manager_wrap .sc_data {
    /*border-right: 1px solid #ddd;*/
}

.advertisement_manager_wrap .sc_menu {
    width: 100%;
    height: 26px;
}

.advertisement_manager_wrap .sc_main_box {
    position: relative;
    float: left;
    /*overflow-x: auto;
    overflow-y: auto;*/
}

.advertisement_manager_wrap .sc_main {
    position: relative;
}

.advertisement_manager_wrap .sc_main .timeLine {
    border-top: 1px solid #ccc;
    border-bottom: 0;
    border-right: 2px solid #ccc;
}

.advertisement_manager_wrap .sc_main_scroll .sc_main .tl {
    float: left;
    height: 100%;
    border-left: dotted 1px
    #ccc;
    fort-size: 6px;
    color:
            #eee;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.advertisement_manager_wrap .schedule_overlay{
    position: absolute;display:inline-block;
    top:0;left:0;
}

.advertisement_manager_wrap .schedule_overlay .overlayBar{
    position:absolute;
    display: block; /* make the <a> tag block */
    font-size: .85em;
    line-height: 1.3;
    border-radius: 3px;
    border: 1px solid #3a87ad; /* default BORDER color */
    background-color: #3a87ad; /* default BACKGROUND color */
    font-weight: normal; /* undo jqui's ui-widget-header bold */
}

.advertisement_manager_wrap .overlayBar > span {
    font-size: 1.2em;
    padding: 0 1px;
    white-space: nowrap;
    text-align:left;
    color: #fff;
}

.advertisement_manager_wrap .sc_Bar {
    overflow: hidden;
    position: absolute;
    background: #4f93d6;
    cursor: pointer;
    display: block;
    box-sizing: content-box;
    z-index: 10;
    box-shadow: 2px 2px 4px #333;
    -moz-box-shadow: 2px 2px 4px #333;
    -webkit-box-shadow: 2px 2px 4px
    #333;
}

.advertisement_manager_wrap .sc_Bar .head {
    display: block;
    padding: 6px 8px 0;
    font-size: 12px;
    height: 16px;
    overflow: hidden;
}

.advertisement_manager_wrap .sc_Bar span {
    color: #fff;
}

.advertisement_manager_wrap .adTime {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
}

.advertisement_manager_wrap .sc_Bar .text {
    display: block;
    padding: 5px 15px 0;
    font-weight: bold;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
}

.advertisement_manager_wrap .sc_Bar .thumbnail {
    width: auto;
    height: 39px;
    text-align: center;
    margin-top: 10px;
    white-space: normal;
    box-sizing: content-box;
    overflow: hidden;
}

.advertisement_manager_wrap .sc_Bar .date {
    display: block;
    text-align: right;
    width: 200px;
    overflow: hidden;
    line-height: 15px;
    margin-right: 20px;
    float: right;
    color: #fff;
    font-size: 12px;
}


.advertisement_manager_wrap .react-resizable-handle {
    position: absolute;
    right: 0px;
    background: url('../../../images/button/editor_button.png') no-repeat;
    top: 40%;
    width: 10px;
    height: 40px;
    margin-top:0px;
    transform: rotate(0);
}
.advertisement_manager_wrap .sc_Bar .resizeIcon {
    position: absolute;
    right: 0px;
    background: url('../../../images/button/editor_button.png') no-repeat;
    top: 40%;
    width: 10px;
    height: 40px;
}

.advertisement_manager_wrap .sc_Bar .resizeIcon:hover {
    cursor: e-resize;
}

.advertisement_manager_wrap .sc_menu .sc_header_cell .adOption {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('../../../images/icon/circle_setting.png') no-repeat center 2px !important;
    border: 1px solid #f9fafb;
    border-radius: 15px;
    cursor: pointer;
}

.slot_setting_popup .slot_setting_slots > li {
    padding: 6px 10px 6px 16px;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: left;
    line-height: 20px;
    position: relative;
    color: #797979;
    height: 30px;
}

.slot_setting_popup .slot_setting_slots > li:last-child {
    border-bottom: 0;
}

.slot_setting_popup .slot_setting_slots > li:first-child {
    padding: 6px 10px 6px 16px;
}

.slot_setting_popup .slot_setting_slots > li:hover {
    background:#eff0f1;
}

.slot_setting_popup .slot_setting_slots > li:hover .delete{
    display: block;
}

.slot_setting_popup .slot_setting_slots > li > .delete {
    border:0;
    float:right;
    width:19px;
    height:19px;
    background : url('../../../images/icon/dashboard_delete_normal.png');
    cursor:pointer;
    margin-top: -20px;
}



.slot_setting_popup .slot_setting_slots .delete {
    display: none;
}


.slot_setting_popup .sortableHelper {
    z-index: 999999;
}

.advertisement_manager_wrap .sc_Bar .thumbnail > span {
    margin-right: 10px;
}
.advertisement_manager_wrap .sc_Bar .thumbnail {
    width: auto;
    height: 39px;
    text-align: center;
    margin-top: 10px;
    white-space: normal;
    box-sizing: content-box;
    overflow: hidden;
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
}

.advertisement_manager_wrap .sc_Bar .thumbnail img, .advertisement_manager_wrap .sc_Bar .thumbnail img:after, .advertisement_manager_wrap .sc_Bar .thumbnail img:before  {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.ruletree-label[type="start"] {
	border: 4px solid #18336A;
    border-radius: 43px;
	background: #18336A;
}

.ruletree-label[type="start"] > div {
	color: #ffffff;
	font-family: Tahoma;
	font-size: 15px;
	font-weight: bold;
	position: relative;
	top: 50%; 
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	margin-right: 0px;
}

.ruletree-start-label {
	width: 85px;
	height: 85px;
	text-align: center;
}
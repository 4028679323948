
.upload_content_popup_wrap {
    padding: 0;
    z-index: 999;
}

.upload_content_popup {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 3px;
    background: rgba(0,0,0,0.15);
    padding-bottom: 2px;
    position: absolute;
    box-shadow: 0 10px 13px
    #888888;
}

.upload_content_popup h1 {
    text-overflow: ellipsis;
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    margin: 0;
    font-size: 18px;
    color: #797979;
    font-weight: normal;
    padding-left: 25px;
}

.upload_content_popup .popup_header {
    position: relative;
    border-bottom: 1px solid #ebebeb;
    line-height: 56px;
}

.upload_content_popup .popup_header div {
    position: absolute;
    top: 9px;
    right: 13px;
}

.upload_content_popup > div{
    width: 782px;
    background: #fff;
}

.upload_content_popup .pop_circle{
    width: 27px;
    height: 27px;
    border: 0;
    padding: 0;
    border-radius: 15px;
    cursor: pointer;
    background: none;
}

.upload_content_popup .uploading {
    position: relative;
    width: 100%;
    height: 19px;
    margin-top: 49px;
}

.upload_content_popup .popup_btn_wrap.noline {
    border-top: 0px;
}
.upload_content_popup .popup_btn_wrap {
    border-top: 1px solid #f5f5f5;
    margin: 0 25px;
    padding: 20px 0;
    text-align: center;
    line-height: 16px;
}

.upload_content_popup .popup_btn_wrap button{
    min-width: 102px;
}

.upload_content_popup .detail_btn {
    border: 0;
    background: none;
    color: #8d8d8d;
    line-height: 28px;
    padding: 0;
    text-align: left;
}

.upload_content_popup .detail_btn span{
    width:7px;
    height:5px;
    display:inline-block;
    background:url('../../../images/button/select_down.png') no-repeat;
    vertical-align:middle;
    margin-right:9px;
}

.upload_content_popup .detail_btn.on span{
    background: url('../../../images/button/select_up.png') no-repeat
}

.upload_content_popup .pop_list.content_upload th:first-child {
    padding-left: 16px;
}
.upload_content_popup .pop_list table tr th:first-child {
    padding: 7px 0 7px 11px;
    padding-left: 11px;
}
.upload_content_popup .pop_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url('../../../images/bg/table_divider.png') no-repeat right #eeeeef;
}
.upload_content_popup table {
    border-collapse: collapse;
    border-spacing: 0;
}

.upload_content_popup .popup_wrap.type2 > div {
    width: 782px;
}
.upload_content_popup .popup_wrap > div {
    background:
            #fff;
    border-radius: 2px;
}
.upload_content_popup .list_wrap_empty {
    width: 100%;
    text-align: center;
}

.upload_content_popup .span_button {
    height:28px;
    line-height:28px;
    padding: 7px 15px;
    position: relative
}

.upload_content_popup .file_total {
    position: absolute;
    right: 5px;
    top: -25px;
    font-size: 12px;
    color: #848484;
}
.upload_content_popup .upload_content_list_view .content_tr_group{
    border-bottom: 1px solid #f2f2f2 !important;
    height: 38px;
}

.upload_content_popup .ReactTable .rt-tbody .rt-tr-group{
    padding: 0px 10px 0px 10px;
}

.upload_content_popup .popup_contents {
    padding: 0 25px;
}

.progressBarWrap{
	position:absolute;
    width: 328px;
   	height: auto;
	bottom:20px;
	right:50px;
	text-align:left;
    z-index:999;
}

.progressBar{
	position:relative;
    width: 328px;
   	height: 39px;
	margin-bottom:5px;
	background : #e8e8e8;
	border:1px solid #c5c5c5;
}

.progressBar > .progressBarText{
 	float:left;
    width:260px;
    margin-top:3px;
    margin-left:13px;
    color:#8c8c8c;
    font-size: 14px;
    line-height: 16px;
    text-decoration:none;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
}

.progressBar .progress_bar{
	position:static !important;
}

.upload_content_popup .popup_btn_wrap .disabled,
.upload_content_popup .popup_btn_wrap input:disabled,
.upload_content_popup .popup_btn_wrap button:disabled {
    color: #c1c1c1;
    border-color: #f3f3f3;
    background: #f3f3f3;
    cursor: default !important;
}

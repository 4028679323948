.vwl_layout_open_wrap .mis_dialog_body_wrap {
    overflow-y: auto;
}
.layout_icon_wrap {
    width: 90px;
    height: 100px;
    padding-left: 5px;
    padding-right: 5px;
    display: inherit;
    position: relative;
}

.layout_check_bg {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    background: url("../../../../images/bg/thumb_layer_bg.png") no-repeat;
    text-align: center;
    vertical-align: middle;
}

.layout_check {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    background: url("../../../../images/img/thumb_check.png");
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 8px;
    text-align: center;
    vertical-align: middle;
}

.layout_mouse_over {
    width: 100px;
    height: 100px;
    position: absolute;
    background: url("../../../../images/bg/thumb_layer_bg.png") no-repeat;
    font-size: 8px;
    text-align: center;
    vertical-align: middle;
}
.layout_mouse_over_type {
    font-weight: bold;
    color: #000;
}

.layout_mouse_over_name {
    color: #7f7f7f;
}

.layout_content {
    display: contents;
}

.layout_icon {
    display: inline-flex;
    position: inherit;
    justify-self: center;
    width: 65px;
    height: 63px;
    background: url("../../../../images/icon/icon_Videowall.png") no-repeat;
}

.layout_icon_title {
    display: inline-flex;
    position: inherit;
    top: 70px;
    font-size: 10px;
    color: #333;
    text-align: center;
}


.ui-dialog-overlay {
    background: #aaaaaa;
    opacity: .3;
    filter: Alpha(Opacity=30);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*z-index: 2000;*/
}

.ui-dialog-overlay-with-draggable {
    opacity: .3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.group_popup .ui-dialog-overlay {
    opacity: .003;
    z-index: 2001;
}

.tag_popup .ui-dialog-overlay {
    z-index: 2001;
}

.ui-dialog {
    position: fixed;
    /*top: 50%;
    left: 50%;*/
    outline: 0 none;
    padding: 0 !important;;
    z-index: 103;
    background-color: white;
    border: 1px solid #c5c5c5;

    /*margin-right: -50%;*/
    /*transform: translate(-50%,-50%);*/
}

.ui-dialog.maximized{
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.ui-dialog.minimized{
    position: fixed;
    cursor: move;
}

.ui-dialog .ui-dialog-titlebar {
    position: relative;
    font-size: 1em;
    border-radius: 3px;
    padding: 0.5em;
    height: 35px;
    border-bottom: 1px solid #f6f6f6;
}

.ui-dialog.react-draggable .ui-dialog-titlebar {
    cursor: move;
}

.ui-dialog .ui-dialog-titlebar .action-items {
    float: right;
    position: relative;
}

.ui-dialog .ui-dialog-titlebar .title {
    float: left;
    margin-right: .5em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    height: 22px;
    padding-left: 25px;
    padding-top: 10px;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.ui-dialog .ui-dialog-titlebar .title_noellipsis {
    float: left;
    margin-right: .5em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    height: 22px;
    padding-left: 25px;
    padding-top: 10px;
}

.mis_dialog_body_wrap {
    padding:20px;
}

.mis_dialog_buttons_wrap {
    border-top: 1px solid #e8e8e8;
    padding-top:10px;
    padding-left:20px;
    padding-right:20px;
}

.mis_dialog_buttons_wrap > div > button:first-child{
    margin:0px
}
.mis_dialog_buttons_wrap > div > button{
    margin-left: 20px;
}

.ui-dialog-content {
    height: 100%;
    overflow: hidden !important;
}


.ui-dialog .ui-dialog-content {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    overflow: hidden;
    position: relative;
    padding: 0.5em;
    display: inline;
}

.ui-dialog .ui-dialog-buttonpane {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: right;
    border-width: 1px 0 0 0;
    border-top: 1px solid #f6f6f6;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset{
    padding: 0.5em;
}

.ui-dialog .ui-dialog-buttonpane button {
    margin: 0 .5em 0 .5em;
    cursor: pointer;
    background-color: #f6f6f6;
    padding: 0.5em 1em;
    outline: none;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
}

.ui-dialog .ui-dialog-buttonpane button:hover{
    background-color: #CCCCCC;
    border: 1px solid #BBBBBB;
}

.ui-dialog .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.playlistDetailView .detail_view table td {
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
}

.detail_view .icon {
    height: 24px;
    /*width: 100px;*/
    display: block;
    float: left;
    margin: 5px;
    cursor: pointer;
    background-size: cover;

}

.popupTh {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
    white-space: pre-wrap;
    display: block;
}
.detail_view table th {
    color: #8d8d8d;
}

.detail_view {
    position: relative;
    padding-top: 15px !important;
}
.detail_view caption, th, td {
    text-align: left;
    vertical-align: top;
    font-weight: normal;
    white-space: nowrap;
}
.detail_view table {
    width: 100%;
}
.detail_view table th {
    vertical-align: middle;
    color:
            #8d8d8d;
    padding-right: 10px;
}
.detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}
.detail_view table td label {
    color: #474747;
    display: inline-block;
}
.detail_view input[type="checkbox"] + label {
    display: inline-block;
    padding-left: 24px;
    color: #59616b;
    background: url('../../images/img/checkbox_normal.png') no-repeat;
    padding-bottom: 2px;
    cursor: pointer;
}

.detail_view .input_date {
    position: relative;
    width: 80px;
    display: inline;
}


.detail_view .scheduleTime {
    width: 100px;
}


.popup_btn_wrap {
    border-top: 0px;
    margin: 0 25px;
    padding: 20px 0;
    text-align: center;
    height: 20px;
}

.mis_dialog .ui-dialog.minimized {
    width: 300px !important;
    height: 50px !important;
}

.mis_dialog .ui-dialog.minimized .icon.icon-restore {
    background: url('../../images/button/popup_btn_circle_show_normal.png');
}

.ui-dialog.minimized .ui-dialog-titlebar .title {
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

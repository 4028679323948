.detail-report-top {
    height: 28px;
    line-height: 28px;
    padding: 40px;
    display: inline-flex;
    flex-direction: row;
}

.detail-report-top-title {
    font-size: 12px;
    color: #8d8d8d;
    font-weight: normal;
    margin-right: 8px;
}

.detail-report-top-type {
    width: 41px;
    margin-left: 29px;
    margin-right: 8px;
}

.trigger_list_view-month {
    display: inline-flex;
}

.trigger_list_view-month-text {
    width: 40px;
    line-height: 28px;
}

.trigger_list_view .trigger_table {
    margin-bottom: 50px;
}

.trigger_list_view .trigger_tr_group {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 40px;
    padding: 0px !important;
    height: 40px !important;
    cursor: default !important;
}

.trigger_list_view .trigger_group_selected {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 41px;
    padding: 0px !important;
    height: 40px !important;
    background: #e6f2ff;
    cursor: pointer;
}

.ReactTable .rt-tbody .trigger_tr_group:last-child {
    border-bottom: 1px solid #f2f2f2;
}

.trigger_list_view .trigger_td_props {
    padding-left: 20px !important;
    padding-right: 10px !important;
    border-right: none !important;
    justify-content: left !important;
    flex-direction: row !important;
}
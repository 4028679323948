.image_table_header_wrapper table {
    border-color: grey;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 34px;
    position: relative;
}

.image_table_header_wrapper table th {
    border: 0;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    background: #eeeeef;
    position: relative;
    /* width: 100%; */
    /* display: table-header-group; */
    vertical-align: middle;
}

.image_table_header_wrapper table th.check_all {
    padding-left: 20px;
}

.image_table_header_wrapper table th .sorting_wrap {
    display: flex;
    align-items: center;
}

.image_table_header_wrapper table th .sorting_wrap > div {
    padding: 7px;
}

.image_table_header_wrapper table th .sorting_wrap .slider_box > div:first-child {
    padding-right: 10px;
}

.image_table_header_wrapper table th .sorting_wrap .slider_box > div:last-child {
    padding-left: 10px;
}

.image_table_header_wrapper .select_wrap .sorting_btn {
    width: 82px;
    margin-right: 20px;
    padding-left: 13px;
    padding-right: 6px;
    text-align: left;
    background: none;
    border: 0;
    color: #797979;
    vertical-align: middle;
}

.image_table_header_wrapper .select_wrap .sorting_btn:hover span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 15px;
    background: url('../../images/button/select_down.png') no-repeat 0 5px;
    margin-left: 10px;
}

.image_table_header_wrapper .select_wrap .sorting_btn:hover {
    color: #848484; /*border-color:#b4b9c3;background:#ffffff;*/
}

.image_table_header_wrapper .select_wrap {
    position: relative;
}

.image_table_header_wrapper .select_wrap > .option_list {
    position: absolute;
    display: inline-block;
    top: 37px;
    left: 0;
    width: 80%;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 101;
}

.image_table_header_wrapper .select_wrap .option_list > span {
    display: block;
    width: 100%;
    height: 8px;
    position: absolute;
    top: -8px;
    background: url('../../images/bg/option_list_bg.png') no-repeat 10px 0;
}

.image_table_header_wrapper .select_wrap .option_list > ul > li:first-child {
    padding-top: 11px;
}

.image_table_header_wrapper .select_wrap .option_list > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
    display: block;
    color: #707070;
    text-decoration: none;
    vertical-align: middle;
}
.image_table_header_wrapper .select_wrap .option_list > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}

.image_table_header_wrapper .select_wrap .option_list > ul > li:hover {
    color: #609be8 !important;
    cursor: pointer;
}
.group_button_wrap > button:hover {
    cursor: pointer;
    border-color: #bcbec1;
    background: url('../../images/button/btn_group.png') no-repeat center 0;
}
.group_button_wrap > button {
    width: 28px;
    height: 28px;
    background: url('../../images/button/btn_group.png') no-repeat center 0;
    background-color: rgba(0, 0, 0, 0);
}
.container.bg {    
    overflow-x: hidden;
    overflow-y: auto;        
}
a {
    text-decoration: none;
    color: #818181;
}
input[type="text"], textarea {
    cursor: text !important;
}
.sign_input.underline {
    box-shadow: 0 2px 1px -1px #a9adb2 inset !important;
    /*border-bottom: 1px solid #a9adb2 !important;*/
}
.sign_input:hover {
    background: #38414c;
    border: 0 !important;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
}
.sign_input {
    height: 42px;
    font-size: 14px;
    background:#323b46;
    border: 0 !important;
    border-radius: 0 !important;
    width: 76%;
    color:#fff !important;    
}
.signin_area {
    margin-left: 12%;
    margin-top: 167px;
}
.signin_box
{    
    height:100%;
    min-height:969px;    
}
.signin_wrap {
    width: 19%;
    background:#323b46;
    float:left;
    z-index: 100;
    height: 100%;    
    min-width:300px;
    min-height:969px;
    position:absolute;
    left:0px;
}
.signin_wrap > form > div > div > img {
    margin-top: 85px;    
}
.signin_wrap > form
{
    padding-bottom:220px;
}

.sign_btn {
    width: 76%;
    height: 42px;
    background:#4fa5ff;
    border: 0;
    font-size: 13px;
    color:#fff;
    margin: 51px 0 0 12%;
    padding:0px;
}
.signup_go_wrap {    
    margin-left:10%;    
    border-top: 1px solid #3e4751;
    width: 81%;
    color:#70767e;
    padding-top: 31px;
    line-height: 25px;
    position:realtive;
}
.checked_area {
    margin: 27px 0 0 12%;
}


.signup_go_wrap > a {
    color: #70767e;
}

.signup_go_wrap > a {
    color: #70767e;
}
.sign_mainimg_box {
/*    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -135px;
    margin-left: -100px;
    z-index: 10;*/
    margin-top:-80px; /* negative, same as fotter height */
}

.copyRight {
    height: 80px; /*if update this, then update .sign_mainimg_box  also*/
    text-align: center;
    right: 0px;
    line-height: 18px;
    display: block;
    padding-top: 10px;
    z-index:90;
}

.signup_checking_area {
    width: 100%;
    border-bottom: 1px solid #dfe3e8;
    padding-top: 40px;
    padding-bottom: 29px;
}

.signup_checking_area h3 {
    font-size: 18px;
    color: #686b72;
    font-weight: normal;
}

.signup_checking_area h3 > span {
    font-size: 12px;
    color: #8a8d90;
    display: inline-block;
    padding-left: 28px;
}

.signup_checking_area table th, .signup_checking_area table td {
    vertical-align: middle;
    padding: 6px 0 6px 3px;
}

.signup_checking_area table th, .signup_checking_area table td {
    vertical-align: middle;
    padding: 6px 0 6px 3px;
}
.signup_wrap {
    background: #e6ebf0;
    top: 0;
    left: 19%;
    bottom: 0;
    height:100%;    
    width:81%;
    position:absolute;
    height: 100%;
    z-index:92;
    min-height:969px;    
}
.signup_wrap > div {
    width:81%;
    padding:0% 10%;
    padding-top: 50px;    
}
.signup_wrap h1 {
    font-size: 32px;
    color: #686b72;
    margin: 0;
    margin-bottom: 0px;
    line-height: 32px;
    margin-bottom: 25px;
}
.signup_wrap h1 {
    font-size: 32px;
    color: #686b72;
    margin: 0;
    margin-bottom: 0px;
    line-height: 32px;
    margin-bottom: 25px;
}

.signup_wrap .signup {
    border: 0;
    font-size: 13px;
    width: 179px;
    line-height: 40px;
    background: #4fa5ff;
    color: #fff;
    margin-top: 45px;
}

.signup_wrap .cancel {
    border: 0;
    font-size: 13px;
    width: 179px;
    line-height: 38px;
    background: #ecf0f4;
    color: #636464;
    margin-top: 45px;
    border: 1px solid
    #c6ccd5;
}

.signup_wrap .error_txt3 {
    left: initial;
}

/* [V9 RC #4] ICP certificate link add in Log-in screen */
.gov{
    padding-top: 5px;
    display: flex;
    justify-content: center;
}
.gov > img {
    width:20px;
    height:20px;
    margin: 0px 5px 0px 0px;
}
.gov > a {
    color:#609be8;
}

.sign_bg_base {
    left : 19%;
    position:absolute;
    top : 0;    
    width : 81%;
    height : 100%;
    /*background-image: url( ../../images/bg/sign_in.png);*/
    background-size: cover;
    min-height:969px; 
    z-index: 90;       
}

.sign_bg {
    width : 100%;
    height : 100%;
}
.logoDiv
{
    width:100%;
}

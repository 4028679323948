.tooltip_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    background: url(../../images/img/qu_notice.png) no-repeat;
}

.tooltip_icon:hover {
    background: url(../../images/img/qu_notice2.png) no-repeat;
}

.tooltip_msg {
    width: 550px;
    height: 20px;
    background: rgba(224,224,224,0.95);
    border: 1px solid #fff;
    position: absolute;    
    top:15px;
    left:25px;    
    color: #000;
    line-height: 22px;
    z-index: 2;
    padding:10px;
}
/*
.addRule .tooltip_msg {
    top: auto;
    position: fixed;
}*/
.new_frame_wrap .detail_view {
    position: relative;
}

.new_frame_wrap .detail_view table {
    width: 100%;
}

.new_frame_wrap .detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.new_frame_wrap .detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.new_frame_wrap .custom_frame > div {
    position: absolute;
}

.new_frame_wrap .custom_frame > div > .frame {
    border: 1px solid #ddd;
}

.new_frame_wrap .circle_btn.horizontal > span {
    background: url('../../../../images/button/icon_horizontal_divide.png') no-repeat 0px 0px;
}

.new_frame_wrap .circle_btn.vertical > span {
    background: url('../../../../images/button/icon_vertical_divide.png') no-repeat 0px 0px;
}

.new_frame_wrap .circle_btn > span {
    display: inline-block;
    height: 28px;
    width: 28px;
}

.new_frame_wrap .custom_frame > div {
    position: absolute;
}

.new_frame_wrap .custom_frame > div > .frame.on {
    border: 1px solid #99cafc;
    background-color: #E4E4E4;
}

.new_frame_wrap .pixelFrameMenu.on {
    border: 1px solid #99cafc;
    background-color: #E4E4E4;
}

.new_frame_wrap .custom_frame .closeBtn {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    right: 3px;
    background: url('../../../../images/button/publish_minimized_close_normal.png');
}

.new_frame_wrap .pixelFrameMenu .closeBtn {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    right: 3px;
    background: url('../../../../images/button/publish_minimized_close_normal.png');
}

.new_frame_wrap .pixelFrameMenu .frame_size {
    color: #000;
    background: #e5e5e5;
    display: block;
    line-height: 24px;
    padding: 0 16px;
    position: absolute;
    bottom: 3px;
    right: 3px;
}

.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

.new_frame_wrap .canvas-container {
    position: absolute !important;
}
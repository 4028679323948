.tutorial_popup .dim {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #aaaaaa;
    opacity: .3;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.tutorial_popup .tutorial_wrap {
    position: absolute;
    right: 20px;
    top: 45px;
    background: #fff;
    padding: 5px 0px;
    border-radius: 3px;
    box-shadow: 0px 2px rgba(0, 0, 0, 0.15);
}

.tutorial_popup .tutorial_wrap > span {
    position: absolute;
    top: -13px;
    right: 22px;
}
.tutorial_popup .tutorial_wrap h3 {
    margin: 0;
    font-size: 13px;
    line-height: 30px;    
    padding: 5px 10px 5px 20px;    
}

.tutorial_popup .tutorial_wrap > .clearfix {
    border-bottom: 1px solid #ebebeb;
    margin: 0 15px;
}

.tutorial_popup .tutorial_wrap .user_detail {
    float: left;
}
.tutorial_popup .tutorial_wrap .user_detail ul {
    margin: 0 15px;
}
.tutorial_popup .tutorial_wrap .user_detail ul > li {
    cursor: pointer;
}

.tutorial_popup .tutorial_wrap .user_detail ul > li:first-child > span {
    padding-top: 10px;
}

.tutorial_popup .tutorial_wrap .user_detail ul > li > span {
    display: block;
    font-size: 10px;
    color: #6a6a6a;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 15px;
}

.tutorial_popup .tutorial_wrap .user_detail ul > li > span > span {
    display: block;
    font-size: 16px;
    color: #7f7f7f;
    font-weight: normal;
    padding-top: 6px;
}

.tutorial_popup .tutorial_wrap .user_detail ul > li > a > span {
    display: block;
    font-size: 10px;
    color: #6a6a6a;
    font-weight: bold;
    padding-top: 18px;
}

.tutorial_popup .tutorial_wrap .user_detail ul > li:last-child {
    margin-bottom: 10px;
}
.content_add_to_playlist_popup .notify_wrap {
    color: #999999;
    font-size: 11px;
    margin-bottom:16px;
}

.content_add_to_playlist_popup .mis_dialog_buttons_wrap .float_l button{
    min-width: 152px !important;
}

.content_add_to_playlist_popup .mis_dialog_buttons_wrap .float_r button{
    min-width: 102px !important;
}

.content_add_to_playlist_popup .content_tr_group {
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0px !important;
    max-height: 70px;
}
.storage-summary {
    width: 100%;
    height: 100%;
    margin-top: 12px;
    position: relative;
}

.storage-summary .right {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    width: 33px;
    height: 33px;
    border: 0;
    cursor: pointer;
    background: url(../../../../images/icon/widget_ic_right.png) no-repeat 10px 0;
}

.storage-summary .right:hover {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    width: 33px;
    height: 33px;
    border: 0;
    cursor: pointer;
    background: url(../../../../images/icon/widget_ic_right_dim.png) no-repeat 10px 0;
}

.storage-summary .left {
    isplay: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 33px;
    height: 33px;
    border: 0;
    cursor: pointer;
    background: url(../../../../images/icon/widget_ic_left.png) no-repeat 0 0;
}

.storage-summary .left:hover {
    background: url(../../../../images/icon/widget_ic_left_dim.png) no-repeat 0 0;
}


.storage-summary .li {
    margin: 3px 3px 3px 0;
    padding: 1px;
    float: left;
    height: auto;
    width: 100%;
}

.storage-summary .server_detail .storage_title{
    opacity: 0.6;
    font-size: 11px;
    color: #363636;
    letter-spacing: 0;
}

.storage-summary .server_detail .storage_total{
    font-size: 13px;
    color: #454545;
    letter-spacing: 0;
    display: block;
}

.storage-summary .server_detail .storage_used{
    color: #6e78c8;
    font-size: 16px;
    display: block;
    height: 21px;
}

.storage-summary .server_detail .storage_available{
    color: #b7bbd9;
    font-size: 16px;
    display: block;
    height: 21px;
}



.storage-summary .chart_info {
    position: absolute;
    top: 90px;
    right: 30px;
    text-align: center;
    width: 136px;
    height: 45px;
}

.storage-summary .chart_info .root-path {
    font-size: 13px;
    color: #252525;
    height: 20px;
    margin-bottom:10px;
}

.storage-summary .chart_info .storage_used {
    font-size: 37px;
    color: #252525;
    height: 28px;
}
.storage-summary .chart_info .percent {
    font-size: 13px;
    color: #252525;
}

.storage-summary .storage-tabs {
    width: 100%;
    height: 28px;
    position: relative;
    overflow: hidden;
    background: url('../../../../images/bg/datetab_bg2.png') repeat-x bottom;
    margin-top:-10px;
}

.storage-summary .storage-tabs ul{
    position: absolute;
    top: 0;
    left: 0px;
}

.storage-summary .storage-tabs ul li {
    float: left;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    width: 80px;
    height: 30px;
}

.storage-summary .storage-tabs ul li.on {
    color: #3196ff;
    background: url('../../../../images/bg/datetab_bg_selected.png') no-repeat center bottom 1px;
    background-size: 80% 2px;
}
.base.noline {
    border: 1px solid
    #edf1f4;
    color: #bcbec1;
    background: #333c4e;
}
.base {
    height: 28px;
    border: 1px solid #dde0e5;
    color: #363636;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}
.base:hover {
    color: #848484;
    border-color: #b4b9c3;
    background: #ffffff;
}
.dashboard_base {
    height: 28px;
    border: 0px;
    color: #363636;
    background: #F5F5F5;
    font-size: 12px;
    line-height: 40px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}

.base.select:hover {
    color: #848484;
    border-color: #b4b9c3;
    background: #e7eaf0;
}

.base:disabled {
    border-color: #b9b9b9;
    color: #b9b9b9;
    background: #fff;
    cursor: auto;
}

.submenu_select_button_wrap .option_list {
    width: 150px;
    position: fixed;
    top: 140px;
    left: 250px;
    background: #fff;
    border: 1px solid
    #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
}

.submenu_select_button_wrap .option_list > div > ul > li:first-child {
    padding-top: 11px;
}
.submenu_select_button_wrap .option_list > div > ul > li {
    padding: 6px 5px 4px 13px;
    padding-top: 6px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}
.circle_btn {
    padding:0;
    height:28px;
    width:28px;
    border:1px solid #dde0e5;
    color:#848484;
    background:#fff;
    border-radius:15px;
    text-align:center;
}
.circle_btn.search {
    background: url('../../images/icon/search_select.png') no-repeat 8px 8px;
}
.device_table_columntool_wrap {
    /* display: inline-block; */
    position: relative;
}

div.device_table_columntool {
    position: absolute;
    top: 0px;
    right: -5px;
    width: 20px;
    height: 31px;
    z-index: 20;
    background: url('../../images/icon/column_normal.png') no-repeat 0 0;
}

div.device_table_columntool:hover {
    cursor: pointer;
    background: url('../../images/icon/column_hover.png') no-repeat 0 0;
}

.device_table_columntool_wrap .option_list {
    width: 200px;
    height: 315px;
    display: inline-block;
    position: absolute;
    top: 37px;
    right: 0px;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.device_table_columntool_wrap .option_list .extend {
    background: url('../../images/button/btn_next.png') no-repeat;
    background-position: top 4px right 4px;
}

.device_table_columntool_wrap .option_list > ul > li {
    padding: 6px 5px 4px 13px;
    font-size: 12px;
    position: relative;
    line-height: 15px;
    text-align: left;
}
.device_table_columntool_wrap .option_list > ul > li:first-child {
    padding-top: 11px;
}
.device_table_columntool_wrap .option_list > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 10px;
}
.device_table_columntool_wrap .option_list > ul > li.last {
    border-top: 1px solid #dde0e5;
    width: 132px;
    border-bottom: 0;
    padding-bottom: 10px;
}
.device_table_columntool_wrap .option_list > ul > li {
    /* display: inline-block; */
    color: #707070;
    text-decoration: none;
    width: 100%;
    font-size: 12px;
    vertical-align: middle;
}
.device_table_columntool_wrap .option_list > ul > li > img {
    vertical-align: top;
    margin-right: 8px;
}
.device_table_columntool_wrap .option_list > ul > li.on > {
    color: #3196ff !important;
}
.device_table_columntool_wrap .option_list > ul > li:hover {
    color: #609be8 !important;
    cursor: pointer;
}
.device_table_columntool_wrap .option_list > ul > li:active {
    color: #2789f4 !important;
}